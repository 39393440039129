import * as tslib_1 from "tslib";
import { MatPaginatorIntl } from '@angular/material';
import { TranslateService, TranslateParser } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
var CustomPaginatorIntlComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CustomPaginatorIntlComponent, _super);
    function CustomPaginatorIntlComponent(translate, translateParser) {
        var _this = _super.call(this) || this;
        _this.translate = translate;
        _this.translateParser = translateParser;
        _this.getRangeLabel = function (page, pageSize, length) {
            length = Math.max(length, 0);
            var startIndex = page * pageSize;
            var endIndex = startIndex < length ?
                Math.min(startIndex + pageSize, length) :
                startIndex + pageSize;
            return _this.translateParser.interpolate(_this.rangeLabelIntl, { startIndex: startIndex, endIndex: endIndex, length: length });
        };
        _this.getPaginatorTranslations();
        return _this;
    }
    CustomPaginatorIntlComponent.prototype.getPaginatorTranslations = function () {
        var _this = this;
        this.translate.get([
            'paginator.items_per_page',
            'paginator.next_page',
            'paginator.previous_page',
            'paginator.range',
            'paginator.last_page',
            'paginator.first_page'
        ]).subscribe(function (translation) {
            _this.itemsPerPageLabel = translation['paginator.items_per_page'];
            _this.nextPageLabel = translation['paginator.next_page'];
            _this.previousPageLabel = translation['paginator.previous_page'];
            _this.rangeLabelIntl = translation['paginator.range'];
            _this.firstPageLabel = translation['paginator.first_page'];
            _this.lastPageLabel = translation['paginator.last_page'];
        });
    };
    CustomPaginatorIntlComponent.ngInjectableDef = i0.defineInjectable({ factory: function CustomPaginatorIntlComponent_Factory() { return new CustomPaginatorIntlComponent(i0.inject(i1.TranslateService), i0.inject(i1.TranslateParser)); }, token: CustomPaginatorIntlComponent, providedIn: "root" });
    return CustomPaginatorIntlComponent;
}(MatPaginatorIntl));
export { CustomPaginatorIntlComponent };
