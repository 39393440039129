<h1 mat-dialog-title>{{ 'reset_password_page.reset_password' | translate }}</h1>
<div mat-dialog-content fxLayout="row wrap" fxLayoutAlign="space-between start" [ngStyle]="{'height.px': dialogContentHeight}">
    <p fxFlex="grow">{{ 'reset_password_page.enter_new_password' | translate }}</p>

    <mat-form-field appearance="outline">
        <mat-label>{{ 'reset_password_page.new_password' | translate }}</mat-label>
        <input
            data-e2e="change-password.password1"
            matInput
            type="password"
            [formControl]="passwordFormControl"
            (ngModelChange)="passwordChanged($event)">
        <mat-hint align="start">
            <span>{{ 'reset_password_page.min_characters' | translate: {minLength: passwordSettings?.minLength} }}</span>
            <p *ngIf="passwordSettings?.containsBlank">{{ 'reset_password_page.blank_character' | translate }}</p>
            <p *ngIf="passwordSettings?.containsDigit">{{ 'reset_password_page.one_number' | translate }}</p>
            <p *ngIf="passwordSettings?.containsLowercase">{{ 'reset_password_page.lowercase' | translate }}</p>
            <p *ngIf="passwordSettings?.containsUppercase">{{ 'reset_password_page.uppercase' | translate }}</p>
            <p *ngIf="passwordSettings?.containsSpecial">{{ 'reset_password_page.special_character' | translate }}</p>
        </mat-hint>

        <mat-error align="start" *ngIf="!!errors.length">
            <p *ngFor="let error of errors">
                {{ error | translate: passwordFormControl.errors[error] }}
            </p>
        </mat-error>

        <mat-hint align="end">{{newPassword?.length || 0}}/{{passwordSettings?.minLength}}</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline" [ngClass]="{'error-hint': reEnteredPassword && reEnteredPassword !== newPassword}">
        <mat-label>{{ 'reset_password_page.re-enter_password' | translate }}</mat-label>
        <input data-e2e="change-password.password2" matInput type="password" [(ngModel)]="reEnteredPassword">
        <mat-hint *ngIf="reEnteredPassword && reEnteredPassword !== newPassword">{{ 'reset_password_page.must_match' | translate }}</mat-hint>
    </mat-form-field>
</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end" fxLayoutGap="12px">
    <button
        data-e2e="change-password.cancel"
        mat-stroked-button
        (click)="onCancel()">
        {{ 'cancel' | translate }}
    </button>
    <button
        data-e2e="change-password.submit"
        mat-raised-button
        color="accent"
        [disabled]="!!passwordFormControl.errors || newPassword !== reEnteredPassword"
        (click)="updatePassword(newPassword)">
        {{ 'reset_password' | translate }}
    </button>
</div>
