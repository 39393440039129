import { JsonApiModelConfig, JsonApiModel, Attribute, HasMany } from 'angular2-jsonapi';
import { Role } from './role';

@JsonApiModelConfig({
    type: 'user-sessions'
})

export class UserSession extends JsonApiModel {

    @Attribute({ serializedName: 'authentication-token' })
    authenticationToken: string;

    @Attribute({ serializedName: 'password-reset-required' })
    passwordResetRequired: string;

    @Attribute({ serializedName: 'first-name' })
    firstName: string;

    @Attribute({ serializedName: 'last-name' })
    lastName: string;

    @Attribute({ serializedName: 'user-type' })
    userType: string;

    @Attribute()
    email: string;

    @HasMany()
    roles: Role[];

    @Attribute({ serializedName: 'account-name' })
    accountName: string;

    get navName(): string {
        return this.email;
    }
}
