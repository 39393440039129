import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { UserManagementService } from 'src/app/services/user-management/user-management.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material';
import { Role } from 'src/app/models/role';

@Component({
  selector: 'reg-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {

  dismiss: string;
  successMessage: string;
  routeDataSub: Subscription;
  roles: Role[];
  roleCheckBoxes: object;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private usersService: UserManagementService,
    private translate: TranslateService,
    private toast: MatSnackBar
  ) {
    this.translate.get([
      'users.create_success',
      'dismiss'
    ]).subscribe(translation => {
      this.dismiss = translation['dismiss'];
      this.successMessage = translation['users.create_success'];
    });
  }

  user = {
    firstName: '',
    lastName: '',
    email: '',
    roles: [],
  };

  ngOnInit() {
    this.routeDataSub = this.route.data.subscribe(({roles}) => {
      this.roles = roles;
      this.roleCheckBoxes = this.roles.reduce((selections, role) => {
        selections[role.name] = false;
        return selections;
      }, {});
    });
  }

  cancel() {
    this.router.navigate(['users']);
  }

  create() {
    this.user.roles = this.roles.map(r => r.name).reduce((roles, role) => {
      if (this.roleCheckBoxes[role]) {
        roles.push(role);
      }
      return roles;
    }, []);

    this.usersService.createUser(this.user).subscribe(() => {
      this.toast.open(this.successMessage, this.dismiss, {
        duration: 3000,
        panelClass: 'success-snackbar',
      });
      this.router.navigate(['users']);
    });
  }

}
