import { DataStore } from '../../services/datastore/datastore.service';
import { map } from 'rxjs/operators';
import { Role } from 'src/app/models/role';
import * as i0 from "@angular/core";
import * as i1 from "../../services/datastore/datastore.service";
var RolesResolverService = /** @class */ (function () {
    function RolesResolverService(datastore) {
        this.datastore = datastore;
    }
    RolesResolverService.prototype.resolve = function (_route, _state) {
        return this.datastore.findAll(Role).pipe(map(function (rolesResult) { return rolesResult.getModels(); }));
    };
    RolesResolverService.ngInjectableDef = i0.defineInjectable({ factory: function RolesResolverService_Factory() { return new RolesResolverService(i0.inject(i1.DataStore)); }, token: RolesResolverService, providedIn: "root" });
    return RolesResolverService;
}());
export { RolesResolverService };
