<mat-card class="mat-elevation-z4" fxLayout="row" fxAlign="space-between">
    <div>
    <mat-card-title>{{ 'titles.create_catalog_entry' | translate }}</mat-card-title>
    <mat-vertical-stepper #stepper linear>
        <mat-step [stepControl]="manufacturer" [label]="'create_catalog.steps.manufacturer' | translate">
            <form [formGroup]="manufacturer">
                <mat-form-field [matTooltip]="'create_catalog.hints.manufacturer' | translate" matTooltipPosition="above">
                    <input data-e2e="manufacturer" #manufacturerName matInput required formControlName="manufacturerName" [placeholder]="'create_catalog.manufacturer_name' | translate">
                </mat-form-field>
            </form>
            <button data-e2e="next1" type="button" mat-button matStepperNext> {{ 'create_catalog.next' | translate }} </button>
        </mat-step>
        <mat-step [label]="'create_catalog.steps.drug_information' | translate" [stepControl]="drugInformation">
            <form [formGroup]="drugInformation">
                <mat-form-field class="input-margin" [matTooltip]="'create_catalog.hints.drug_name' | translate" matTooltipPosition="above">
                    <input data-e2e="itemName" matInput required formControlName="drugName" [placeholder]="'create_catalog.drug_name' | translate">
                </mat-form-field>
                <mat-form-field [matTooltip]="'create_catalog.hints.drug_strength' | translate" matTooltipPosition="above">
                    <input data-e2e="itemStrengthScalar" type="number" matInput required formControlName="drugStrength" [placeholder]="'create_catalog.drug_strength' | translate">
                </mat-form-field>
                <div>
                    <mat-form-field [matTooltip]="'create_catalog.hints.drug_strength_uom' | translate" matTooltipPosition="above">
                        <input data-e2e="itemStrengthUom" matInput required formControlName="drugStrengthUom" [placeholder]="'create_catalog.drug_strength_uom' | translate">
                    </mat-form-field>
                </div>
            </form>
            <button data-e2e="back2" type="button" mat-button matStepperPrevious> {{ 'create_catalog.back' | translate }} </button>
            <button data-e2e="next2" type="button" mat-button matStepperNext> {{ 'create_catalog.next' | translate }} </button>
        </mat-step>
        <mat-step [label]="'create_catalog.steps.package_information' | translate" [stepControl]="packageInformation">
            <form [formGroup]="packageInformation">
                <mat-form-field class="input-margin" [matTooltip]="'create_catalog.hints.package_size' | translate" matTooltipPosition="above">
                    <input data-e2e="packageSizeScalar" type="number" matInput required formControlName="packageSize" [placeholder]="'create_catalog.package_size' | translate">
                </mat-form-field>
                <mat-form-field class="input-margin" [matTooltip]="'create_catalog.hints.package_size_uom' | translate" matTooltipPosition="above">
                    <input data-e2e="packageSizeUom" matInput required formControlName="packageSizeUom" [placeholder]="'create_catalog.package_size_uom' | translate">
                </mat-form-field>
                <mat-form-field [matTooltip]="'create_catalog.hints.package_type' | translate" matTooltipPosition="above">
                <mat-select formControlName="packageType" required [placeholder]="'create_catalog.package_type' | translate">
                    <mat-option *ngFor="let packageType of (route.data | async)?.packageTypes" [value]="packageType">
                        {{packageType.name}}
                    </mat-option>
                </mat-select>
                </mat-form-field>
            <div>
                <mat-form-field class="input-margin" [matTooltip]="'create_catalog.hints.expiring_fridge' | translate" matTooltipPosition="above">
                    <input data-e2e="expiringFridgeDays" matInput formControlName="expiringFridge" [placeholder]="'create_catalog.expiring_fridge' | translate">
                </mat-form-field>
                <mat-form-field [matTooltip]="'create_catalog.hints.expiring_multidose' | translate" matTooltipPosition="above">
                    <input data-e2e="expiringMultidoseDays" matInput  formControlName="expiringMultidose" [placeholder]="'create_catalog.expiring_multidose' | translate">
                </mat-form-field>
            </div>
            </form>
            <button data-e2e="back3" type="button" mat-button matStepperPrevious> {{ 'create_catalog.back' | translate }} </button>
            <button data-e2e="next3" type="button" mat-button matStepperNext> {{ 'create_catalog.next' | translate }} </button>
        </mat-step>
        <mat-step [stepControl]="identifiers" [label]="'create_catalog.steps.identifiers' | translate">
        <form [formGroup]="identifiers">
            <label> {{ 'create_catalog.unit_use' | translate }} </label>
            <div>
                <mat-form-field class="input-margin" [matTooltip]="'create_catalog.hints.unit_use_ndc' | translate" matTooltipPosition="above">
                    <input data-e2e="ndc" matInput required formControlName="unitOfUseNdc" [placeholder]="'create_catalog.ndc' | translate">
                </mat-form-field>
                <mat-form-field [matTooltip]="'create_catalog.hints.unit_use_gtin' | translate" matTooltipPosition="above">
                    <input data-e2e="gtin" matInput formControlName="unitOfUseGtin" [placeholder]="'create_catalog.gtin' | translate">
                </mat-form-field>
            </div>
            <label> {{ 'create_catalog.unit_sale' | translate }} </label>

            <div formArrayName="unitsOfSale">
                <div *ngFor="let item of unitsOfSale.controls; let i = index;" [formGroupName]="i">
                    <mat-form-field class="input-margin" [matTooltip]="'create_catalog.hints.unit_sale_gtin' | translate" matTooltipPosition="above">
                        <input data-e2e="unitOfSaleNdc" matInput required formControlName="ndc" [placeholder]="'create_catalog.ndc' | translate">
                    </mat-form-field>
                    <mat-form-field class="input-margin" [matTooltip]="'create_catalog.hints.unit_sale_gtin' | translate" matTooltipPosition="above">
                        <input data-e2e="unitOfSaleGtin" matInput formControlName="gtin" [placeholder]="'create_catalog.gtin' | translate">
                    </mat-form-field>
                    <mat-form-field [matTooltip]="'create_catalog.hints.package_count' | translate" matTooltipPosition="above">
                        <input data-e2e="packageCount" matInput required formControlName="packageCount" [placeholder]="'create_catalog.package_count' | translate">
                    </mat-form-field>
                    <mat-icon *ngIf="i > 0" (click)="deleteUnitOfSale(i)">cancel</mat-icon>
                </div>
            </div>
            <a (click)="addUnitOfSale()"> {{ 'create_catalog.add_identifier' | translate }} </a>
        </form>
        <button data-e2e="back4" type="button" mat-button matStepperPrevious> {{ 'create_catalog.back' | translate }} </button>
        <button data-e2e="next4" type="button" mat-button matStepperNext> {{ 'create_catalog.next' | translate }} </button>
        </mat-step>
        <mat-step [label]="'create_catalog.steps.confirmation' | translate">
            <p> {{ 'create_catalog.information_correct?' | translate }} </p>
            <button mat-stroked-button (click)="cancel()"> {{ 'cancel' | translate }} </button>
            <button mat-raised-button color="accent" (click)="submit()"> {{'transition_batch.submit' | translate }} </button>
        </mat-step>
    </mat-vertical-stepper>
    <button *ngIf="!onConfirmation()" class="cancel" mat-stroked-button (click)="cancel()"> {{ 'cancel' | translate }} </button>
    </div>
    <mat-card fxFlex="40">
        <div fxLayout="column" fxLayoutAlign="space-between stretch">
            <div fxLayout="row" fxLayoutAlign="space-between" fxFlexOffset="5">
                <label fxFlex="45"> {{ 'create_catalog.info.manufacturer' | translate }} </label>
                <span data-e2e="confirm.manufacturer" fxFlex="45">{{manufacturer.get('manufacturerName').value}}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" fxFlexOffset="5">
                <label fxFlex="45"> {{ 'create_catalog.info.drug' | translate }} </label>
                <span data-e2e="confirm.itemName" fxFlex="45">{{drugInformation.get('drugName').value}}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" fxFlexOffset="5">
                <label fxFlex="45"> {{ 'create_catalog.info.strength' | translate }} </label>
                <span data-e2e="confirm.itemStrength" fxFlex="45">{{drugInformation.get('drugStrength').value}} {{drugInformation.get('drugStrengthUom').value}}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" fxFlexOffset="5">
                <label fxFlex="45"> {{ 'create_catalog.info.package_size' | translate }} </label>
                <span data-e2e="confirm.packageSize" fxFlex="45">{{packageInformation.get('packageSize').value}} {{packageInformation.get('packageSizeUom').value}}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" fxFlexOffset="5">
                <label fxFlex="45"> {{ 'create_catalog.info.package_type' | translate }} </label>
                <span data-e2e="confirm.packageType" fxFlex="45">{{packageInformation.get('packageType').value.name}}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" fxFlexOffset="5">
                <span fxFlex="45"> {{ 'create_catalog.info.expiring_fridge' | translate }} </span>
                <span data-e2e="confirm.expiringFridgeDays" fxFlex="45">{{packageInformation.get('expiringFridge').value}}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" fxFlexOffset="5">
                <span fxFlex="45"> {{ 'create_catalog.info.expiring_multidose' | translate }} </span>
                <span data-e2e="confirm.expiringMultidoseDays" fxFlex="45">{{packageInformation.get('expiringMultidose').value}}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" fxFlexOffset="5">
                <span fxFlex="45"> {{ 'create_catalog.info.unit_use_identifiers' | translate }} </span>
                <div fxLayout="column" fxFlex="45" fxLayoutAlign="space-between stretch">
                <span data-e2e="confirm.ndc" *ngIf="identifiers.get('unitOfUseNdc').value"> {{ 'create_catalog.ndc' | translate }} - {{identifiers.get('unitOfUseNdc').value}} </span>
                <span data-e2e="confirm.gtin" *ngIf="identifiers.get('unitOfUseGtin').value"> {{ 'create_catalog.gtin' | translate }} - {{identifiers.get('unitOfUseGtin').value}} </span>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" fxFlexOffset="5">
                <span fxFlex="45"> {{ 'create_catalog.info.unit_sale_identifiers' | translate }} </span>
                <div fxLayout="column" fxFlex="45">
                    <div *ngFor="let item of unitsOfSaleValues()" fxLayout="column">
                        <span data-e2e="confirm.unitOfSaleNdc" *ngIf="item.ndc"> {{ 'create_catalog.ndc' | translate }} - {{item.ndc}} </span>
                        <span data-e2e="confirm.unitOfSaleGtin" *ngIf="item.gtin"> {{ 'create_catalog.gtin' | translate }} - {{item.gtin}} </span>
                        <span data-e2e="confirm.packageCount" *ngIf="item.packageCount"> {{ 'create_catalog.package_count' | translate }} - {{item.packageCount}} </span>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
</mat-card>
