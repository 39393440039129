import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { urls } from '../../../urls';

const DEFAULT_SUPPORT_EMAIL = 'help@bluesight.com';

@Injectable({
    providedIn: 'root'
})

export class EmailService {
    constructor(
        private http: HttpClient,
    ) {}

    setEmails(): void {
        this.http.get<any>(`${urls.API_BASE_URL}/emails.json`).pipe(
            tap(emails => {
                if (emails.support_email) {
                    localStorage.setItem('supportEmail', emails.support_email);
                }
            }),
        ).subscribe();
    }

    getSupportEmail(): string {
        return localStorage.getItem('supportEmail') || DEFAULT_SUPPORT_EMAIL;
    }
}
