import { MatDialog } from '@angular/material';
import { KioskConfigureComponent } from 'src/app/dialogs/kiosk-configure/kiosk-configure.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var KioskService = /** @class */ (function () {
    function KioskService(dialog) {
        this.dialog = dialog;
    }
    KioskService.prototype.configureKioskModal = function () {
        this.dialog.open(KioskConfigureComponent, {
            width: '290px'
        });
    };
    KioskService.ngInjectableDef = i0.defineInjectable({ factory: function KioskService_Factory() { return new KioskService(i0.inject(i1.MatDialog)); }, token: KioskService, providedIn: "root" });
    return KioskService;
}());
export { KioskService };
