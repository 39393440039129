import { DataStore } from '../../services/datastore/datastore.service';
import { map } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import * as i0 from "@angular/core";
import * as i1 from "../../services/datastore/datastore.service";
var UsersResolverService = /** @class */ (function () {
    function UsersResolverService(datastore) {
        this.datastore = datastore;
    }
    UsersResolverService.prototype.resolve = function (_route, _state) {
        return this.datastore.findAll(User).pipe(map(function (usersResult) { return usersResult.getModels(); }));
    };
    UsersResolverService.ngInjectableDef = i0.defineInjectable({ factory: function UsersResolverService_Factory() { return new UsersResolverService(i0.inject(i1.DataStore)); }, token: UsersResolverService, providedIn: "root" });
    return UsersResolverService;
}());
export { UsersResolverService };
