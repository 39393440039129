import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';

import { AuthService } from '../../services/auth/auth.service';
import { DatadogService } from '../../services/vendor/datadog.service';
import { PasswordResetService } from '../../services/auth/password-reset.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'reg-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
    @ViewChild('password') passwordInput: ElementRef;
    passwordExpired: string;
    loginSuccess: string;
    dismiss: string;

    constructor(
        private router: Router,
        private authService: AuthService,
        private datadogService: DatadogService,
        private passwordResetService: PasswordResetService,
        private toast: MatSnackBar,
        private translate: TranslateService
    ) {
        this.translate.get([
            'login_page.password_expired',
            'dismiss'
          ]).subscribe(translation => {
            this.passwordExpired = translation['login_page.password_expired'];
            this.dismiss = translation['dismiss'];
          });
    }

    ngOnInit() { }

    login(email: string, password: string) {
        this.authService.login(email, password).subscribe((authResult) => {
            if (authResult.passwordResetRequired) {
                this.passwordResetService.resetPasswordModal();

                this.toast.open(this.passwordExpired , this.dismiss, {
                    duration: 3000
                });

                this.passwordInput.nativeElement.value = '';
            } else {
                this.datadogService.onLogin();
                this.translate.get('login_page.login_success', {email: authResult.email}).subscribe(translation => {
                    this.toast.open(translation, this.dismiss, {
                        duration: 3000,
                        panelClass: 'success-snackbar',
                    });
                });

                this.authService.postLoginRouting(authResult);
            }
        });
    }

    forgotPassword() {
        this.router.navigate(['forgot-password']);
    }
}
