import { HttpClient } from '@angular/common/http';
import { urls } from '../../../urls';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CatalogResourceService = /** @class */ (function () {
    function CatalogResourceService(http) {
        this.http = http;
        this.CATALOG_ENTRIES_URL = urls.API_URL + "/catalog-entries";
    }
    CatalogResourceService.prototype.createCatalogEntry = function (newCatalogData) {
        return this.http.post(this.CATALOG_ENTRIES_URL, newCatalogData).pipe(
        // tap(catalogPostResult => console.log(catalogPostResult))
        );
    };
    CatalogResourceService.prototype.createMockCatalogEntries = function (num) {
        var _this = this;
        Array.from({ length: num }).forEach(function (val, index) {
            _this.createCatalogEntry({
                ndc: "12-124-19-" + index,
                itemName: 'Fentanly',
                manufacturerName: 'Amgen',
                itemStrength: {
                    scalar: 5,
                    uom: 'p/nm'
                },
                packageSize: {
                    scalar: 100,
                    uom: 'mg'
                },
                packageDescriptionId: 31
            }).subscribe();
        });
    };
    CatalogResourceService.ngInjectableDef = i0.defineInjectable({ factory: function CatalogResourceService_Factory() { return new CatalogResourceService(i0.inject(i1.HttpClient)); }, token: CatalogResourceService, providedIn: "root" });
    return CatalogResourceService;
}());
export { CatalogResourceService };
