/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./approve.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/batches-table/batches-table.component.ngfactory";
import * as i3 from "../../components/batches-table/batches-table.component";
import * as i4 from "../../services/transition-batch/transition-batch.service";
import * as i5 from "@angular/material/dialog";
import * as i6 from "@angular/router";
import * as i7 from "../../services/auth/auth.service";
import * as i8 from "@ngx-translate/core";
import * as i9 from "@angular/common";
import * as i10 from "./approve.component";
import * as i11 from "../../services/datastore/datastore.service";
var styles_ApproveComponent = [i0.styles];
var RenderType_ApproveComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ApproveComponent, data: {} });
export { RenderType_ApproveComponent as RenderType_ApproveComponent };
function View_ApproveComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "reg-batches-table", [["defaultSort", "processedAt"]], null, null, null, i2.View_BatchesTableComponent_0, i2.RenderType_BatchesTableComponent)), i1.ɵdid(1, 638976, null, 0, i3.BatchesTableComponent, [i4.TransitionBatchService, i5.MatDialog, i6.Router, i7.AuthService], { tableName: [0, "tableName"], displayedColumns: [1, "displayedColumns"], data: [2, "data"], defaultSort: [3, "defaultSort"] }, null), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), i1.ɵpad(3, 6)], function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("batches_table.unapproved")); var currVal_1 = _ck(_v, 3, 0, "drug", "lotNumber", "manufacturerExpiration", "processedAt", "processedBy", "approveButton"); var currVal_2 = _v.context.ngIf; var currVal_3 = "processedAt"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_ApproveComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_ApproveComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.dataSource$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ApproveComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "reg-approve", [], null, null, null, View_ApproveComponent_0, RenderType_ApproveComponent)), i1.ɵdid(1, 114688, null, 0, i10.ApproveComponent, [i6.ActivatedRoute, i11.DataStore], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ApproveComponentNgFactory = i1.ɵccf("reg-approve", i10.ApproveComponent, View_ApproveComponent_Host_0, {}, {}, []);
export { ApproveComponentNgFactory as ApproveComponentNgFactory };
