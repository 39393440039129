import { JsonApiModelConfig, JsonApiModel, Attribute } from 'angular2-jsonapi';

@JsonApiModelConfig({
    type: 'users/password-settings'
})
export class PasswordSettings extends JsonApiModel {

    @Attribute({ serializedName: 'contains-digit' })
    containsDigit: boolean;

    @Attribute({ serializedName: 'contains-lowercase' })
    containsLowercase: boolean;

    @Attribute({ serializedName: 'contains-uppercase' })
    containsUppercase: boolean;

    @Attribute({ serializedName: 'contains-special' })
    containsSpecial: boolean;

    @Attribute({ serializedName: 'contains-blank' })
    containsBlank: boolean;

    @Attribute({ serializedName: 'min-length' })
    minLength: number;

    @Attribute({ serializedName: 'max-length' })
    maxLength: number;
}
