import { JsonApiModelConfig, JsonApiModel, Attribute} from 'angular2-jsonapi';

@JsonApiModelConfig({
    type: 'kiosk-sessions'
})

export class KioskSession extends JsonApiModel {

    @Attribute({ serializedName: 'authentication-token' })
    authenticationToken: string;

    @Attribute()
    name: string;

    get navName(): string {
        return this.name;
    }
}

