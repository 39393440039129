<mat-card-title> {{ 'users.update_user' | translate }} </mat-card-title>
<div fxLayout="row" fxLayoutAlign="space-around">
  <mat-form-field fxFlex="40" class="input-margin">
    <input data-e2e="first.name" [(ngModel)]="data.user._firstName" matInput required [placeholder]="'users.first_name' | translate">
  </mat-form-field>
  <mat-form-field fxFlex="40">
    <input data-e2e="last.name" [(ngModel)]="data.user._lastName" matInput required [placeholder]="'users.last_name' | translate">
  </mat-form-field>
</div>
<div fxLayout="row" fxLayoutAlign="space-around">
  <mat-form-field fxFlex="91">
    <input data-e2e="email" [(ngModel)]="data.user._email" matInput required [placeholder]="'users.email' | translate">
  </mat-form-field>
</div>
<div class="checkboxes" fxLayout="row" fxLayoutAlign="space-around">
  <mat-checkbox *ngFor="let role of data.roles" [(ngModel)]="data.user.roleCheckBoxes[role.name]"> {{ role.displayName }} </mat-checkbox>
</div>

<div class="buttons" fxLayout="row">
  <a *ngIf="data.user.id !== userId && !showDeleteConfirm" fxFlexOffset="10" (click)="deleteUser()"> {{ 'users.delete_user' | translate }} </a>
  <a *ngIf="showDeleteConfirm" fxFlexOffset="10" (click)="confirmDelete()"> {{ 'users.confirm' | translate }} </a>
  <a *ngIf="!data.user._hasLoggedIn" fxFlexOffset="10" (click)="resendInvite()"> {{ 'users.resend_invite' | translate }} </a>
  <button [fxFlexOffset]="data.user._hasLoggedIn && data.user.id == userId? '60': '30'" mat-stroked-button (click)="onCancel()"> {{ 'cancel' | translate }} </button>
  <button fxFlexOffset="5" mat-raised-button color="accent" (click)="updateUser()"> {{ 'users.update' | translate }} </button>
</div>
