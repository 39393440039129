import * as tslib_1 from "tslib";
import { JsonApiModelConfig, JsonApiModel, Attribute } from 'angular2-jsonapi';
var Counts = /** @class */ (function (_super) {
    tslib_1.__extends(Counts, _super);
    function Counts() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Counts.prototype, "started", {
        // we only want to show the number of started batches that are not active
        get: function () {
            return this._started - this.active;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        Attribute(),
        tslib_1.__metadata("design:type", Number)
    ], Counts.prototype, "finished", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'started' }),
        tslib_1.__metadata("design:type", Number)
    ], Counts.prototype, "_started", void 0);
    tslib_1.__decorate([
        Attribute(),
        tslib_1.__metadata("design:type", Number)
    ], Counts.prototype, "active", void 0);
    Counts = tslib_1.__decorate([
        JsonApiModelConfig({
            type: 'production-runs/counts'
        })
    ], Counts);
    return Counts;
}(JsonApiModel));
export { Counts };
