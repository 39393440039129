import { Component, OnInit,  OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';

import { AuthService } from '../../services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';

import { Account } from '../../models/account';

@Component({
    selector: 'reg-select-accounts',
    templateUrl: './select-account.component.html',
    styleUrls: ['./select-account.component.scss']
})
export class SelectAccountComponent implements OnInit, OnDestroy {
    accounts: Account[] = [];
    routeDataSub: Subscription;

    constructor(
        private router: Router,
        private authService: AuthService,
        private route: ActivatedRoute,
        private toast: MatSnackBar,
        /* tslint:disable:no-unused-variable */
        public translate: TranslateService
    ) {
    }

    ngOnInit() {
        this.routeDataSub = this.route.data.subscribe(({ accounts }) => {
            this.accounts = accounts;
        });
    }

    ngOnDestroy() {
        this.routeDataSub.unsubscribe();
    }

    selectAccount(account: Account) {
        this.authService.setCurrentAccount(account.id).subscribe((authResult) => {
            this.toast.open(`👀 Viewing account: ${account.name}`, '🍳', {
                duration: 3000,
                panelClass: 'success-snackbar',
            });
            this.router.navigate(['batches']);
        });
    }
}
