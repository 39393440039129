import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { EMPTY, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
import * as i2 from "@angular/router";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../services/auth/auth.service";
var HttpErrorInterceptService = /** @class */ (function () {
    function HttpErrorInterceptService(toast, router, translate, authService) {
        this.toast = toast;
        this.router = router;
        this.translate = translate;
        this.authService = authService;
    }
    HttpErrorInterceptService.prototype.intercept = function (request, next) {
        var _this = this;
        return next.handle(request).pipe(catchError(function (error) {
            var toasted = false;
            if ((error.status >= 200 && error.status < 300) ||
                !_this.authService.requestWasMadeWithCurrentToken(request)) {
                return of(new HttpResponse(request));
            }
            if (error.status === 401) {
                // for Password Reset and New User Setting Password pages, query parameter 'page' is passed to ExpiredLink component
                if (request.url.includes('password-reset-settings')) {
                    _this.router.navigate(['expired-link'], { queryParams: { fromPage: request.url.split('page=')[1] } });
                }
                else if (!_this.authService.isLoginRequest(request)) {
                    // only log out if this request was made with our auth token
                    var authHeader = request.headers.get('authorization').trim();
                    if (!!authHeader && authHeader.indexOf('Bearer') >= 0) {
                        toasted = true;
                        var oldToken = authHeader.substring(authHeader.indexOf(' ')).trim();
                        if (oldToken === localStorage.getItem('authToken')) {
                            // probably expired token due to inactivity we should log out
                            _this.authService.logOut();
                            _this.translate.get(['logged_out_due_to_inactivity', 'dismiss']).subscribe(function (translation) {
                                _this.toast.open(translation['logged_out_due_to_inactivity'], translation['dismiss'], {
                                    duration: 3000
                                });
                            });
                        }
                    }
                }
            }
            if (!toasted) {
                if (error.status && error.error && error.error.errors[0].detail) {
                    // The backend returned an unsuccessful response code.
                    // The response body may contain clues as to what went wrong,
                    _this.translate.get(['status_error', 'dismiss'], { status: error.status, error: error.error.errors[0].detail }).subscribe(function (translation) {
                        _this.toast.open(translation['status_error'], translation['dismiss'], {
                            duration: 3000
                        });
                    });
                }
                else {
                    _this.translate.get(['unknown_error', 'dismiss']).subscribe(function (translation) {
                        _this.toast.open(translation['unknown_error'], translation['dismiss'], {
                            duration: 3000
                        });
                    });
                }
            }
            if (error.status === 403) {
                _this.router.navigate(['permission-denied']);
            }
            // If you want to return a new response:
            // return of(new HttpResponse({body: [{name: "Default value..."}]}));
            // If you want to return the error on the upper level:
            // return throwError(error);
            // or just return nothing:
            return EMPTY;
        }));
    };
    HttpErrorInterceptService.ngInjectableDef = i0.defineInjectable({ factory: function HttpErrorInterceptService_Factory() { return new HttpErrorInterceptService(i0.inject(i1.MatSnackBar), i0.inject(i2.Router), i0.inject(i3.TranslateService), i0.inject(i4.AuthService)); }, token: HttpErrorInterceptService, providedIn: "root" });
    return HttpErrorInterceptService;
}());
export { HttpErrorInterceptService };
