import { JsonApiModelConfig, JsonApiModel, Attribute, BelongsTo } from 'angular2-jsonapi';
import { AccountType } from './account-type';

export interface AccountModel {
    id: number;
    name: string;
}

@JsonApiModelConfig({
    type: 'accounts'
})

export class Account extends JsonApiModel {
    @Attribute({ serializedName: 'name' })
    name: string;

    @BelongsTo({ key: 'account-type' })
    accountType: AccountType;
}
