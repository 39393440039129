import { DataStore } from 'src/app/services/datastore/datastore.service';
import { ProductionRun } from 'src/app/models/production-run';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../services/datastore/datastore.service";
var StartedBatchesResolverService = /** @class */ (function () {
    function StartedBatchesResolverService(datastore) {
        this.datastore = datastore;
    }
    StartedBatchesResolverService.prototype.resolve = function (route, state) {
        return this.datastore.findAll(ProductionRun, { state: 'started' }).pipe(map(function (productionRunsResult) { return productionRunsResult.getModels(); }));
    };
    StartedBatchesResolverService.ngInjectableDef = i0.defineInjectable({ factory: function StartedBatchesResolverService_Factory() { return new StartedBatchesResolverService(i0.inject(i1.DataStore)); }, token: StartedBatchesResolverService, providedIn: "root" });
    return StartedBatchesResolverService;
}());
export { StartedBatchesResolverService };
