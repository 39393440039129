import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

import { ProductionRun } from 'src/app/models/production-run';

interface LinesData {
    id: string;
    drug: string;
    lotNumber: string;
    manufacturerExpiration: string;
    line: string;
    lastScan: string;
    unitUseNdc: string;
    unitSaleNdc: string;
    processedAt: string;
    processedBy: string;
    totalCartons: number;
    uniqueValidEpcs: number;
    uniqueInvalidEpcs: number;
    uniqueEpcs: number;
}

@Component({
  selector: 'reg-archive',
  templateUrl: './archive.component.html'
})
export class ArchiveComponent implements OnInit {

    routeData$: Observable<ProductionRun[]>;
    dataSource$: Observable<Array<LinesData>>;
    POLLING_INTERVAL = 5000;

    constructor(
        private route: ActivatedRoute,
        ) {}

    ngOnInit() {
        this.routeData$ = this.route.data.pipe(
            map((routeData: any) => routeData.batches),
        );

        this.dataSource$ = this.routeData$.pipe(
            map((productionRunData: ProductionRun[]) => this.mapRunsDataLinesData(productionRunData)),
            map((tableData: LinesData[]) => tableData)
        );
    }

    mapRunsDataLinesData(productionRunData: ProductionRun[]): LinesData[] {
        return productionRunData.map((batch) => {
          const catalogEntry = batch.unitOfSale && batch.unitOfSale.catalogEntry;
          const accountType = batch.account ? batch.account.accountType : null;
          const accountName = accountType ? accountType.name : null;

          try {
            return {
                id: batch.id,
                drug: batch.drugInformation,
                lotNumber: batch.lotNumber,
                manufacturerExpiration: accountName === 'compounder' ? moment(batch.expirationManufacturer).format('DDMMMYYYY').toUpperCase() :
                moment(batch.expirationManufacturer).format('MMMYYYY').toUpperCase(),
                expirationFull: batch.expirationManufacturer,
                line: batch.tunnelName,
                lastScan: moment(batch.lastScan).format('MMMYYYY').toUpperCase(),
                unitUseNdc: catalogEntry.ndcFull,
                unitSaleNdc: batch.unitOfSale && batch.unitOfSale.ndcFull,
                processedAt: moment(batch.finishedAt).format('YYYY-MM-DD HH:mm:ss'),
                processedBy: `${batch.finishedBy && batch.finishedBy.firstName} ${batch.finishedBy && batch.finishedBy.lastName}`,
                approvedAt: moment(batch.approvedAt).format('YYYY-MM-DD HH:mm:ss'),
                approvedBy: `${batch.approvedBy && batch.approvedBy.firstName} ${batch.approvedBy && batch.approvedBy.lastName}`,
                test: batch.formattedBatchType,
                totalCartons: batch.totalCartons,
                uniqueValidEpcs: batch.totalUniqueValidEpcs,
                uniqueInvalidEpcs: batch.totalUniqueInvalidEpcs,
                uniqueEpcs: batch.totalUniqueValidEpcs + batch.totalUniqueInvalidEpcs
            };
        } catch (error) {
          console.error('Error while trying to map the batch', error);
        }
      });
    }

}
