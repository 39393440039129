import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'reg-invalid-path',
  templateUrl: './invalid-path.component.html',
  styleUrls: ['./invalid-path.component.scss']
})
export class InvalidPathComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
