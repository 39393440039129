<section fxLayout="column" fxLayoutAlign="center center">
  <mat-card fxLayout="column" fxLayoutAlign="space-between center" class="mat-elevation-z4">
    <mat-card-title> {{ 'password_reset.expired_link' | translate }} </mat-card-title>
    <p> {{ 'password_reset.waited' | translate }} </p>
    <p class="or"> {{ 'password_reset.or' | translate }} </p>
    <p *ngIf="fromPage === 'reset'"> {{ 'password_reset.multiple_requests' | translate }} </p>
    <p *ngIf="fromPage === 'signup'"> {{ 'password_reset.new_invite' | translate }} </p>
    <mat-divider [inset]="true"></mat-divider>
    <p> {{ 'password_reset.check_inbox' | translate }} </p>
    <p *ngIf="fromPage === 'reset'"><a (click)="restart()"> {{ 'password_reset.restart' | translate }} </a>  {{ 'password_reset.process' | translate }} </p>
    <p *ngIf="fromPage === 'signup'"> {{ 'password_reset.contact_admin' | translate }} </p>
  </mat-card>
</section>
