import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from './services/auth/auth-guard.service';
import { CatalogIndexResolverService } from './resolvers/catalog-index/catalog-index-resolver.service';
import { AccountsIndexResolverService } from './resolvers/accounts-index/accounts-index-resolver.service';
import { PackageTypesResolverService } from './resolvers/package-types/package-types-resolver.service';
import { TunnelsResolverService } from './resolvers/tunnels/tunnels-resolver.service';
import { StartedBatchesResolverService } from './resolvers/started-batches/started-batches-resolver.service';

import { LoginPageComponent } from './pages/login-page/login-page.component';
import { CatalogEntryIndexComponent } from './pages/catalog-entry-index/catalog-entry-index.component';
import { CreateCatalogEntryComponent } from './pages/create-catalog-entry/create-catalog-entry.component';
import { LinesComponent } from './pages/lines/lines.component';
import { BatchesComponent } from './pages/batches/batches.component';
import { FinishComponent } from './pages/finish/finish.component';
import { ApproveComponent } from './pages/approve/approve.component';
import { FinishedBatchesResolverService } from './resolvers/finished-batches/finished-batches-resolver.service';
import { ArchiveComponent } from './pages/archive/archive.component';
import { ApprovedBatchesResolverService } from './resolvers/approved-batches/approved-batches-resolver.service';
import { InvalidPathComponent } from './pages/invalid-path/invalid-path.component';
import { PermissionDeniedComponent } from './pages/permission-denied/permission-denied.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ExpiredLinkComponent } from './pages/expired-link/expired-link.component';
import { UsersComponent } from './pages/users/users.component';
import { CreateUserComponent } from './pages/create-user/create-user.component';
import { UsersResolverService } from './resolvers/users/users-resolver.service';
import { RolesResolverService } from './resolvers/roles/roles-resolver.service';
import { SignupComponent } from './pages/signup/signup.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { BatchActivityComponent } from './pages/batch-activity/batch-activity.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { AlertActivityComponent } from './pages/alert-activity/alert-activity.component';
import { UserActivityComponent } from './pages/user-activity/user-activity.component';
import { SelectAccountComponent } from './pages/select-account/select-account.component';

// provide titles in translation files, with keys matching route names
// add all route names in auth-guard.service for permissions
export const routes: Routes = [
    {
        path: 'login',
        component: LoginPageComponent,
        data: {
            name: 'login',
            title: 'Login'
        }
    },
    {
        path: 'accounts',
        component: SelectAccountComponent,
        data: {
            name: 'accounts',
            title: 'Select Account'
        },
        resolve: { accounts: AccountsIndexResolverService }
    },
    {
        path: 'batches',
        component: BatchesComponent,
        children: [
            {
                path: '',
                redirectTo: 'lines',
                pathMatch: 'prefix'
            },
            {
                path: 'lines',
                component: LinesComponent,
                data: {
                    name: 'Lines',
                    title: 'Batches - Lines',
                    countAttribute: 'active',
                    showOnlyWhenLoggedIn: false
                },
                resolve: { tunnels: TunnelsResolverService },
                runGuardsAndResolvers: 'always',
                canActivate: [AuthGuardService]
            },
            {
                path: 'finish',
                component: FinishComponent,
                data: {
                    name: 'Finish',
                    title: 'Batches - Finish',
                    countAttribute: 'started',
                    showOnlyWhenLoggedIn: true
                },
                resolve: { batches: StartedBatchesResolverService },
                canActivate: [AuthGuardService],
                runGuardsAndResolvers: 'always'
            },
            {
                path: 'approve',
                component: ApproveComponent,
                data: {
                    name: 'Approve',
                    title: 'Batches - Approve',
                    countAttribute: 'finished',
                    showOnlyWhenLoggedIn: true
                },
                resolve: { batches: FinishedBatchesResolverService },
                canActivate: [AuthGuardService],
                runGuardsAndResolvers: 'always'
            },
            {
                path: 'archive',
                component: ArchiveComponent,
                data: {
                    name: 'Archive',
                    title: 'Batches - Archive',
                    showOnlyWhenLoggedIn: true
                },
                resolve: { batches: ApprovedBatchesResolverService },
                canActivate: [AuthGuardService]
            }
        ],
        data: {
            name: 'batches',
            title: 'Batches',
            showOnMenu: true
        }
    },
    {
        path: 'catalog',
        component: CatalogEntryIndexComponent,
        data: {
            name: 'catalog',
            title: 'Catalog',
            showOnMenu: true
        },
        canActivate: [AuthGuardService],
        resolve: { catalogs: CatalogIndexResolverService }
    },
    {
        path: 'alert-activity',
        component: AlertActivityComponent,
        data: {
            name: 'Alert Activity',
            title: 'Alert Activity'
        },
        canActivate: [AuthGuardService]
    },
    {
        path: 'reports',
        component: ReportsComponent,
        data: {
            name: 'reports',
            title: 'reports',
            showOnMenu: true
        },
        canActivate: [AuthGuardService],
        resolve: { catalogs: CatalogIndexResolverService }
    },
    {
        path: 'batch-activity',
        component: BatchActivityComponent,
        data: {
            name: 'Batch Activity',
            title: 'Batch Activity',
        },
        canActivate: [AuthGuardService]
    },
    {
        path: 'user-activity',
        component: UserActivityComponent,
        data: {
            name: 'User Activity',
            title: 'User Activity',
        },
        canActivate: [AuthGuardService],
        resolve: { users: UsersResolverService }
    },
    {
        path: 'users',
        component: UsersComponent,
        data: {
            name: 'users',
            title: 'Users',
            showOnMenu: true
        },
        canActivate: [AuthGuardService],
        resolve: { roles: RolesResolverService, users: UsersResolverService },
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'users/create-user',
        component: CreateUserComponent,
        data: {
            name: 'create-user',
            title: 'Create User'
        },
        canActivate: [AuthGuardService],
        resolve: { roles: RolesResolverService }
    },
    {
        path: 'catalog/create-entry',
        component: CreateCatalogEntryComponent,
        data: {
            name: 'create-catalog-entry',
            title: 'Create Catalog Entry'
        },
        canActivate: [AuthGuardService],
        resolve: { packageTypes: PackageTypesResolverService }
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        data: {
            name: 'forgot-password',
            title: 'Forgot Password'
        },
    },
    {
        path: 'password-reset',
        component: PasswordResetComponent,
        data: {
            name: 'password-reset',
            title: 'Password Reset'
        },
    },
    {
        path: 'signup',
        component: SignupComponent,
        data: {
            name: 'signup',
            title: 'Signup'
        },
    },
    {
        path: 'permission-denied',
        component: PermissionDeniedComponent,
        data: {
            name: 'permission-denied',
            title: 'Permission Denied'
        }
    },
    {
        path: 'expired-link',
        component: ExpiredLinkComponent,
        data: {
            name: 'expired-link',
            title: 'Expired Link'
        }
    },
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: '**',
        component: InvalidPathComponent
    }

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {useHash: true, onSameUrlNavigation: 'reload'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
