import * as tslib_1 from "tslib";
import { JsonApiModelConfig, JsonApiModel, Attribute, HasMany, BelongsTo } from 'angular2-jsonapi';
import { ProductionRun } from './production-run';
var Tunnel = /** @class */ (function (_super) {
    tslib_1.__extends(Tunnel, _super);
    function Tunnel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        Attribute(),
        tslib_1.__metadata("design:type", String)
    ], Tunnel.prototype, "name", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'software-version' }),
        tslib_1.__metadata("design:type", String)
    ], Tunnel.prototype, "softwareVersion", void 0);
    tslib_1.__decorate([
        HasMany({ key: 'active-alerts' }),
        tslib_1.__metadata("design:type", Array)
    ], Tunnel.prototype, "activeAlerts", void 0);
    tslib_1.__decorate([
        BelongsTo({ key: 'active-production-run' }),
        tslib_1.__metadata("design:type", ProductionRun)
    ], Tunnel.prototype, "activeProductionRun", void 0);
    Tunnel = tslib_1.__decorate([
        JsonApiModelConfig({
            type: 'tunnels'
        })
    ], Tunnel);
    return Tunnel;
}(JsonApiModel));
export { Tunnel };
