<section fxLayout="column" fxLayoutAlign="center center">
    <div class="select-account" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <h1 class="select-title">{{'select_account.title' | translate}}</h1>
        <div class="select-account-list">
            <mat-action-list>
                <button mat-list-item class="select-account-item" *ngFor="let account of accounts" (click)="selectAccount(account)">
                    {{account.name}} [{{account.id}}]
                </button>
            </mat-action-list>
        </div>
    </div>
</section>
