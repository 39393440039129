import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataStore } from '../../services/datastore/datastore.service';
import { PackageDescription } from '../../models/package-description';

@Injectable({
  providedIn: 'root'
})

export class PackageTypesResolverService implements Resolve<PackageDescription[]> {

    constructor(private datastore: DataStore) {}

    resolve(
        _route: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot
    ): Observable<PackageDescription[]> {
        return this.datastore.findAll(PackageDescription).pipe(
            map(packageTypesResult => packageTypesResult.getModels())
        );
    }
}
