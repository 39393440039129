import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatSort } from '@angular/material';

@Component({
  selector: 'reg-catalog-entry-details',
  templateUrl: './catalog-entry-details.component.html',
  styleUrls: ['./catalog-entry-details.component.scss']
})
export class CatalogEntryDetailsComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['ndcFull', 'gtin', 'packageCount'];
  dataSource: MatTableDataSource<any>;

  constructor(
    public dialogRef: MatDialogRef<CatalogEntryDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.data.item.unitOfSales);
    this.dataSource.sort = this.sort;
  }

  onCancel() {
      this.dialogRef.close();
  }
}
