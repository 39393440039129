import { JsonApiModelConfig, JsonApiModel, Attribute, BelongsTo } from 'angular2-jsonapi';
import { UnitOfSale} from './unit-of-sale';
import { User } from './user';
import { Account } from './account';

@JsonApiModelConfig({
    type: 'production-runs'
})
export class ProductionRun extends JsonApiModel {
    @Attribute({ serializedName: 'lot-number' })
    lotNumber: string;

    @Attribute({ serializedName: 'expiration-manufacturer' })
    expirationManufacturer: string;

    @Attribute({ serializedName: 'active-batch-on-tunnel' })
    activeBatch: boolean;

    @Attribute({ serializedName: 'first-scan' })
    firstScan: string;

    @Attribute({ serializedName: 'last-scan' })
    lastScan: string;

    @Attribute()
    state: string;

    @Attribute()
    test: boolean;

    @Attribute({ serializedName: 'tunnel-name' })
    tunnelName: string;

    @Attribute({ serializedName: 'finished-at' })
    finishedAt: string;

    @Attribute({ serializedName: 'approved-at' })
    approvedAt: string;

    @Attribute({ serializedName: 'scan-accuracy' })
    scanAccuracy: string;

    @Attribute({ serializedName: 'total-cartons' })
    totalCartons: number;

    @Attribute({ serializedName: 'total-unique-invalid-epcs' })
    totalUniqueInvalidEpcs: number;

    @Attribute({ serializedName: 'total-unique-valid-epcs' })
    totalUniqueValidEpcs: number;

    @Attribute({ serializedName: 'formatted-batch-type' })
    formattedBatchType: string;

    @BelongsTo({key: 'unit-of-sale'})
    unitOfSale: UnitOfSale;

    @BelongsTo({key: 'finished-by'})
    finishedBy: User;

    @BelongsTo({key: 'approved-by'})
    approvedBy: User;

    @BelongsTo({key: 'account'})
    account: Account;

    get catalogEntry() {
      return !!this.unitOfSale ? this.unitOfSale.catalogEntry : null;
    }

    get drugInformation() {
      return !!this.catalogEntry ? this.catalogEntry.drugInformation : '';
    }
}
