<h1 mat-dialog-title> {{data.name}} {{ 'lines.alert_dialog.line_error' | translate }} </h1>
<p>{{data.alertMessage}}</p>
<p> {{'lines.alert_dialog.contact' | translate }} <a href="{{'mailto:' + emailService.getSupportEmail()}}" target="_blank">{{emailService.getSupportEmail()}}</a> {{'lines.alert_dialog.contact_support' | translate }} </p>
<mat-form-field>
  <input matInput required [(ngModel)]="data.acceptanceReason" placeholder="{{ 'lines.alert_dialog.acknowledgement_reason' | translate }}">
</mat-form-field>
<div class="submit" mat-dialog-actions  fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
  <button mat-stroked-button (click)="onCancel()"> {{ 'cancel' | translate }} </button>
  <button mat-raised-button color="accent" [disabled]="!data.acceptanceReason" [mat-dialog-close]="{acceptanceReason: data.acceptanceReason, alertId: data.alertId}">
    {{ 'lines.alert_dialog.submit' | translate }}
  </button>
</div>
