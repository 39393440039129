import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';

import { AuthService } from './auth.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})

export class AuthGuardService implements CanActivate {

    constructor(
        private authService: AuthService,
        private router: Router,
        private toast: MatSnackBar,
        private translate: TranslateService
    ) { }

    roles(tab) {
        if (tab === 'catalog') {
            return ['registry_admin_user', 'line_operator', 'batch_approver', 'auditor', 'catalog_manager'];
        } else if (['batches', 'Lines'].includes(tab)) {
            return ['registry_admin_user', 'kiosk', 'line_operator', 'batch_approver', 'auditor'];
        } else if (['Finish', 'Approve', 'Archive'].includes(tab)) {
            return ['registry_admin_user', 'line_operator', 'batch_approver', 'auditor'];
        } else if (tab === 'users' || tab === 'create-user') {
            return ['registry_admin_user', 'user_manager'];
        } else if (['reports', 'Batch Activity', 'Alert Activity', 'User Activity'].includes(tab)) {
            return ['registry_admin_user', 'auditor'];
        } else if (tab === 'create-catalog-entry') {
            return ['registry_admin_user', 'catalog_manager'];
        }
    }

    canActivate(
        route: ActivatedRouteSnapshot
    ): boolean {
        if (route.data.name === 'Lines') { // don't need to be logged in to access Lines
            if (this.authService.hasRole(this.roles(route.data.name))) {
                return true;
            } else {
                this.router.navigate(['permission-denied']);
                return false;
            }
        } else {
            if (this.authService.isLoggedIn()) {
                    if (this.authService.hasRole(this.roles(route.data.name))) {
                        return true;
                    } else {
                            this.router.navigate(['permission-denied']);
                            return false;
                    }
            } else {
                this.translate.get(['required_login', 'dismiss'], {page: route.data.title}).subscribe(translation => {
                    this.router.navigate(['login']);
                    this.toast.open(translation['required_login'], translation['dismiss'], {
                        duration: 3000
                    });
                    return false;
                });
            }
        }

    }
}
