import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'reg-transition-batch',
  templateUrl: './transition-batch.component.html',
  styleUrls: ['./transition-batch.component.scss']
})
export class TransitionBatchComponent {
  batchTypes: [string, boolean][] = [];
  test: boolean;
  constructor(
      public dialogRef: MatDialogRef<TransitionBatchComponent>,
      private translate: TranslateService,
      @Inject(MAT_DIALOG_DATA) public data
  ) {
      this.getTranslations();
    }

  getTranslations() {
    this.translate.get([
      'transition_batch.production',
      'transition_batch.test'
    ]).subscribe(translation => {
      this.batchTypes.push([translation['transition_batch.production'], false]);
      this.batchTypes.push([translation['transition_batch.test'], true]);
      this.test = false;
    });
  }

  onCancel() {
      this.dialogRef.close();
  }
}
