import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataStore } from 'src/app/services/datastore/datastore.service';
import { Tunnel } from 'src/app/models/tunnel';

@Injectable({
  providedIn: 'root'
})
export class TunnelsResolverService implements Resolve<any> {

  constructor(private datastore: DataStore) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Tunnel[]> {
  return this.datastore.findAll(Tunnel).pipe(
    map(catalogEntriesResult => catalogEntriesResult.getModels())
  );
}
}
