export interface PackageDescriptionModel {
    id: number;
    abbreviation: string;
    name: string;
}

import { JsonApiModelConfig, JsonApiModel, Attribute } from 'angular2-jsonapi';

@JsonApiModelConfig({
    type: 'package-descriptions'
})
export class PackageDescription extends JsonApiModel {

    @Attribute()
    abbreviation: string;

    @Attribute()
    name: string;
}
