<mat-card class="mat-elevation-z4">
    <div fxLayout="row" fxLayoutAlign="space-around center">
        <mat-card-title class="filters" fxFlexAlign="start"> {{ 'reports.filters' | translate }} </mat-card-title>
        <mat-form-field fxFlex="15">
        <mat-select data-e2e="date.type" [(ngModel)]="dateRange" [placeholder]="'reports.date_range' | translate">
                <mat-option *ngFor="let dateRange of dateRanges" [value]="dateRange">
                    {{dateRange}}
                </mat-option>
        </mat-select>
        </mat-form-field>
    
        <div fxFlex="15" fxFlexOffset="3" fxLayout="column" fxLayoutAlign="space-between">
            <mat-form-field >
                <mat-label [innerText]="'reports.start_date' | translate"></mat-label>
                <input data-e2e="start.date" [required]="this.endDate || this.startDate" matInput [matDatepicker]="start" [(ngModel)]="startDate" (dateChange)="startDateChanged($event)">
                <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
                <mat-datepicker #start></mat-datepicker>
            </mat-form-field>
            
            
            <mat-form-field>
                <mat-label [innerText]="'reports.end_date' | translate"></mat-label>
                <input data-e2e="end.date" [required]="this.endDate || this.startDate" matInput [matDatepicker]="end" [(ngModel)]="endDate" (dateChange)="endDateChanged($event)">
                <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
                <mat-datepicker #end></mat-datepicker>
            </mat-form-field>
        </div>

        <mat-form-field fxFlex="15" fxFlexOffset="3">
            <mat-select data-e2e="alert.type" [(ngModel)]="alertType" [placeholder]="'alert_activity.alert_type' | translate">
                    <mat-option *ngFor="let type of alertTypes" [value]="type">
                        {{alertTranslations[type]}}
                    </mat-option>
            </mat-select>
        </mat-form-field>
        <a (click)="clearFilters()"> {{ 'reports.clear_filters' | translate }} </a>
        <div fxLayout="column" fxLayoutAlign="space-between">
            <button class="run-report" [disabled]="isDisabled()" mat-raised-button (click)="filterAlerts()" color="accent"> {{ 'reports.run_report' | translate }} </button>
            <button class="download" fxFlexOffset="10" mat-stroked-button (click)="download()"> {{ 'reports.download' | translate }} </button>
        </div>
    </div>
</mat-card>
  
<mat-card class="mat-elevation-z4">
    <mat-card-title> {{ 'alert_activity.title' | translate }} </mat-card-title>
    <table mat-table [dataSource]="dataSource" matSort fxFill  (matSortChange)="onSortData($event)">
        <ng-container matColumnDef="alertType">
            <th class="drug-header" mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'alert_activity.alert_type' | translate }} </th>
            <td class="drug-row" mat-cell *matCellDef="let row"> {{alertTranslations[row.alertType]}} </td>
        </ng-container>

        <ng-container matColumnDef="line">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'alert_activity.line' | translate }} </th>
            <td mat-cell *matCellDef="let row"> {{row.line}} </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'alert_activity.created' | translate }} </th>
            <td mat-cell *matCellDef="let row"> {{row.createdAt}} </td>
        </ng-container>

        <ng-container matColumnDef="drugInformation">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'alert_activity.drug' | translate }} </th>
            <td mat-cell *matCellDef="let row"> {{row.drugInformation}} </td>
        </ng-container>

        <ng-container matColumnDef="lotNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'alert_activity.lot' | translate }} </th>
            <td mat-cell *matCellDef="let row"> {{row.lotNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="acknowledgedAt">
            <th class="last-column" mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'alert_activity.acknowledged' | translate }} </th>
            <td mat-cell *matCellDef="let row"> {{row.acknowledgedAt}} </td>
        </ng-container>

        <ng-container matColumnDef="acknowledgedBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'alert_activity.acknowledged_by' | translate }} </th>
            <td mat-cell *matCellDef="let row"> {{row.acknowledgedBy}} </td>
        </ng-container>

        <ng-container matColumnDef="resolution">
            <th class="last-column" mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'alert_activity.reason' | translate }} </th>
            <td mat-cell *matCellDef="let row"> {{row.resolution}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <ng-container *ngIf="!dataSource.data.length">
        <div class="no-batches" fxLayout="row" fxLayoutAlign="center center">
            <p> {{ 'reports.select_filters' | translate }} </p>
        </div>
    </ng-container>

    <mat-paginator [pageSize]="100" [pageSizeOptions]="[10, 20, 50, 100]"  showFirstLastButtons></mat-paginator>
</mat-card>
