import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CatalogEntryModel } from '../../models/catalog-entry';
import { urls } from '../../../urls';

@Injectable({
    providedIn: 'root'
})

export class CatalogResourceService {
    CATALOG_ENTRIES_URL = `${urls.API_URL}/catalog-entries`;

    constructor(private http: HttpClient) { }

    public createCatalogEntry(newCatalogData): Observable<any> {
        return this.http.post<CatalogEntryModel>(this.CATALOG_ENTRIES_URL, newCatalogData).pipe(
            // tap(catalogPostResult => console.log(catalogPostResult))
        );
    }

    public createMockCatalogEntries(num: number) {
        Array.from({ length: num }).forEach((val, index) => {
            this.createCatalogEntry({
                ndc: `12-124-19-${index}`,
                itemName: 'Fentanly',
                manufacturerName: 'Amgen',
                itemStrength: {
                    scalar: 5,
                    uom: 'p/nm'
                },
                packageSize: {
                    scalar: 100,
                    uom: 'mg'
                },
                packageDescriptionId: 31
            }).subscribe();
        });
    }
}
