import { Injectable } from '@angular/core';
import { urls } from 'src/urls';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataStore } from '../datastore/datastore.service';
import { UserActivity } from 'src/app/models/user-activity';

@Injectable({
  providedIn: 'root'
})
export class UserActivityService {
  USER_ACTIVITY_URL = `${urls.API_URL}/user-activities`;

  constructor(
    private http: HttpClient,
    private datastore: DataStore
  ) { }

  public getUserActivities(startDate, endDate, email, activityType): Observable<any> {
    const parameters: any = {};
    if (startDate) {
        parameters.time_filter_start = startDate;
    }

    if (endDate) {
        parameters.time_filter_end = endDate;
    }

    if (email) {
        parameters.email = email;
    }

    if (activityType) {
      parameters.activity_type = activityType;
    }

    return this.datastore.findAll(UserActivity, parameters).pipe(
        map(activityResult => activityResult.getModels())
    );
  }

  public downloadUserActivity(downloadFilters: any): Observable<any> {
    let params = new HttpParams();
    if (downloadFilters.startDate) {
        params = params.append('time_filter_start', downloadFilters.startDate);
    }

    if (downloadFilters.endDate) {
        params = params.append('time_filter_end', downloadFilters.endDate);
    }

    if (downloadFilters.email) {
        params = params.append('email', downloadFilters.email);
    }

    if (downloadFilters.type) {
        params = params.append('activity_type', downloadFilters.type);
    }

    const options = {responseType: 'text' as 'json', params: params};

    return this.http.get<any>(`${this.USER_ACTIVITY_URL}.csv`, options ).pipe(
        // tap(result => console.log(result))
    );
}
}
