import { Injectable } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ProductionRunsResourceService } from '../production-runs/production-runs-resource.service';
import { TransitionBatchComponent } from 'src/app/dialogs/transition-batch/transition-batch.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { RejectBatchDialogComponent } from 'src/app/dialogs/reject-batch/reject-batch.component';

@Injectable({
    providedIn: 'root'
})
export class TransitionBatchService {

    dismiss: string;
    finishedMessage: string;
    approvedMessage: string;
    rejectMessage: string;

    constructor(
        private toast: MatSnackBar,
        private dialog: MatDialog,
        private productionRunsService: ProductionRunsResourceService,
        private translate: TranslateService,
        private router: Router
    ) {
        this.translate.get([
            'dismiss',
            'transition_batch.successfully_finished',
            'transition_batch.successfully_approved',
            'transition_batch.successfully_rejected'
        ]).subscribe(translation => {
            this.dismiss = translation['dismiss'];
            this.finishedMessage = translation['transition_batch.successfully_finished'];
            this.approvedMessage = translation['transition_batch.successfully_approved'];
            this.rejectMessage = translation['transition_batch.successfully_rejected'];
        });
    }

    finishBatch(batch, page) {
        const finishDialog = this.dialog.open(TransitionBatchComponent, {
            width: '800px',
            data: {
                action: 'finish',
                batch: batch
            }
        });
        finishDialog.afterClosed().subscribe(result => {
            if (result) {
                this.productionRunsService.finish(result.id, result.test).subscribe(() => {
                    if (page === 'lines') {
                        this.router.navigate(['batches/lines']);
                    } else {
                        this.router.navigate(['batches/finish']);
                    }
                    this.toast.open(this.finishedMessage, this.dismiss, {
                        duration: 8000,
                        panelClass: 'success-snackbar'
                    });
                });
            }
        });
    }

    rejectBatch(batch) {
      const rejectDialog = this.dialog.open(RejectBatchDialogComponent, {
        width: '800px',
      });

      rejectDialog.afterClosed().subscribe(result => {
          if (!result) {
            return;
          }

          this.productionRunsService.reject(batch.id).subscribe(() => {
            this.router.navigate(['batches/approve']);
            this.toast.open(this.rejectMessage, this.dismiss, {
                duration: 8000,
                panelClass: 'success-snackbar'
            });
          });
      });
  }

    approveBatch(batch) {
        const approveDialog = this.dialog.open(TransitionBatchComponent, {
            width: '800px',
            data: {
                action: 'approve',
                batch: batch
            }
        });
        approveDialog.afterClosed().subscribe(result => {
            if (result) {
                this.productionRunsService.approve(result.id).subscribe(() => {
                    this.router.navigate(['batches/approve']);
                    this.toast.open(this.approvedMessage, this.dismiss, {
                        duration: 8000,
                        panelClass: 'success-snackbar'
                    });
                });
            }
        });
    }
}
