import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatSnackBar, MatSnackBarRef } from '@angular/material';
import { Router, Route, ActivatedRoute, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { tap, map, filter, mergeMap, } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { environment } from '../environments/environment';
import { AuthService } from './services/auth/auth.service';
import { PasswordResetService } from './services/auth/password-reset.service';
import { KioskService } from './services/auth/kiosk.service';
import { VersionService } from './services/version/version.service';
import { EmailService } from './services/email/email.service';
import { TranslateService } from '@ngx-translate/core';
import { DatadogService } from './services/vendor/datadog.service';

import * as packageJson from '../../package.json';

@Component({
  selector: 'reg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

    menuItems: Route[] = this.router.config.filter(route => route.data && route.data.showOnMenu);
    date: Date = new Date;
    version: string;
    backendVersion: string;
    currentPage: string;
    parentPageName: string;
    pageLoading = false;
    titles: Array<string>;
    routeTitle;
    dismiss: string;
    successMessage: string;
    inactivityMessage: string;
    secondsMessage: string;

    userActivityTimeout;
    userActivityInterval;
    isUserActive = true;
    versionOutdated = false;
    userInactive: Subject<any> = new Subject();
    inActivityTimeoutInSeconds: number = environment.inactivityTimeoutInSeconds;
    inactivityPingInSeconds = 15;
    activityPingInSeconds = 15;
    displayInactivityThresholdMinutes = 5;
    outdatedVersionCheckSeconds = 60;
    toaster: MatSnackBarRef<any>;
    titleTranslation = {};

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        public authService: AuthService,
        private versionService: VersionService,
        public emailService: EmailService,
        /* tslint:disable:no-unused-variable */
        private passwordResetService: PasswordResetService,
        /* tslint:disable:no-unused-variable */
        private kioskService: KioskService,
        private toast: MatSnackBar,
        private translate: TranslateService,
        private datadogService: DatadogService,
    ) {
        translate.addLangs(['en', 'fr']);
        translate.setDefaultLang('en');
        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');

        translate.get([
            'logout_success',
            'dismiss',
            'titles.batches',
            'titles.catalog',
            'titles.users',
            'titles.reports'
        ]).subscribe(translation => {
            this.dismiss = translation['dismiss'];
            this.successMessage = translation['logout_success'];
            this.titleTranslation['batches'] = translation['titles.batches'];
            this.titleTranslation['catalog'] = translation['titles.catalog'];
            this.titleTranslation['users'] = translation['titles.users'];
            this.titleTranslation['reports'] = translation['titles.reports'];
        });

        // Subscribes to NavigationEnd events and pulls route data to set title and currentPage
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map(route => {
                while (route.firstChild) { route = route.firstChild; }
                return route;
            }),
            tap(route => this.parentPageName = route.parent.data['_value'].name),
            mergeMap(route => route.data),
            tap(routeData => this.currentPage = routeData.name),
            tap(routeData => this.titleService.setTitle(`Bluesight - Registry Service: ${routeData.title}`)),
            // tap(routeData => console.log(routeData))
        ).subscribe();

        // Subscribes to NavigationStart event for showing spinner
        this.router.events.pipe(
            filter(event => event instanceof NavigationStart),
            tap(() => this.pageLoading = true),
            // tap(event => console.log(event))
        ).subscribe();

        // Hides the spinner after NavigationEnd, NavigationCancel, or NavigationError
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError),
            tap(() => this.pageLoading = false),
            // tap(event => console.log(event))
        ).subscribe();
    }

    setVersion() {
        this.version = `${packageJson.version} / ?`;
        this.versionService.getBackEndVersion().subscribe(version =>  {
            this.backendVersion = version;
            this.version = `${packageJson.version} / ${version}`;
        });
    }

    ngOnInit() {
        this.setVersion();

        // set support email
        this.emailService.setEmails();

        // Outdated version banner
        setInterval(() => {
            this.versionService.getBackEndVersion().subscribe(version =>  {
                this.versionOutdated = (!!this.backendVersion && version !== this.backendVersion);
            });
        }, this.outdatedVersionCheckSeconds * 1000);

        // Beigin Activity timeout logic
        setInterval(() => {
            if (this.isUserActive && this.authService.isLoggedIn() && this.currentPage !== 'login' && !this.authService.isInKioskMode()) {
                this.authService.notifyInactivity(0).subscribe();
            }
        }, this.activityPingInSeconds * 1000);

        this.setActivityTimeout();

        this.userInactive.subscribe(() => {
            this.isUserActive = false;
            let secondsSinceActivity: number = this.inActivityTimeoutInSeconds;

            this.userActivityInterval = setInterval(() => {
                if (this.authService.isLoggedIn() && this.currentPage !== 'login') {
                    this.authService.notifyInactivity(secondsSinceActivity).subscribe((data) => {
                        const timeToTimeout = data['time_to_timeout'];
                        const logoutInMinutes = Math.floor(timeToTimeout / 60);
                        const secondsRemainder = timeToTimeout % 60;

                        if (logoutInMinutes <= this.displayInactivityThresholdMinutes) {
                            if (logoutInMinutes < 1) {
                                this.translate.get(
                                    'browser_inactivity_seconds', {seconds: secondsRemainder}).subscribe(translation => {
                                        this.toaster = this.toast.open(translation);
                                    });
                            } else if (secondsRemainder === 0 ) {
                                this.translate.get('browser_inactivity_minutes', {minutes: logoutInMinutes}).subscribe(translation => {
                                    this.toaster = this.toast.open(translation);
                                });
                            }
                        }
                    });
                }

                secondsSinceActivity += this.inactivityPingInSeconds;
            }, this.inactivityPingInSeconds * 1000);
        });
        this.datadogService.load(environment, packageJson.version);
    }

    roles(tab) {
        if (tab === 'catalog') {
            return ['registry_admin_user', 'line_operator', 'batch_approver', 'auditor', 'catalog_manager'];
        } else if (tab === 'batches') {
            return ['registry_admin_user', 'line_operator', 'batch_approver', 'auditor'];
        } else if (tab === 'users') {
            return ['registry_admin_user', 'user_manager'];
        } else if (tab === 'reports') {
            return ['registry_admin_user', 'auditor'];
        }
    }

    navName() {
        const name = localStorage.getItem('navName');
        return this.authService.isLoggedIn() && !!name ?  name : '';
    }

    setActivityTimeout() {
        if (this.authService.isLoggedIn() && !this.authService.isInKioskMode()) {
            this.userActivityTimeout = setTimeout(() => this.userInactive.next(), (this.inActivityTimeoutInSeconds - this.inactivityPingInSeconds) * 1000);
        }
    }

    @HostListener('window:mousemove')
    @HostListener('document:keydown')
    refreshUserState() {
        // clear toast
        clearTimeout(this.userActivityTimeout);
        this.setActivityTimeout();

        if (!this.authService.isInKioskMode()) {
            clearInterval(this.userActivityInterval);
        } else {
            clearTimeout(this.userActivityInterval);
        }

        if (this.toaster) {
            this.toaster.dismiss();
        }

        this.isUserActive = true;
    }

    isOnLoginPages() {
        return ['login', 'forgot-password', 'password-reset', 'signup', 'expired-link'].includes(this.currentPage);
    }

    isOnBatchesPage(): boolean {
        return this.router.url.indexOf('/batches') === 0;
    }

    showBatchTab(data): boolean {
        return !this.authService.isInKioskMode() || !data.showOnlyWhenLoggedIn;
    }

    shouldDisplayNavLinks() {
        return !this.isOnLoginPages() && (
            this.authService.isInKioskMode() ||
            this.authService.isLoggedIn()
        );
    }

    logoutUser() {
        this.authService.destroySession().subscribe(() => {
            if (this.authService.isInKioskMode()) {
                this.refreshUserState();
            }
            this.toast.open(this.successMessage, this.dismiss, {
                duration: 3000,
                panelClass: 'success-snackbar',
            });
        });
    }

    goToLoginPage() {
        this.router.navigate(['login']);
    }

    goToSelectAccountPage() {
        this.router.navigate(['accounts']);
    }

    reloadPage() {
        window.location.reload();
    }

    isRegistryAdmin(): boolean {
        return this.authService.hasRole('registry_admin_user');
    }
}
