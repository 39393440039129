import * as tslib_1 from "tslib";
import { JsonApiModelConfig, JsonApiModel, Attribute, BelongsTo } from 'angular2-jsonapi';
import { UnitOfSale } from './unit-of-sale';
import { User } from './user';
import { Account } from './account';
var ProductionRun = /** @class */ (function (_super) {
    tslib_1.__extends(ProductionRun, _super);
    function ProductionRun() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ProductionRun.prototype, "catalogEntry", {
        get: function () {
            return !!this.unitOfSale ? this.unitOfSale.catalogEntry : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProductionRun.prototype, "drugInformation", {
        get: function () {
            return !!this.catalogEntry ? this.catalogEntry.drugInformation : '';
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        Attribute({ serializedName: 'lot-number' }),
        tslib_1.__metadata("design:type", String)
    ], ProductionRun.prototype, "lotNumber", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'expiration-manufacturer' }),
        tslib_1.__metadata("design:type", String)
    ], ProductionRun.prototype, "expirationManufacturer", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'active-batch-on-tunnel' }),
        tslib_1.__metadata("design:type", Boolean)
    ], ProductionRun.prototype, "activeBatch", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'first-scan' }),
        tslib_1.__metadata("design:type", String)
    ], ProductionRun.prototype, "firstScan", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'last-scan' }),
        tslib_1.__metadata("design:type", String)
    ], ProductionRun.prototype, "lastScan", void 0);
    tslib_1.__decorate([
        Attribute(),
        tslib_1.__metadata("design:type", String)
    ], ProductionRun.prototype, "state", void 0);
    tslib_1.__decorate([
        Attribute(),
        tslib_1.__metadata("design:type", Boolean)
    ], ProductionRun.prototype, "test", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'tunnel-name' }),
        tslib_1.__metadata("design:type", String)
    ], ProductionRun.prototype, "tunnelName", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'finished-at' }),
        tslib_1.__metadata("design:type", String)
    ], ProductionRun.prototype, "finishedAt", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'approved-at' }),
        tslib_1.__metadata("design:type", String)
    ], ProductionRun.prototype, "approvedAt", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'scan-accuracy' }),
        tslib_1.__metadata("design:type", String)
    ], ProductionRun.prototype, "scanAccuracy", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'total-cartons' }),
        tslib_1.__metadata("design:type", Number)
    ], ProductionRun.prototype, "totalCartons", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'total-unique-invalid-epcs' }),
        tslib_1.__metadata("design:type", Number)
    ], ProductionRun.prototype, "totalUniqueInvalidEpcs", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'total-unique-valid-epcs' }),
        tslib_1.__metadata("design:type", Number)
    ], ProductionRun.prototype, "totalUniqueValidEpcs", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'formatted-batch-type' }),
        tslib_1.__metadata("design:type", String)
    ], ProductionRun.prototype, "formattedBatchType", void 0);
    tslib_1.__decorate([
        BelongsTo({ key: 'unit-of-sale' }),
        tslib_1.__metadata("design:type", UnitOfSale)
    ], ProductionRun.prototype, "unitOfSale", void 0);
    tslib_1.__decorate([
        BelongsTo({ key: 'finished-by' }),
        tslib_1.__metadata("design:type", User)
    ], ProductionRun.prototype, "finishedBy", void 0);
    tslib_1.__decorate([
        BelongsTo({ key: 'approved-by' }),
        tslib_1.__metadata("design:type", User)
    ], ProductionRun.prototype, "approvedBy", void 0);
    tslib_1.__decorate([
        BelongsTo({ key: 'account' }),
        tslib_1.__metadata("design:type", Account)
    ], ProductionRun.prototype, "account", void 0);
    ProductionRun = tslib_1.__decorate([
        JsonApiModelConfig({
            type: 'production-runs'
        })
    ], ProductionRun);
    return ProductionRun;
}(JsonApiModel));
export { ProductionRun };
