/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./invalid-path.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./invalid-path.component";
var styles_InvalidPathComponent = [i0.styles];
var RenderType_InvalidPathComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InvalidPathComponent, data: {} });
export { RenderType_InvalidPathComponent as RenderType_InvalidPathComponent };
export function View_InvalidPathComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["fxLayout", "row"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("invalid_path")); _ck(_v, 4, 0, currVal_2); }); }
export function View_InvalidPathComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "reg-invalid-path", [], null, null, null, View_InvalidPathComponent_0, RenderType_InvalidPathComponent)), i1.ɵdid(1, 114688, null, 0, i5.InvalidPathComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InvalidPathComponentNgFactory = i1.ɵccf("reg-invalid-path", i5.InvalidPathComponent, View_InvalidPathComponent_Host_0, {}, {}, []);
export { InvalidPathComponentNgFactory as InvalidPathComponentNgFactory };
