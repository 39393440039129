import { map } from 'rxjs/operators';
import { DataStore } from '../../services/datastore/datastore.service';
import { Account } from '../../models/account';
import * as i0 from "@angular/core";
import * as i1 from "../../services/datastore/datastore.service";
var AccountsIndexResolverService = /** @class */ (function () {
    function AccountsIndexResolverService(datastore) {
        this.datastore = datastore;
    }
    AccountsIndexResolverService.prototype.resolve = function (_route, _state) {
        return this.datastore.findAll(Account).pipe(map(function (accounts) { return accounts.getModels(); }));
    };
    AccountsIndexResolverService.ngInjectableDef = i0.defineInjectable({ factory: function AccountsIndexResolverService_Factory() { return new AccountsIndexResolverService(i0.inject(i1.DataStore)); }, token: AccountsIndexResolverService, providedIn: "root" });
    return AccountsIndexResolverService;
}());
export { AccountsIndexResolverService };
