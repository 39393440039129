import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataStore } from '../../services/datastore/datastore.service';
import { Account } from '../../models/account';

@Injectable({
  providedIn: 'root'
})

export class AccountsIndexResolverService implements Resolve<Account[]> {

    constructor(private datastore: DataStore) {}

    resolve(
        _route: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot
    ): Observable<Account[]> {
        return this.datastore.findAll(Account).pipe(
            map(accounts => accounts.getModels()),
        );
    }
}
