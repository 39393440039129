export function downloadBlob(filename: string, blob: Blob): void {
    if (window['Cypress']) {
        return;
    } else if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
    } else {
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.setAttribute('download', filename);
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }
}

export function downloadCsv(filename: string, data: any): void {
    const blob = new Blob(['\uFEFF' + data], {type: 'text/csv;charset=utf-8'});
    downloadBlob(filename, blob);
}
