import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'reg-expired-link',
  templateUrl: './expired-link.component.html',
  styleUrls: ['./expired-link.component.scss']
})
export class ExpiredLinkComponent implements OnInit {

  routeParamSub: Subscription;
  fromPage: string;

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.routeParamSub = this.route.queryParams.pipe(
      tap(({ fromPage }) => this.fromPage = fromPage),
    ).subscribe();
  }

  restart() {
    this.router.navigate(['forgot-password']);
  }


}
