import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
import * as i3 from "@angular/material/snack-bar";
import * as i4 from "@ngx-translate/core";
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(authService, router, toast, translate) {
        this.authService = authService;
        this.router = router;
        this.toast = toast;
        this.translate = translate;
    }
    AuthGuardService.prototype.roles = function (tab) {
        if (tab === 'catalog') {
            return ['registry_admin_user', 'line_operator', 'batch_approver', 'auditor', 'catalog_manager'];
        }
        else if (['batches', 'Lines'].includes(tab)) {
            return ['registry_admin_user', 'kiosk', 'line_operator', 'batch_approver', 'auditor'];
        }
        else if (['Finish', 'Approve', 'Archive'].includes(tab)) {
            return ['registry_admin_user', 'line_operator', 'batch_approver', 'auditor'];
        }
        else if (tab === 'users' || tab === 'create-user') {
            return ['registry_admin_user', 'user_manager'];
        }
        else if (['reports', 'Batch Activity', 'Alert Activity', 'User Activity'].includes(tab)) {
            return ['registry_admin_user', 'auditor'];
        }
        else if (tab === 'create-catalog-entry') {
            return ['registry_admin_user', 'catalog_manager'];
        }
    };
    AuthGuardService.prototype.canActivate = function (route) {
        var _this = this;
        if (route.data.name === 'Lines') { // don't need to be logged in to access Lines
            if (this.authService.hasRole(this.roles(route.data.name))) {
                return true;
            }
            else {
                this.router.navigate(['permission-denied']);
                return false;
            }
        }
        else {
            if (this.authService.isLoggedIn()) {
                if (this.authService.hasRole(this.roles(route.data.name))) {
                    return true;
                }
                else {
                    this.router.navigate(['permission-denied']);
                    return false;
                }
            }
            else {
                this.translate.get(['required_login', 'dismiss'], { page: route.data.title }).subscribe(function (translation) {
                    _this.router.navigate(['login']);
                    _this.toast.open(translation['required_login'], translation['dismiss'], {
                        duration: 3000
                    });
                    return false;
                });
            }
        }
    };
    AuthGuardService.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.inject(i1.AuthService), i0.inject(i2.Router), i0.inject(i3.MatSnackBar), i0.inject(i4.TranslateService)); }, token: AuthGuardService, providedIn: "root" });
    return AuthGuardService;
}());
export { AuthGuardService };
