import { HttpClient, HttpParams } from '@angular/common/http';
import { urls } from '../../../urls';
import { DataStore } from '../datastore/datastore.service';
import { Alert } from 'src/app/models/alert';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../datastore/datastore.service";
var AlertsResourceService = /** @class */ (function () {
    function AlertsResourceService(http, datastore) {
        this.http = http;
        this.datastore = datastore;
        this.ALERTS_URL = urls.API_URL + "/alerts";
    }
    AlertsResourceService.prototype.update = function (id, resolution) {
        return this.http.put(this.ALERTS_URL + "/" + id, { resolution: resolution }).pipe(
        // tap(result => console.log(result))
        );
    };
    AlertsResourceService.prototype.getAlerts = function (dateRange, startDate, endDate, alertType) {
        var parameters = {};
        if (dateRange && startDate) {
            parameters.time_filter_event = dateRange;
        }
        if (startDate) {
            parameters.time_filter_start = startDate;
        }
        if (endDate) {
            parameters.time_filter_end = endDate;
        }
        if (alertType) {
            parameters.type = alertType;
        }
        return this.datastore.findAll(Alert, parameters).pipe(map(function (alertsResult) { return alertsResult.getModels(); }));
    };
    AlertsResourceService.prototype.downloadAlertActivity = function (downloadFilters) {
        var params = new HttpParams();
        if (downloadFilters.timeFrame && downloadFilters.startDate) {
            params = params.append('time_filter_event', downloadFilters.timeFrame);
        }
        if (downloadFilters.startDate) {
            params = params.append('time_filter_start', downloadFilters.startDate);
        }
        if (downloadFilters.endDateTime) {
            params = params.append('time_filter_end', downloadFilters.endDateTime);
        }
        if (downloadFilters.type) {
            params = params.append('type', downloadFilters.type);
        }
        var options = { responseType: 'text', params: params };
        return this.http.get(this.ALERTS_URL + ".csv", options).pipe(
        // tap(result => console.log(result))
        );
    };
    AlertsResourceService.ngInjectableDef = i0.defineInjectable({ factory: function AlertsResourceService_Factory() { return new AlertsResourceService(i0.inject(i1.HttpClient), i0.inject(i2.DataStore)); }, token: AlertsResourceService, providedIn: "root" });
    return AlertsResourceService;
}());
export { AlertsResourceService };
