import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource, MatSort, MatPaginator, MatDatepickerInputEvent } from '@angular/material';
import * as moment from 'moment';
import { downloadCsv } from '../../util/download';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { UserActivityService } from 'src/app/services/user-activity/user-activity.service';

@Component({
  selector: 'reg-user-activity',
  templateUrl: './user-activity.component.html',
  styleUrls: ['./user-activity.component.scss']
})
export class UserActivityComponent implements OnInit {

  activityTypes: string[] = ['login', 'logout', 'password_change', 'user_create', 'user_delete', 'user_update', 'batch_finish', 'batch_approve', 'alert_acknowledge'];
  startDate: string;
  endDate: string;
  email: string;
  emails: string[];
  activityType: string;
  displayedColumns: string[] = ['dateTime', 'activityType', 'email', 'firstName', 'lastName'];
  dataSource: MatTableDataSource<any>;
  activities = [];
  translations: any = {};
  downloadFilters: any = {};
  routeDataSub: Subscription;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private translate: TranslateService,
    private userActivityService: UserActivityService,
    private route: ActivatedRoute
  ) {
    this.getTranslations();
   }

  ngOnInit() {
      this.dataSource = new MatTableDataSource(this.activities);
      this.sort.sort({ id: 'dateTime', start: 'desc', disableClear: false });
      this.dataSource.sort = this.sort;
      this.routeDataSub = this.route.data.subscribe(({users}) => {
        this.emails = users.map(user => user.email);
      });
    }

  getTranslations() {
    this.translate.get([
      'user_activity.login',
      'user_activity.logout',
      'user_activity.password_change',
      'user_activity.user_create',
      'user_activity.user_delete',
      'user_activity.user_update',
      'user_activity.batch_finish',
      'user_activity.batch_approve',
      'user_activity.alert_acknowledge',
    ]).subscribe(translation => {
      this.translations['login'] = translation['user_activity.login'];
      this.translations['logout'] = translation['user_activity.logout'];
      this.translations['password_change'] = translation['user_activity.password_change'];
      this.translations['user_create'] = translation['user_activity.user_create'];
      this.translations['user_delete'] = translation['user_activity.user_delete'];
      this.translations['user_update'] = translation['user_activity.user_update'];
      this.translations['batch_finish'] = translation['user_activity.batch_finish'];
      this.translations['batch_approve'] = translation['user_activity.batch_approve'];
      this.translations['alert_acknowledge'] = translation['user_activity.alert_acknowledge'];
    });
  }

  clearFilters() {
    this.startDate = null;
    this.endDate = null;
    this.email = null;
    this.activityType = null;
  }

  startDateChanged($event: MatDatepickerInputEvent<Date>): void {
    const date = this.startDate ? moment($event.value).format('YYYY-MM-DD') : null;
    this.startDate = date;
  }

  endDateChanged($event: MatDatepickerInputEvent<Date>): void {
    const date = this.endDate ? moment($event.value).format('YYYY-MM-DD') : null;
    this.endDate = date;
  }

  mapActivitiesData(activities) {
    return activities.map((activity) => {
      return {
        dateTime: moment(activity.time).format('YYYY-MM-DD HH:mm:ss'),
        activityType : activity.activityType,
        email: activity.email,
        firstName: activity.firstName,
        lastName: activity.lastName
      };
    });
  }

  filterActivities() {
    let endDateTime;
    if (this.endDate) {
      endDateTime = moment(this.endDate).format('YYYY-MM-DD 23:59:59');
    }

    this.downloadFilters = {
      email: this.email,
      startDate: this.startDate,
      endDate: endDateTime,
      type: this.activityType
    };

    this.userActivityService.getUserActivities(this.startDate, endDateTime, this.email, this.activityType).subscribe((activities) => {
      this.activities = this.mapActivitiesData(activities);
      this.dataSource = new MatTableDataSource(this.activities);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  download() {
    this.userActivityService.downloadUserActivity(this.downloadFilters).subscribe((activities) => {
      downloadCsv(`user_activity.csv`, activities);
    });
  }

  isDisabled() {
    return (this.startDate && !this.endDate) || (!this.startDate && this.endDate) || this.startDate === 'Invalid date' || this.endDate === 'Invalid date';
  }

  onSortData(sort) {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'activityType': {
          return this.translations[item[property]];
        }
        default: {
          return item[property];
        }
      }
    };
  }
}
