import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDatepickerInputEvent, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import * as moment from 'moment';
import { ProductionRunsResourceService } from 'src/app/services/production-runs/production-runs-resource.service';
import { Subscription } from 'rxjs';
import { downloadCsv } from '../../util/download';
@Component({
  selector: 'reg-batch-activity',
  templateUrl: './batch-activity.component.html',
  styleUrls: ['./batch-activity.component.scss']
})

export class BatchActivityComponent implements OnInit {

  batchTypes: string[] = [];
  dateRanges: string[] = [];
  startDate: string;
  endDate: string;
  batchType: string;
  dateRange: string;
  displayedColumns: string[] = ['drug', 'lot', 'expiration', 'unitSaleNdc', 'unitUseNdc', 'processedAt',
   'processedBy', 'approvedAt', 'approvedBy', 'test', 'line', 'totalCartons', 'uniqueValidEpcs', 'uniqueInvalidEpcs', 'uniqueEpcs'];
  dataSource: MatTableDataSource<any>;
  batches: any[] = [];
  downloadFilters: any = {};

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  routeDataSub: Subscription;

  constructor(
    private translate: TranslateService,
    private productionRunsService: ProductionRunsResourceService
  ) {
    this.getTranslations();
   }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.batches);
    this.sort.sort({ id: 'approvedAt', start: 'desc', disableClear: false });
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  getTranslations() {
    this.translate.get([
      'transition_batch.production',
      'transition_batch.test',
      'batch_activity.approved',
      'batch_activity.finished'
    ]).subscribe(translation => {
      this.batchTypes.push(translation['transition_batch.production']);
      this.batchTypes.push(translation['transition_batch.test']);
      this.dateRanges.push(translation['batch_activity.approved']);
      this.dateRanges.push(translation['batch_activity.finished']);
      this.dateRange = translation['batch_activity.approved'];
    });
  }

  clearFilters() {
    this.startDate = null;
    this.endDate = null;
    this.dateRange = this.translate.instant('batch_activity.approved');
    this.batchType = null;
  }

  startDateChanged($event: MatDatepickerInputEvent<Date>): void {
      const date = this.startDate ? moment($event.value).format('YYYY-MM-DD') : null;
      this.startDate = date;
  }

  endDateChanged($event: MatDatepickerInputEvent<Date>): void {
      const date = this.endDate ? moment($event.value).format('YYYY-MM-DD') : null;
      this.endDate = date;
  }

  mapBatchesData(batches: any[]): any[] {
    return batches.map((batch) => {
      const catalogEntry = batch.catalogEntry;
      const accountType = batch.account ? batch.account.accountType : null;
      const accountName = accountType ? accountType.name : null;
      return {
        id: batch.id,
        drug: batch.drugInformation,
        lot: batch.lotNumber,
        expiration: accountName === 'compounder' ? moment(batch.expirationManufacturer).format('DDMMMYYYY').toUpperCase() :
        moment(batch.expirationManufacturer).format('MMMYYYY').toUpperCase(),
        expirationFull: batch.expirationManufacturer,
        line: batch.tunnelName,
        lastScan: moment(batch.lastScan).format('MMMYYYY').toUpperCase(),
        unitUseNdc: catalogEntry.ndcFull,
        unitSaleNdc: batch.unitOfSale.ndcFull,
        processedAt: !!batch.finishedAt ? moment(batch.finishedAt).format('YYYY-MM-DD HH:mm:ss') : '',
        processedBy: batch.finishedBy ? `${batch.finishedBy.firstName} ${batch.finishedBy.lastName}` : null,
        approvedAt: !!batch.approvedAt ? moment(batch.approvedAt).format('YYYY-MM-DD HH:mm:ss') : '',
        approvedBy: batch.approvedBy ? `${batch.approvedBy.firstName} ${batch.approvedBy.lastName}` : null,
        test: batch.formattedBatchType,
        totalCartons: batch.totalCartons,
        uniqueValidEpcs: batch.totalUniqueValidEpcs,
        uniqueInvalidEpcs: batch.totalUniqueInvalidEpcs,
        uniqueEpcs: batch.totalUniqueValidEpcs + batch.totalUniqueInvalidEpcs

      };
    });
  }

  filterBatches() {
    let test;
    if (this.batchType) {
      if (this.batchType === 'Test') {
          test = true;
      } else {
          test = false;
      }
    }

    const timeFrame = this.dateRange === 'Approved' ? 'approve' : 'finish';

    let endDateTime;
    if (this.endDate) {
      endDateTime = moment(this.endDate).format('YYYY-MM-DD 23:59:59');
    }

    this.downloadFilters = {
      timeFrame,
      startDate: this.startDate,
      endDateTime,
      test
    };

    this.productionRunsService.getBatches(timeFrame, this.startDate, endDateTime, test).subscribe((batches) => {
      this.batches = this.mapBatchesData(batches);
      this.dataSource = new MatTableDataSource(this.batches);
      this.dataSource.sort = this.sort;
      if (this.dataSource.sort.active === 'expiration') {
        this.sort.sortChange.emit();
      }
      this.dataSource.paginator = this.paginator;
    });
  }

  download() {
    this.productionRunsService.downloadBatchActivity(this.downloadFilters).subscribe((batches) => {
      downloadCsv(`batch_activity.csv`, batches);
    });
  }

  onSortData(sort) {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'expiration': {
          const date = new Date(item.expirationFull);
          return date;
        }
        default: {
          return item[property];
        }
      }
    };
  }

  isDisabled() {
    return (this.startDate && !this.endDate) || (!this.startDate && this.endDate) || this.startDate === 'Invalid date' || this.endDate === 'Invalid date';
  }
}
