import { Component } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
    selector: 'reg-kiosk-configure',
    templateUrl: './kiosk-configure.component.html',
    styleUrls: ['./kiosk-configure.component.scss']
})

export class KioskConfigureComponent {
    kioskName: string;
    dismiss: string;
    successMessage: string;

    constructor(
        public dialogRef: MatDialogRef<KioskConfigureComponent>,
        public authService: AuthService,
        private toast: MatSnackBar,
        private translate: TranslateService
    ) {
        this.translate.get([
            'kiosk_configure_page.configured_successfully',
            'dismiss'
        ]).subscribe(translation => {
            this.dismiss = translation['dismiss'];
            this.successMessage = translation['kiosk_configure_page.configured_successfully'];
        });
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    configureKiosk(): void {
        if (this.kioskName) {
            this.authService.configureKiosk(this.kioskName).subscribe(() => {
                this.dialogRef.close();

                this.toast.open(this.successMessage, this.dismiss, {
                    duration: 3000,
                    panelClass: 'success-snackbar'
                });
            });
        }
    }
}
