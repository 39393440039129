import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductionRun } from '../../models/production-run';
import { DataStore } from '../../services/datastore/datastore.service';

@Injectable({
  providedIn: 'root'
})
export class FinishedBatchesResolverService implements Resolve<any> {

  constructor(private datastore: DataStore) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.datastore.findAll(ProductionRun, {state: 'finished'},
    ).pipe(
      map(productionRunsResult => productionRunsResult.getModels())
    );
  }
}
