import { Component, OnInit } from '@angular/core';
import { Router, Route } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { timer, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { AuthService } from '../../services/auth/auth.service';
import { DataStore } from 'src/app/services/datastore/datastore.service';
import { Counts } from 'src/app/models/counts';

@Component({
    selector: 'reg-batches',
    templateUrl: './batches.component.html',
    styleUrls: ['./batches.component.scss']
})
export class BatchesComponent implements OnInit {
    navLinks: Route[];
    titleTranslations$: Observable<{[key: string]: string}>;
    counts$: Observable<Counts>;

    constructor(
        private authService: AuthService,
        private router: Router,
        private translate: TranslateService,
        private dataStore: DataStore
    ) { }

    ngOnInit(): void {
        this.counts$ = timer(0, 2000).pipe(
            concatMap(() => this.dataStore.findRecord(Counts, null))
        );

        this.titleTranslations$ = this.translate.get([
            'titles.lines',
            'titles.finish',
            'titles.archive',
            'titles.approve'
        ]);

        this.navLinks = this.router.config.find(route => route.path === 'batches').children;
    }

  showTab(data): boolean {
    return !this.authService.isInKioskMode() || !data.showOnlyWhenLoggedIn;
  }
}
