/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../components/batches-table/batches-table.component.ngfactory";
import * as i2 from "../../components/batches-table/batches-table.component";
import * as i3 from "../../services/transition-batch/transition-batch.service";
import * as i4 from "@angular/material/dialog";
import * as i5 from "@angular/router";
import * as i6 from "../../services/auth/auth.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "@angular/common";
import * as i9 from "./archive.component";
var styles_ArchiveComponent = [];
var RenderType_ArchiveComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ArchiveComponent, data: {} });
export { RenderType_ArchiveComponent as RenderType_ArchiveComponent };
function View_ArchiveComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "reg-batches-table", [["defaultSort", "approvedAt"]], null, null, null, i1.View_BatchesTableComponent_0, i1.RenderType_BatchesTableComponent)), i0.ɵdid(1, 638976, null, 0, i2.BatchesTableComponent, [i3.TransitionBatchService, i4.MatDialog, i5.Router, i6.AuthService], { tableName: [0, "tableName"], displayedColumns: [1, "displayedColumns"], batchReport: [2, "batchReport"], data: [3, "data"], defaultSort: [4, "defaultSort"] }, null), i0.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i0.ChangeDetectorRef]), i0.ɵpad(3, 6)], function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("batches_table.approved")); var currVal_1 = _ck(_v, 3, 0, "drug", "lotNumber", "manufacturerExpiration", "approvedAt", "approvedBy", "test"); var currVal_2 = true; var currVal_3 = _v.context.ngIf; var currVal_4 = "approvedAt"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_ArchiveComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_ArchiveComponent_1)), i0.ɵdid(1, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i8.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.dataSource$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ArchiveComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "reg-archive", [], null, null, null, View_ArchiveComponent_0, RenderType_ArchiveComponent)), i0.ɵdid(1, 114688, null, 0, i9.ArchiveComponent, [i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ArchiveComponentNgFactory = i0.ɵccf("reg-archive", i9.ArchiveComponent, View_ArchiveComponent_Host_0, {}, {}, []);
export { ArchiveComponentNgFactory as ArchiveComponentNgFactory };
