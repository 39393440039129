import { Injectable} from '@angular/core';
import { MatDialog } from '@angular/material';

import { KioskConfigureComponent } from 'src/app/dialogs/kiosk-configure/kiosk-configure.component';

@Injectable({
    providedIn: 'root'
})

export class KioskService {

    constructor(
        private dialog: MatDialog
    ) { }

    public configureKioskModal() {
        this.dialog.open(KioskConfigureComponent, {
            width: '290px'
        });
    }
}
