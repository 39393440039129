import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProductionRunsResourceService } from 'src/app/services/production-runs/production-runs-resource.service';
import { downloadCsv } from '../../util/download';
@Component({
  selector: 'reg-batch-report',
  templateUrl: './batch-report.component.html',
  styleUrls: ['./batch-report.component.scss']
})
export class BatchReportComponent {
  constructor(
      public dialogRef: MatDialogRef<BatchReportComponent>,
      private productionRunsService: ProductionRunsResourceService,
      @Inject(MAT_DIALOG_DATA) public data
  ) { }

  downloadEpcList() {
    this.productionRunsService.downloadEpcs(this.data.batch.id).subscribe((epcs) => {
      downloadCsv(`lot_${this.data.batch.lotNumber}.csv`, epcs);
    });
  }

  printBatchReport() {
    const report = document.querySelector('reg-batch-report');

    const printWindow = window.open('print.html', 'PRINT');
    printWindow.document.write('<html><head><title>' + document.title  + '</title>');
    printWindow.document.write('</head><body >');
    printWindow.document.write(report.innerHTML);
    printWindow.document.write('</body></html>');

    const buttons = printWindow.document.querySelector('#batch-report-buttons');
    buttons.parentNode.removeChild(buttons);

    printWindow.document.close(); // necessary for IE >= 10
    printWindow.focus(); // necessary for IE >= 10*/

    printWindow.print();
    printWindow.close();
  }

  onCancel() {
      this.dialogRef.close();
  }
}
