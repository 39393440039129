import * as tslib_1 from "tslib";
import { PackageDescription } from './package-description';
import { JsonApiModelConfig, JsonApiModel, Attribute, HasMany, BelongsTo } from 'angular2-jsonapi';
var CatalogEntry = /** @class */ (function (_super) {
    tslib_1.__extends(CatalogEntry, _super);
    function CatalogEntry() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(CatalogEntry.prototype, "drugInformation", {
        get: function () {
            return this.itemName + " " + this.itemStrengthScalar + " " + this.itemStrengthUom + " -\n        " + this.packageSizeScalar + " " + this.packageSizeUom + " " + this.packageDescription.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CatalogEntry.prototype, "packageSize", {
        get: function () {
            return this.packageSizeScalar + " " + this.packageSizeUom;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CatalogEntry.prototype, "itemStrength", {
        get: function () {
            return this.itemStrengthScalar + " " + this.itemStrengthUom;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        Attribute({ serializedName: 'account-id' }),
        tslib_1.__metadata("design:type", Number)
    ], CatalogEntry.prototype, "accountId", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'expiring-fridge-days' }),
        tslib_1.__metadata("design:type", Number)
    ], CatalogEntry.prototype, "expiringFridgeDays", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'expiring-multidose-days' }),
        tslib_1.__metadata("design:type", Number)
    ], CatalogEntry.prototype, "expiringMultidoseDays", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'item-name' }),
        tslib_1.__metadata("design:type", String)
    ], CatalogEntry.prototype, "itemName", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'item-strength-scalar' }),
        tslib_1.__metadata("design:type", String)
    ], CatalogEntry.prototype, "itemStrengthScalar", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'item-strength-uom' }),
        tslib_1.__metadata("design:type", String)
    ], CatalogEntry.prototype, "itemStrengthUom", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'manufacturer-name' }),
        tslib_1.__metadata("design:type", String)
    ], CatalogEntry.prototype, "manufacturerName", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'ndc-digits' }),
        tslib_1.__metadata("design:type", String)
    ], CatalogEntry.prototype, "ndcDigits", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'ndc-full' }),
        tslib_1.__metadata("design:type", String)
    ], CatalogEntry.prototype, "ndcFull", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'package-size-scalar' }),
        tslib_1.__metadata("design:type", String)
    ], CatalogEntry.prototype, "packageSizeScalar", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'package-size-uom' }),
        tslib_1.__metadata("design:type", String)
    ], CatalogEntry.prototype, "packageSizeUom", void 0);
    tslib_1.__decorate([
        BelongsTo({ key: 'package-description' }),
        tslib_1.__metadata("design:type", PackageDescription)
    ], CatalogEntry.prototype, "packageDescription", void 0);
    tslib_1.__decorate([
        HasMany({ key: 'unit-of-sales' }),
        tslib_1.__metadata("design:type", Array)
    ], CatalogEntry.prototype, "unitOfSales", void 0);
    CatalogEntry = tslib_1.__decorate([
        JsonApiModelConfig({
            type: 'catalog-entries'
        })
    ], CatalogEntry);
    return CatalogEntry;
}(JsonApiModel));
export { CatalogEntry };
