import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { JsonApiDatastoreConfig, JsonApiDatastore, DatastoreConfig, JsonApiModel } from 'angular2-jsonapi';
import { urls } from '../../../urls';

import { PackageDescription } from '../../models/package-description';
import { CatalogEntry } from '../../models/catalog-entry';
import { UnitOfSale } from '../../models/unit-of-sale';
import { PasswordSettings } from '../../models/password-settings';
import { Tunnel } from 'src/app/models/tunnel';
import { ProductionRun } from 'src/app/models/production-run';
import { Alert } from 'src/app/models/alert';
import { User } from 'src/app/models/user';
import { UserSession } from 'src/app/models/user-session';
import { KioskSession } from 'src/app/models/kiosk-session';
import { Counts } from 'src/app/models/counts';
import { Role } from 'src/app/models/role';
import { Account } from 'src/app/models/account';
import { AccountType } from 'src/app/models/account-type';

const config: DatastoreConfig = {
  baseUrl: urls.API_URL,
  models: {
    'catalog-entries': CatalogEntry,
    'package-descriptions': PackageDescription,
    'unit-of-sales': UnitOfSale,
    'password-settings': PasswordSettings,
    'tunnels': Tunnel,
    'production-runs': ProductionRun,
    'users': User,
    'user-sessions': UserSession,
    'kiosk-sessions': KioskSession,
    'alerts': Alert,
    'counts': Counts,
    'roles': Role,
    'accounts': Account,
    'account-types': AccountType
  }
};

export type ModelType<T extends JsonApiModel> = new(datastore: JsonApiDatastore, data: any) => T;

@Injectable({
    providedIn: 'root'
})
@JsonApiDatastoreConfig(config)
export class DataStore extends JsonApiDatastore {

    constructor(http: HttpClient) {
        super(http);
    }

    deserialize<T extends JsonApiModel>(
        res: HttpResponse<object>,
        modelType: ModelType<T>,
    ): T {
        return this.extractRecordData(res, modelType);
    }
}
