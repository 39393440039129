import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { urls } from '../../../urls';

@Injectable({
    providedIn: 'root'
})

export class VersionService {
    constructor(
        private http: HttpClient,
    ) {}

    getBackEndVersion(): Observable<string> {
        const options: Object = {
            responseType: 'text',
        };

        return this.http.get<string>(`${urls.API_BASE_URL}/version.txt`, options);
    }
}
