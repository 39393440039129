import * as tslib_1 from "tslib";
import { JsonApiModelConfig, JsonApiModel, Attribute } from 'angular2-jsonapi';
var KioskSession = /** @class */ (function (_super) {
    tslib_1.__extends(KioskSession, _super);
    function KioskSession() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(KioskSession.prototype, "navName", {
        get: function () {
            return this.name;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        Attribute({ serializedName: 'authentication-token' }),
        tslib_1.__metadata("design:type", String)
    ], KioskSession.prototype, "authenticationToken", void 0);
    tslib_1.__decorate([
        Attribute(),
        tslib_1.__metadata("design:type", String)
    ], KioskSession.prototype, "name", void 0);
    KioskSession = tslib_1.__decorate([
        JsonApiModelConfig({
            type: 'kiosk-sessions'
        })
    ], KioskSession);
    return KioskSession;
}(JsonApiModel));
export { KioskSession };
