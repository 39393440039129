import * as moment from 'moment-timezone';
import * as i0 from "@angular/core";
var NO_AUTH_URLS = [
    'version.txt',
    'assets',
    'sessions',
    'emails.json',
];
var HttpHeadersInterceptService = /** @class */ (function () {
    function HttpHeadersInterceptService() {
    }
    HttpHeadersInterceptService.prototype.intercept = function (req, next) {
        var authToken = localStorage.getItem('authToken');
        if (req.method === 'DELETE' && req.url.includes('sessions') && localStorage.getItem('lastToken')) {
            authToken = localStorage.getItem('lastToken');
        }
        if (!authToken) {
            var noAuthAllowed = NO_AUTH_URLS.reduce(function (contains, u) {
                return contains || req.url.includes(u);
            }, false);
            if (!noAuthAllowed) {
                throw new Error('No auth token to make this request: ' + req.url);
            }
        }
        var tz = moment.tz.guess();
        return next.handle(req.clone({
            headers: req.headers
                .set('authorization', 'Bearer ' + authToken)
                .set('JSONAPI', 'true')
                .set('timezone', tz),
        }));
    };
    HttpHeadersInterceptService.ngInjectableDef = i0.defineInjectable({ factory: function HttpHeadersInterceptService_Factory() { return new HttpHeadersInterceptService(); }, token: HttpHeadersInterceptService, providedIn: "root" });
    return HttpHeadersInterceptService;
}());
export { HttpHeadersInterceptService };
