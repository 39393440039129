import { OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { AuthService } from 'src/app/services/auth/auth.service';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
var PasswordResetDialogComponent = /** @class */ (function () {
    function PasswordResetDialogComponent(dialogRef, authService, toast, translate) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.authService = authService;
        this.toast = toast;
        this.translate = translate;
        this.dialogContentHeight = 165;
        this.PASSWORD_HINTS = ['containsBlank', 'containsDigit', 'containsLowercase', 'containsUppercase', 'containsSpecial'];
        this.passwordFormControl = new FormControl('');
        this.translate.get([
            'reset_password_page.reset_successfully',
            'dismiss'
        ]).subscribe(function (translation) {
            _this.dismiss = translation['dismiss'];
            _this.successMessage = translation['reset_password_page.reset_successfully'];
        });
    }
    PasswordResetDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authService.getPasswordSettings().pipe(tap(function (passwordSettingsResponse) { return _this.passwordSettings = passwordSettingsResponse; }), tap(function () { return _this.calcDialogHeight(); }), tap(function () { return _this.passwordFormControl = new FormControl('', _this.validatePassword.bind(_this)); })).subscribe();
    };
    PasswordResetDialogComponent.prototype.calcDialogHeight = function () {
        var _this = this;
        this.PASSWORD_HINTS.forEach(function (hint) {
            if (_this.passwordSettings[hint]) {
                _this.dialogContentHeight += 35;
            }
        });
    };
    PasswordResetDialogComponent.prototype.onCancel = function () {
        this.dialogRef.close();
    };
    PasswordResetDialogComponent.prototype.passwordChanged = function (password) {
        if (!password && !this.passwordFormControl.pristine) {
            this.passwordFormControl.reset('');
        }
    };
    PasswordResetDialogComponent.prototype.updatePassword = function (newPassword) {
        var _this = this;
        if (newPassword) {
            this.authService.resetPassword(newPassword).subscribe(function () {
                _this.dialogRef.close();
                _this.toast.open(_this.successMessage, _this.dismiss, {
                    duration: 3000,
                    panelClass: 'success-snackbar',
                });
            });
        }
    };
    PasswordResetDialogComponent.prototype.validatePassword = function (c) {
        var errors = {};
        if (!this.passwordSettings) {
            return errors;
        }
        if (!c.value) {
            errors['reset_password_page.password_required'] = {};
        }
        else {
            if (this.passwordSettings.minLength) {
                if (c.value.length < this.passwordSettings.minLength) {
                    errors['reset_password_page.more_chars'] = { moreChars: this.passwordSettings.minLength - c.value.length };
                }
            }
        }
        if (this.passwordSettings.maxLength) {
            if (c.value.length > this.passwordSettings.maxLength) {
                errors['reset_password_page.less_chars'] = { lessChars: c.value.length - this.passwordSettings.maxLength };
            }
        }
        if (this.passwordSettings.containsBlank) {
            if (!c.value.match(/ /)) {
                errors['reset_password_page.blank_character'] = {};
            }
        }
        if (this.passwordSettings.containsDigit) {
            if (!c.value.match(/[0-9]/)) {
                errors['reset_password_page.one_number'] = {};
            }
        }
        if (this.passwordSettings.containsLowercase) {
            if (!c.value.match(/[a-z]/)) {
                errors['reset_password_page.lowercase'] = {};
            }
        }
        if (this.passwordSettings.containsUppercase) {
            if (!c.value.match(/[A-Z]/)) {
                errors['reset_password_page.uppercase'] = {};
            }
        }
        if (this.passwordSettings.containsSpecial) {
            // all the ascii characters that aren't letters numbers or whitespace
            // 32-47, 58-64, 91-96, 123-126
            if (!c.value.match(/[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]/)) {
                errors['reset_password_page.special_character'] = {};
            }
        }
        return errors;
    };
    Object.defineProperty(PasswordResetDialogComponent.prototype, "newPassword", {
        get: function () {
            return this.passwordFormControl.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PasswordResetDialogComponent.prototype, "errors", {
        get: function () {
            return !!this.passwordFormControl.errors ? Object.keys(this.passwordFormControl.errors) : [];
        },
        enumerable: true,
        configurable: true
    });
    return PasswordResetDialogComponent;
}());
export { PasswordResetDialogComponent };
