<mat-card class="mat-elevation-z4">
    <mat-card-title> {{ 'batches_table.title' | translate: {tableName: tableName} }} </mat-card-title>
      <table mat-table [dataSource]="dataSource" matSort fxFill (matSortChange)="onSortData($event)">
            <ng-container matColumnDef="drug">
                <th [ngClass]="pageName" class="drug-header" mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'batches_table.drug' | translate }} </th>
                <td [ngClass]="pageName" class="drug-row" mat-cell *matCellDef="let row"> {{row.drug}} </td>
            </ng-container>

            <ng-container matColumnDef="lotNumber">
                <th [ngClass]="pageName" mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'batches_table.lot' | translate }} </th>
                <td [ngClass]="pageName" mat-cell *matCellDef="let row"> {{row.lotNumber}} </td>
            </ng-container>

            <ng-container matColumnDef="manufacturerExpiration">
                <th [ngClass]="pageName" mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'batches_table.expiration' | translate }} </th>
                <td [ngClass]="pageName" mat-cell *matCellDef="let row"> {{row.manufacturerExpiration}} </td>
            </ng-container>

            <ng-container matColumnDef="line">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'batches_table.line' | translate }} </th>
                <td mat-cell *matCellDef="let row"> {{row.line}} </td>
            </ng-container>

            <ng-container matColumnDef="lastScan">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'batches_table.last_active' | translate }} </th>
                <td mat-cell *matCellDef="let row"> {{row.lastScan}} </td>
            </ng-container>

            <ng-container matColumnDef="processedAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'batches_table.finished_at' | translate }} </th>
                <td mat-cell *matCellDef="let row"> {{row.processedAt}} </td>
            </ng-container>

            <ng-container matColumnDef="processedBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'batches_table.finished_by' | translate }} </th>
                <td mat-cell *matCellDef="let row"> {{row.processedBy}} </td>
            </ng-container>

            <ng-container matColumnDef="approveButton">
                <th class="button-header" mat-header-cell *matHeaderCellDef> </th>
                <td class="batch-management" mat-cell *matCellDef="let row">
                  <ng-container *ngIf="authService.hasRole('batch_approver')">
                    <button mat-button color="accent" (click)="_transitionBatch.approveBatch(row)"> {{ 'batches_table.approve' | translate }} </button>
                    <button mat-button color="accent" (click)="_transitionBatch.rejectBatch(row)"> {{ 'batches_table.reject' | translate }} </button>
                  </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="finishButton">
                <th class="button-header"  mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let row"><button *ngIf="authService.hasRole('line_operator')" mat-button color="accent" (click)="_transitionBatch.finishBatch(row, 'finish')"> {{ 'batches_table.finish' | translate }} </button></td>
            </ng-container>

            <ng-container matColumnDef="approvedAt">
                <th class="archive" mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'batches_table.date_approved' | translate }} </th>
                <td class="archive" mat-cell *matCellDef="let row"> {{row.approvedAt}} </td>
            </ng-container>

            <ng-container matColumnDef="approvedBy">
                <th class="archive" mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'batches_table.approved_by' | translate }} </th>
                <td class="archive" mat-cell *matCellDef="let row"> {{row.approvedBy}} </td>
            </ng-container>

            <ng-container matColumnDef="test">
                <th class="archive" mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'batches_table.batch_type' | translate }} </th>
                <td class="archive" mat-cell *matCellDef="let row"> {{row.test}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="pageName" (click)="openBatchReport(batchReport, row)"></tr>
      </table>
      <ng-container *ngIf="!dataSource.data.length">
        <div class="no-batches" fxLayout="row" fxLayoutAlign="center center">
          <p *ngIf="pageName !== 'archive'"> {{ 'batches_table.no_batches' | translate: {tableName: tableName.toLowerCase()} }} </p>
          <p *ngIf="pageName === 'archive'"> {{ 'batches_table.nothing_approved_last_30' | translate }} </p>
        </div>
      </ng-container>

      <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons></mat-paginator>
      <div fxLayout="row" fxLayoutAlign="center center">
          <p *ngIf="pageName === 'archive'"> {{ 'batches_table.approved_last_30' | translate }} </p>
      </div>
  </mat-card>
