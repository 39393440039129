import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, EMPTY, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable({
    providedIn: 'root'
})

export class HttpErrorInterceptService implements HttpInterceptor {

    constructor(
        private toast: MatSnackBar,
        private router: Router,
        private translate: TranslateService,
        private authService: AuthService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                let toasted = false;

                if ((error.status >= 200 && error.status < 300) ||
                    !this.authService.requestWasMadeWithCurrentToken(request)) {
                    return of(new HttpResponse(request));
                }

                if (error.status === 401) {
                    // for Password Reset and New User Setting Password pages, query parameter 'page' is passed to ExpiredLink component
                    if (request.url.includes('password-reset-settings')) {
                        this.router.navigate(['expired-link'], { queryParams: { fromPage: request.url.split('page=')[1]} });
                    } else if (!this.authService.isLoginRequest(request)) {

                        // only log out if this request was made with our auth token
                        const authHeader = request.headers.get('authorization').trim();
                        if (!!authHeader && authHeader.indexOf('Bearer') >= 0) {
                            toasted = true;
                            const oldToken = authHeader.substring(authHeader.indexOf(' ')).trim();
                            if (oldToken === localStorage.getItem('authToken')) {
                                // probably expired token due to inactivity we should log out
                                this.authService.logOut();

                                this.translate.get(['logged_out_due_to_inactivity', 'dismiss']).subscribe(translation => {
                                    this.toast.open(translation['logged_out_due_to_inactivity'], translation['dismiss'], {
                                        duration: 3000
                                    });
                                });
                            }
                        }
                    }
                }

                if (!toasted) {
                    if (error.status && error.error && error.error.errors[0].detail) {
                        // The backend returned an unsuccessful response code.
                        // The response body may contain clues as to what went wrong,
                        this.translate.get(['status_error', 'dismiss'], {status: error.status, error: error.error.errors[0].detail}).subscribe(translation => {
                            this.toast.open(translation['status_error'], translation['dismiss'], {
                                duration: 3000
                            });
                        });
                    } else {
                        this.translate.get(['unknown_error', 'dismiss']).subscribe(translation => {
                            this.toast.open(translation['unknown_error'], translation['dismiss'], {
                                duration: 3000
                            });
                        });
                    }
                }

                if (error.status === 403) {
                    this.router.navigate(['permission-denied']);
                }

                // If you want to return a new response:
                // return of(new HttpResponse({body: [{name: "Default value..."}]}));

                // If you want to return the error on the upper level:
                // return throwError(error);

                // or just return nothing:
                return EMPTY;
            })
        );
    }
}
