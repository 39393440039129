import * as tslib_1 from "tslib";
import { JsonApiModelConfig, JsonApiModel, Attribute, BelongsTo } from 'angular2-jsonapi';
import { AccountType } from './account-type';
var Account = /** @class */ (function (_super) {
    tslib_1.__extends(Account, _super);
    function Account() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        Attribute({ serializedName: 'name' }),
        tslib_1.__metadata("design:type", String)
    ], Account.prototype, "name", void 0);
    tslib_1.__decorate([
        BelongsTo({ key: 'account-type' }),
        tslib_1.__metadata("design:type", AccountType)
    ], Account.prototype, "accountType", void 0);
    Account = tslib_1.__decorate([
        JsonApiModelConfig({
            type: 'accounts'
        })
    ], Account);
    return Account;
}(JsonApiModel));
export { Account };
