/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./batches.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/badge";
import * as i3 from "@angular/common";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/router";
import * as i6 from "@angular/material/tabs";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/material/core";
import * as i9 from "../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i10 from "@angular/cdk/bidi";
import * as i11 from "@angular/cdk/scrolling";
import * as i12 from "./batches.component";
import * as i13 from "../../services/auth/auth.service";
import * as i14 from "@ngx-translate/core";
import * as i15 from "../../services/datastore/datastore.service";
var styles_BatchesComponent = [i0.styles];
var RenderType_BatchesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BatchesComponent, data: {} });
export { RenderType_BatchesComponent as RenderType_BatchesComponent };
function View_BatchesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "mat-badge"], ["data-e2e", "line.tab"], ["matBadgeColor", "accent"], ["matBadgeOverlap", "false"]], [[2, "mat-badge-overlap", null], [2, "mat-badge-above", null], [2, "mat-badge-below", null], [2, "mat-badge-before", null], [2, "mat-badge-after", null], [2, "mat-badge-small", null], [2, "mat-badge-medium", null], [2, "mat-badge-large", null], [2, "mat-badge-hidden", null], [2, "mat-badge-disabled", null]], null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.MatBadge, [[2, i3.DOCUMENT], i1.NgZone, i1.ElementRef, i4.AriaDescriber, i1.Renderer2], { color: [0, "color"], overlap: [1, "overlap"], content: [2, "content"] }, null), (_l()(), i1.ɵted(2, null, [" ", " "]))], function (_ck, _v) { var currVal_10 = "accent"; var currVal_11 = "false"; var currVal_12 = ((_v.parent.parent.parent.context.ngIf[_v.parent.parent.context.$implicit.data.countAttribute] > 0) ? _v.parent.parent.parent.context.ngIf[_v.parent.parent.context.$implicit.data.countAttribute] : null); _ck(_v, 1, 0, currVal_10, currVal_11, currVal_12); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).overlap; var currVal_1 = i1.ɵnov(_v, 1).isAbove(); var currVal_2 = !i1.ɵnov(_v, 1).isAbove(); var currVal_3 = !i1.ɵnov(_v, 1).isAfter(); var currVal_4 = i1.ɵnov(_v, 1).isAfter(); var currVal_5 = (i1.ɵnov(_v, 1).size === "small"); var currVal_6 = (i1.ɵnov(_v, 1).size === "medium"); var currVal_7 = (i1.ɵnov(_v, 1).size === "large"); var currVal_8 = (i1.ɵnov(_v, 1).hidden || !i1.ɵnov(_v, 1)._hasContent); var currVal_9 = i1.ɵnov(_v, 1).disabled; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_13 = _v.context.ngIf[("titles." + _v.parent.parent.context.$implicit.data.name.toLowerCase())]; _ck(_v, 2, 0, currVal_13); }); }
function View_BatchesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "a", [["class", "mat-tab-link"], ["mat-tab-link", ""], ["routerLinkActive", ""]], [[1, "target", 0], [8, "href", 4], [1, "aria-current", 0], [1, "aria-disabled", 0], [1, "tabIndex", 0], [2, "mat-tab-disabled", null], [2, "mat-tab-label-active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, [[3, 4]], 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(2, 1720320, [["rla", 4]], 2, i5.RouterLinkActive, [i5.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 2, { links: 1 }), i1.ɵqud(603979776, 3, { linksWithHrefs: 1 }), i1.ɵdid(5, 147456, [[1, 4]], 0, i6.MatTabLink, [i6.MatTabNav, i1.ElementRef, i1.NgZone, i7.Platform, [2, i8.MAT_RIPPLE_GLOBAL_OPTIONS], [8, null], i4.FocusMonitor], { active: [0, "active"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_BatchesComponent_4)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_7 = _v.parent.context.$implicit.path; _ck(_v, 1, 0, currVal_7); var currVal_8 = ""; _ck(_v, 2, 0, currVal_8); var currVal_9 = i1.ɵnov(_v, 2).isActive; _ck(_v, 5, 0, currVal_9); var currVal_10 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.titleTranslations$)); _ck(_v, 7, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; var currVal_2 = i1.ɵnov(_v, 5).active; var currVal_3 = i1.ɵnov(_v, 5).disabled.toString(); var currVal_4 = i1.ɵnov(_v, 5).tabIndex; var currVal_5 = i1.ɵnov(_v, 5).disabled; var currVal_6 = i1.ɵnov(_v, 5).active; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_BatchesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BatchesComponent_3)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.data && _co.showTab(_v.context.$implicit.data)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_BatchesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "nav", [["class", "mat-tab-nav-bar"], ["mat-tab-nav-bar", ""]], null, null, null, i9.View_MatTabNav_0, i9.RenderType_MatTabNav)), i1.ɵdid(1, 3325952, null, 1, i6.MatTabNav, [i1.ElementRef, [2, i10.Directionality], i1.NgZone, i1.ChangeDetectorRef, i11.ViewportRuler], null, null), i1.ɵqud(603979776, 1, { _tabLinks: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_BatchesComponent_2)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navLinks; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_BatchesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_BatchesComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.counts$)); _ck(_v, 1, 0, currVal_0); _ck(_v, 4, 0); }, null); }
export function View_BatchesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "reg-batches", [], null, null, null, View_BatchesComponent_0, RenderType_BatchesComponent)), i1.ɵdid(1, 114688, null, 0, i12.BatchesComponent, [i13.AuthService, i5.Router, i14.TranslateService, i15.DataStore], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BatchesComponentNgFactory = i1.ɵccf("reg-batches", i12.BatchesComponent, View_BatchesComponent_Host_0, {}, {}, []);
export { BatchesComponentNgFactory as BatchesComponentNgFactory };
