import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { EmailService } from 'src/app/services/email/email.service';

@Component({
  selector: 'reg-line-alert',
  templateUrl: './line-alert.component.html',
  styleUrls: ['./line-alert.component.scss']
})
export class LineAlertComponent {
  constructor(
      public dialogRef: MatDialogRef<LineAlertComponent>,
      /* tslint:disable:no-unused-variable */
      public emailService: EmailService,
      @Inject(MAT_DIALOG_DATA) public data
  ) { }

  onCancel() {
      this.dialogRef.close();
  }
}
