import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
 import { MatDialog } from '@angular/material';
import { tap, shareReplay, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { urls } from '../../../urls';
import { DataStore } from '../datastore/datastore.service';

import { UserSession } from '../../models/user-session';
import { KioskSession } from '../../models/kiosk-session';
import { PasswordSettings } from '../../models/password-settings';

interface TimeoutResponse {
    timeToTimeout: number;
}

@Injectable({
    providedIn: 'root'
})

export class AuthService {
    KIOSK_URL = `${urls.API_URL}/kiosk-sessions`;
    PASSWORD_URL = `${urls.API_URL}/users/password`;
    SESSION_URL = `${urls.API_URL}/sessions`;
    SESSION_ACTIVITY_URL = `${urls.API_URL}/sessions/activity`;
    SESSION_ACCOUNT_URL = `${urls.API_URL}/sessions/account`;

    constructor(
        private http: HttpClient,
        private dataStore: DataStore,
        private router: Router,
        private location: Location,
        private dialog: MatDialog
        ) {}

    public resetPassword(password: string): Observable<null> {
        return this.http.put<null>(this.PASSWORD_URL, { password }).pipe(
            // tap(pwResetResponse => console.log(pwResetResponse)),
            shareReplay()
        );
    }

    public getPasswordSettings(): Observable<PasswordSettings> {
        return this.dataStore.findRecord(PasswordSettings, null).pipe(
            // tap(pwSettingsResult => console.log(pwSettingsResult)),
        );
    }

    public login(email: string, password: string): Observable<UserSession> {
        return this.http.post<any>(this.SESSION_URL, { email, password }, {observe: 'response'}).pipe(
            map((res) => this.dataStore.deserialize<UserSession>(res, UserSession)),
            tap(this.setSession),
            shareReplay()
        );
    }

    public notifyInactivity(secondsSinceActivity: number): Observable<TimeoutResponse> {
        if (!this.isInKioskMode()) {
            return this.http.post<TimeoutResponse>(this.SESSION_ACTIVITY_URL, { secondsSinceActivity }).pipe(
                // tap(notifyInactivityResult => console.log(notifyInactivityResult)),
                shareReplay()
            );
        }
    }

    public forgotPassword(email: string): Observable<any> {
        return this.http.post<any>(`${this.SESSION_URL}/forgot-password`, { email }).pipe(
            shareReplay()
        );
    }

    public resetPasswordWithToken(password: string, resetToken: string): Observable<UserSession> {
        return this.http.post<any>(
            `${this.SESSION_URL}/reset-password`,
            { password, resetToken }, {observe: 'response'}
        ).pipe(
            map((res) => this.dataStore.deserialize<UserSession>(res, UserSession)),
            tap(this.setSession),
            shareReplay()
        );
    }

    public getPasswordSettingsWithToken(resetToken: string, page: string): Observable<PasswordSettings> {
        return this.http.get<any>(
            `${this.SESSION_URL}/password-reset-settings?reset_token=${resetToken}&page=${page}`,
            {observe: 'response'}
        ).pipe(
            map(res => this.dataStore.deserialize<PasswordSettings>(res, PasswordSettings)),
            shareReplay()
        );
    }

    public setSession(authResult: UserSession): void {
        localStorage.removeItem('kioskMode');
        localStorage.setItem('userId', authResult.id.toString()); // used to know which user is me
        if (authResult.userType === 'registry_admin_user') {
            localStorage.setItem('roles', JSON.stringify(['registry_admin_user'])); // roles available
        } else {
            localStorage.setItem('roles', JSON.stringify(authResult.roles.map(r => r.name))); // roles available
        }
        localStorage.setItem('navName', authResult.navName); // used to display email in nav bar
        localStorage.setItem('authToken', authResult.authenticationToken); // token for authentication
    }

    public setKioskSession(authResult: KioskSession): void {
        localStorage.setItem('kioskName', authResult.navName);
        localStorage.setItem('kioskAuthToken', authResult.authenticationToken);
    }

    public destroySession(): Observable<null> {
        localStorage.setItem('lastToken', localStorage.getItem('authToken'));

        this.logOut();

        return this.http.delete<null>(this.SESSION_URL).pipe(
            tap(() => localStorage.removeItem('lastToken')),
            shareReplay()
        );
    }

    public logOut(): void {
        localStorage.removeItem('authToken');
        localStorage.removeItem('roles');
        localStorage.removeItem('userId');
        localStorage.removeItem('navName');

        // enter kiosk mode if configured
        if (this.kioskIsConfigured()) {
            localStorage.setItem('navName', localStorage.getItem('kioskName'));
            localStorage.setItem('authToken', localStorage.getItem('kioskAuthToken'));
            localStorage.setItem('kioskMode', 'true');

            if (this.location.path() !== '/batches/lines') {
                this.router.navigate(['batches/lines']);
            }
        } else {
            localStorage.removeItem('kioskMode');
            this.router.navigate(['login']);
        }

        // close modal if open
        this.dialog.closeAll();
    }

    public postLoginRouting(authResult: UserSession) {
        if (authResult.userType === 'registry_admin_user') {
            this.router.navigate(['accounts']);
            return;
        }

        const roleRoutes = {
            line_operator: 'batches',
            batch_approver: 'batches/approve',
            catalog_manager: 'catalog',
            auditor: 'reports',
            user_manager: 'users',
        };

        for (const role in roleRoutes) {
            if (this.hasRole(role)) {
                this.router.navigate([roleRoutes[role]]);
                return;
            }
        }

        // should never reach this but just in case
        this.router.navigate(['catalog']);
    }

    public isLoggedIn(): boolean {
        return !!localStorage.getItem('authToken');
    }

    public isLoggedOut(): boolean {
        return !this.isLoggedIn();
    }

    public configureKiosk(name: string): Observable<KioskSession> {
        return this.http.post<KioskSession>(this.KIOSK_URL, { name }, {observe: 'response'}).pipe(
            map((res) => this.dataStore.deserialize<KioskSession>(res, KioskSession)),
            tap(this.setKioskSession),
            shareReplay()
        );
    }

    public isInKioskMode(): boolean {
        return !!localStorage.getItem('kioskMode');
    }

    public kioskIsConfigured(): boolean {
        return !!localStorage.getItem('kioskAuthToken');
    }

    public getRoles(): string[] {
        if (this.isInKioskMode()) {
            return ['kiosk'];
        } else {
            return JSON.parse(localStorage.getItem('roles') || '[]');
        }
    }

    public hasRole(roles: string | string[]): boolean {
        if (typeof roles === 'string') {
            return this.getRoles().includes(roles);
        } else {
            return roles.some(role => this.getRoles().includes(role));
        }
    }

    public isLoginRequest(request: HttpRequest<any>): boolean {
        return request.method === 'POST' && request.url.endsWith('/sessions');
    }

    public requestWasMadeWithCurrentToken(request: HttpRequest<any>): boolean {
        return request.headers.get('authorization') === 'Bearer ' + localStorage.getItem('authToken');
    }

    public setCurrentAccount(accountId: string): Observable<UserSession> {
        return this.http.post<any>(`${this.SESSION_ACCOUNT_URL}/${accountId}`, {}, {observe: 'response'}).pipe(
            map((res) => this.dataStore.deserialize<UserSession>(res, UserSession)),
            tap(this.setSession),
            tap((authResult) => {
                localStorage.setItem('navName', `${authResult.navName} / ${authResult.accountName}`);
            }),
            shareReplay()
        );
    }
}
