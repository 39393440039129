import { NgModule } from '@angular/core';
import { MatButtonModule, MatCheckboxModule, MatInputModule, MatCardModule, MatIconModule, MatSnackBarModule, MatDialogModule, MatTableModule,
    MatPaginatorModule, MatSortModule, MatProgressSpinnerModule, MatMenuModule, MatTooltipModule, MatSelectModule,
    MatPaginatorIntl, MatTabsModule, MatBadgeModule, MatStepperModule,  MatDividerModule, MatDatepickerModule, MatFormFieldModule, MatListModule } from '@angular/material';
import { CustomPaginatorIntlComponent } from './util/custom-paginator-intl';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

const MATERIAL_COMPONENT_MODULES = [ MatButtonModule, MatCheckboxModule, MatInputModule, MatCardModule, MatIconModule, MatSnackBarModule, MatDialogModule, MatTableModule,
    MatPaginatorModule, MatSortModule, MatProgressSpinnerModule, MatMenuModule, MatTooltipModule, MatSelectModule, MatTabsModule, MatBadgeModule, MatStepperModule,
    MatDividerModule, MatDatepickerModule, MatFormFieldModule, MatMomentDateModule, MatListModule ];

@NgModule({
    imports: MATERIAL_COMPONENT_MODULES,
    exports: MATERIAL_COMPONENT_MODULES,
    providers: [
        { provide: MatPaginatorIntl, useClass: CustomPaginatorIntlComponent }
    ],
    declarations: [
        CustomPaginatorIntlComponent,
    ],
})

export class AppMaterialModule { }
