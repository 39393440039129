import { Component } from '@angular/core';

@Component({
  selector: 'reg-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent {

  constructor() { }
}
