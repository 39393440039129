import { Component } from '@angular/core';

@Component({
  selector: 'reg-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {

  constructor() { }
}
