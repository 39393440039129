import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'reg-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent {

  constructor(
    private router: Router
  ) { }

    navigateTo(report) {
      this.router.navigate(report);
    }
}
