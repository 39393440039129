import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataStore } from '../../services/datastore/datastore.service';
import { CatalogEntry } from '../../models/catalog-entry';

@Injectable({
  providedIn: 'root'
})

export class CatalogIndexResolverService implements Resolve<CatalogEntry[]> {

    constructor(private datastore: DataStore) {}

    resolve(
        _route: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot
    ): Observable<CatalogEntry[]> {
        return this.datastore.findAll(CatalogEntry).pipe(
            map(catalogEntriesResult => catalogEntriesResult.getModels()),
        );
    }
}
