import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { UpdateUserComponent } from 'src/app/dialogs/update-user/update-user.component';
import { Subscription } from 'rxjs';
import { Role } from 'src/app/models/role';

@Component({
  selector: 'reg-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  displayedColumns: string[] = ['email', 'firstName', 'lastName', 'roles'];
  dataSource: MatTableDataSource<any>;
  routeDataSub: Subscription;
  roles: Role[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.routeDataSub = this.route.data.subscribe(({users, roles}) => {
      this.roles = roles;
      users.forEach((user) => {
        user.displayRoles = user.roles.map(role => role.displayName).join(', ');
        user.roleCheckBoxes = roles.reduce((checkBoxes, r) => {
          checkBoxes[r.name] = false;
          return checkBoxes;
        }, {});

        user.roles.forEach(role => user.roleCheckBoxes[role.name] = true);
      });

      this.dataSource = new MatTableDataSource(users);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  createUser() {
    this.router.navigate(['users/create-user']);
  }

  canManageUsers(): boolean {
    return this.authService.hasRole('user_manager');
  }

  updateUser(user) {
    if (!this.canManageUsers()) {
      return;
    }

    const data = { user, roles: this.roles };
    this.dialog.open(UpdateUserComponent, {
        width: '1230px',
        data,
    });
  }
}
