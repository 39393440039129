<div *ngFor="let tunnel of tunnelsData$ | async">
    <mat-card class="mat-elevation-z4"
        [ngClass]="{'tunnel-alert-background': tunnel.alert, 'no-active':(!tunnel.activeProductionRun && !tunnel.alert)}"
        [fxLayout]="tunnel.alert || !tunnel.activeProductionRun? 'row' : 'column'"
        [fxLayoutAlign]="tunnel.alert || !tunnel.activeProductionRun? 'space-between center': 'space-around stretch'">

        <!-- tunnel name & version -->
        <div *ngIf="tunnel.alert || !tunnel.activeProductionRun then card_title else card_title_with_drug"></div>
        <ng-template #card_title>
            <div fxFlex="10"
                class="tunnel-name">
                <span><strong>{{tunnel.name}}</strong></span><br>
                <span class="tunnel-version">{{!!tunnel.softwareVersion ? 'v' + tunnel.softwareVersion : ""}}</span>
            </div>
        </ng-template>
        <ng-template #card_title_with_drug>
            <div fxLayout="row"
                [fxLayoutAlign]="tunnel.alert || !tunnel.activeProductionRun? 'space-between': 'start'"
                fxFlexAlign="stretch"
                class="tunnel-name">
                <div fxFlex="10">
                  <span><strong>{{tunnel.name}}</strong></span><br>
                  <span class="tunnel-version">{{!!tunnel.softwareVersion ? 'v' + tunnel.softwareVersion : ""}}</span>
                </div>
                <span fxFlexOffset="3" fxFlex="70"><b>{{tunnel.drug}}</b></span>
            </div>
        </ng-template>

        <!-- tunnel alert -->
        <div *ngIf="tunnel.alert; then alert else no_alert"></div>
        <ng-template #alert>
            <span class="alert-text tunnel-alert-text"
                  fxFlex="90">{{alertMessage(tunnel.alert)}}</span>

            <button *ngIf="canResolveAlerts()"
                  mat-stroked-button
                  data-e2e="acknowledge.alert"
                  color="accent"
                  class="accept-error"
                  fxFlex="10"
                  fxFlexAlign="stretch"
                  (click)="acceptError(tunnel, alertMessage(tunnel.alert), tunnel.alerts[0].id)"
                  [innerHTML]="'lines.acknowledge_error' | translate"></button>

            <!-- padding to prevent the text from moving when the button goes away -->
            <div fxFlex="10" *ngIf="!canResolveAlerts()"></div>
        </ng-template>

        <!-- tunnel batch -->
        <ng-template #no_alert>
            <!-- no active batch -->
            <span fxFlex="55" class="no-active-text" *ngIf="!tunnel.activeProductionRun">
                {{ 'lines.no_active' | translate }} </span>

            <!-- active batch -->
            <div *ngIf="tunnel.activeProductionRun"
                          style="margin-bottom: 40px;"
                          fxLayout="row"
                          fxLayoutAlign="space-between">

                <table fxFlexOffset="13" fxFlex="70" mat-table [dataSource]="tunnel.dataSource">
                    <ng-container matColumnDef="lot">
                        <th mat-header-cell *matHeaderCellDef> {{ 'lines.lot' | translate }}: </th>
                        <td mat-cell *matCellDef="let row"> {{row.lot}} </td>
                    </ng-container>

                    <ng-container matColumnDef="expiration">
                        <th mat-header-cell *matHeaderCellDef> {{ 'lines.expiration_date' | translate }}: </th>
                        <td mat-cell *matCellDef="let row"> {{row.expiration}} </td>
                    </ng-container>

                    <ng-container matColumnDef="totalCartons">
                        <th mat-header-cell *matHeaderCellDef> {{ 'lines.total_cartons' | translate }}: </th>
                        <td mat-cell *matCellDef="let row"> {{row.totalCartons}} </td>
                    </ng-container>

                    <ng-container matColumnDef="uniqueValidEpcs">
                        <th mat-header-cell *matHeaderCellDef> {{ 'lines.unique_valid_epcs' | translate }}: </th>
                        <td mat-cell *matCellDef="let row"> {{row.uniqueValidEpcs}} </td>
                    </ng-container>

                    <ng-container matColumnDef="uniqueInvalidEpcs">
                        <th mat-header-cell *matHeaderCellDef> {{ 'lines.unique_invalid_epcs' | translate }}: </th>
                        <td mat-cell *matCellDef="let row"> {{row.uniqueInvalidEpcs}} </td>
                    </ng-container>

                    <ng-container matColumnDef="totalEpcs">
                        <th mat-header-cell *matHeaderCellDef> {{ 'lines.total_unique_epcs' | translate }}: </th>
                        <td mat-cell *matCellDef="let row"> {{row.uniqueEpcs}} </td>
                    </ng-container>

                    <ng-container matColumnDef="ndc">
                        <th mat-header-cell *matHeaderCellDef> {{ 'lines.ndc' | translate }}: </th>
                        <td mat-cell *matCellDef="let row"> {{row.ndc}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <!-- finish button -->
                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
                    <button mat-flat-button
                            color="accent"
                            class="finish-batch line-button"
                            *ngIf="canFinishBatches()"
                            (click)="_transitionBatch.finishBatch(tunnel.batchInfo, 'lines')"
                            [innerHTML]="'lines.finish_batch' | translate"></button>
                </div>
            </div>
        </ng-template>
    </mat-card>
</div>
