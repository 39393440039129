import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { urls } from 'src/urls';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  USERS_URL = `${urls.API_URL}/users`;

  constructor(
    private http: HttpClient
  ) { }

  public deleteUser(id): Observable<any> {
    return this.http.delete<any>(`${this.USERS_URL}/${id}`).pipe(
    );
  }

  public resendInvite(id): Observable<any> {
    return this.http.put<any>(`${this.USERS_URL}/${id}/resend-invite`, {}).pipe(
    );
  }

  public updateUser(id, user): Observable<any> {
    return this.http.put<any>(`${this.USERS_URL}/${id}`, user).pipe(
    );
  }

  public updateUserRoles(id, roles): Observable<any> {
    return this.http.put<any>(`${this.USERS_URL}/${id}/roles`, { roles }).pipe(
    );
  }

  public createUser(user): Observable<any> {
    return this.http.post<any>(this.USERS_URL, user ).pipe(
    );
  }
}
