import { JsonApiModelConfig, JsonApiModel, Attribute } from 'angular2-jsonapi';

@JsonApiModelConfig({
    type: 'production-runs/counts'
})
export class Counts extends JsonApiModel {
    @Attribute()
    finished: number;

    @Attribute({ serializedName: 'started'})
    _started: number;

    // we only want to show the number of started batches that are not active
    get started(): number {
      return this._started - this.active;
    }

    @Attribute()
    active: number;
}
