<main fxFill fxLayout="column" fxLayoutAlign="space-between" [ngClass]="{ 'on-login': isOnLoginPages() || !authService.isLoggedIn(), 'page-loading': pageLoading }">
    <section>

        <!-- hacky way to tell cypress we are, or are not in kiosk mode -->
        <div [class]="'kiosk-' + authService.isInKioskMode()"></div>

        <div *ngIf="versionOutdated" fxLayout="row" fxLayout="center center" class="outdated-version">
            <div fxLayout="row" fxLayoutAlign="center center" fxFlex="80">
                <span> {{ 'newer_version' | translate }}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" fxFlex="20">
                <button mat-raised-button (click)="reloadPage()">
                    <mat-icon>refresh</mat-icon>
                    {{ 'refresh' | translate }}
                </button>
            </div>
        </div>

        <header fxLayout="column" fxLayoutAlign="center center" class="mat-elevation-z2" [fxShow]="isOnLoginPages() || !authService.isLoggedIn()">
            <img class="logo" width="350" height="206" alt="Registry Service" src="/assets/logo-bluesight-color-vert.svg" />
        </header>
        <header fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="20px" class="mat-elevation-z2" [fxHide]="isOnLoginPages() || !authService.isLoggedIn()">
            <div fxLayout="column" fxLayoutAlign="start end">
                <img class="logo" width="225" height="52" alt="Registry Service" src="/assets/logo-bluesight-color-horiz.svg" />
            </div>
            <span *ngIf="!isOnLoginPages() && (authService.isInKioskMode() || authService.isLoggedIn())" fxFlex fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
                <ng-container *ngFor="let menuItem of menuItems">
                    <a
                        *ngIf="authService.hasRole(roles(menuItem.data.name))"
                        [routerLink]="menuItem.path"
                        [ngClass]="{ 'current-page': (menuItem.data.name === parentPageName || menuItem.data.name === currentPage) }"
                        data-e2e="header.menus">
                        {{ titleTranslation[menuItem.data.name] }}
                    </a>
                </ng-container>
            </span>
            <button *ngIf="!authService.isLoggedIn()" mat-button (click)="goToLoginPage()"> {{ 'titles.login' | translate }} </button>
            <span *ngIf="authService.isLoggedIn()" class="email-span">{{navName()}}
                <button data-e2e="open.right.panel" mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </span>

            <mat-menu #menu="matMenu" overlapTrigger="false">

                <div *ngIf="!authService.isInKioskMode(); then logged_in_menu else kiosk_menu"></div>

                <ng-template #logged_in_menu>
                    <button mat-menu-item (click)="logoutUser()">
                        <mat-icon>exit_to_app</mat-icon>
                        <span> {{ 'logout' | translate }} </span>
                    </button>

                    <button mat-menu-item (click)="passwordResetService.resetPasswordModal()">
                        <mat-icon>lock_open</mat-icon>
                        <span> {{ 'reset_password' | translate }} </span>
                    </button>

                    <button mat-menu-item *ngIf="isRegistryAdmin()" (click)="goToSelectAccountPage()">
                        <mat-icon>code</mat-icon>
                        <span> {{ 'switch_account' | translate }} </span>
                    </button>

                    <button *ngIf="!isRegistryAdmin() && !authService.kioskIsConfigured()" mat-menu-item (click)="kioskService.configureKioskModal()">
                        <mat-icon>computer</mat-icon>
                        <span> {{ 'configure_kiosk' | translate }} </span>
                    </button>
                </ng-template>

                <ng-template #kiosk_menu>
                    <button mat-menu-item (click)="goToLoginPage()">
                        <mat-icon>exit_to_app</mat-icon>
                        <span> {{ 'login' | translate }} </span>
                    </button>
                </ng-template>
            </mat-menu>

        </header>

        <mat-spinner color="accent" *ngIf="pageLoading"></mat-spinner>

        <div id="page" [fxHide]="pageLoading">
            <router-outlet></router-outlet>
        </div>

    </section>

</main>
<footer>
    {{ 'cloud_version' | translate: {version: version} }} <br>
    {{ 'copyright' | translate: {year: date.getFullYear()} }} <br>
    {{ '24/7' | translate: {email: emailService.getSupportEmail()} }} <br>
    {{ 'all_rights_reserved' | translate }} <a href="https://bluesight.com/patents/" target="_blank">https://bluesight.com/patents/</a>
</footer>
