import { MatDialog, MatSnackBar } from '@angular/material';
import { ProductionRunsResourceService } from '../production-runs/production-runs-resource.service';
import { TransitionBatchComponent } from 'src/app/dialogs/transition-batch/transition-batch.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { RejectBatchDialogComponent } from 'src/app/dialogs/reject-batch/reject-batch.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../production-runs/production-runs-resource.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/router";
var TransitionBatchService = /** @class */ (function () {
    function TransitionBatchService(toast, dialog, productionRunsService, translate, router) {
        var _this = this;
        this.toast = toast;
        this.dialog = dialog;
        this.productionRunsService = productionRunsService;
        this.translate = translate;
        this.router = router;
        this.translate.get([
            'dismiss',
            'transition_batch.successfully_finished',
            'transition_batch.successfully_approved',
            'transition_batch.successfully_rejected'
        ]).subscribe(function (translation) {
            _this.dismiss = translation['dismiss'];
            _this.finishedMessage = translation['transition_batch.successfully_finished'];
            _this.approvedMessage = translation['transition_batch.successfully_approved'];
            _this.rejectMessage = translation['transition_batch.successfully_rejected'];
        });
    }
    TransitionBatchService.prototype.finishBatch = function (batch, page) {
        var _this = this;
        var finishDialog = this.dialog.open(TransitionBatchComponent, {
            width: '800px',
            data: {
                action: 'finish',
                batch: batch
            }
        });
        finishDialog.afterClosed().subscribe(function (result) {
            if (result) {
                _this.productionRunsService.finish(result.id, result.test).subscribe(function () {
                    if (page === 'lines') {
                        _this.router.navigate(['batches/lines']);
                    }
                    else {
                        _this.router.navigate(['batches/finish']);
                    }
                    _this.toast.open(_this.finishedMessage, _this.dismiss, {
                        duration: 8000,
                        panelClass: 'success-snackbar'
                    });
                });
            }
        });
    };
    TransitionBatchService.prototype.rejectBatch = function (batch) {
        var _this = this;
        var rejectDialog = this.dialog.open(RejectBatchDialogComponent, {
            width: '800px',
        });
        rejectDialog.afterClosed().subscribe(function (result) {
            if (!result) {
                return;
            }
            _this.productionRunsService.reject(batch.id).subscribe(function () {
                _this.router.navigate(['batches/approve']);
                _this.toast.open(_this.rejectMessage, _this.dismiss, {
                    duration: 8000,
                    panelClass: 'success-snackbar'
                });
            });
        });
    };
    TransitionBatchService.prototype.approveBatch = function (batch) {
        var _this = this;
        var approveDialog = this.dialog.open(TransitionBatchComponent, {
            width: '800px',
            data: {
                action: 'approve',
                batch: batch
            }
        });
        approveDialog.afterClosed().subscribe(function (result) {
            if (result) {
                _this.productionRunsService.approve(result.id).subscribe(function () {
                    _this.router.navigate(['batches/approve']);
                    _this.toast.open(_this.approvedMessage, _this.dismiss, {
                        duration: 8000,
                        panelClass: 'success-snackbar'
                    });
                });
            }
        });
    };
    TransitionBatchService.ngInjectableDef = i0.defineInjectable({ factory: function TransitionBatchService_Factory() { return new TransitionBatchService(i0.inject(i1.MatSnackBar), i0.inject(i2.MatDialog), i0.inject(i3.ProductionRunsResourceService), i0.inject(i4.TranslateService), i0.inject(i5.Router)); }, token: TransitionBatchService, providedIn: "root" });
    return TransitionBatchService;
}());
export { TransitionBatchService };
