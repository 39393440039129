<nav mat-tab-nav-bar *ngIf="counts$ | async as counts">
    <ng-container *ngFor="let link of navLinks">
        <a mat-tab-link *ngIf="link.data && showTab(link.data)" [routerLink]="link.path" routerLinkActive
            #rla="routerLinkActive" [active]="rla.isActive">
            <span
                data-e2e="line.tab"
                *ngIf="titleTranslations$ | async as titleTranslation"
                matBadgeOverlap="false"
                [matBadge]="counts[link.data.countAttribute] > 0 ? counts[link.data.countAttribute] : null"
                matBadgeColor="accent">
                {{ titleTranslation['titles.' + link.data.name.toLowerCase()] }}
            </span>
                
        </a>
    </ng-container>
</nav>
<router-outlet></router-outlet>
