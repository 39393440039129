import { Injectable } from '@angular/core';
import { DataStore } from '../../services/datastore/datastore.service';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from 'src/app/models/user';

@Injectable({
  providedIn: 'root'
})
export class UsersResolverService implements Resolve<User[]> {

  constructor(private datastore: DataStore) { }

  resolve(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<User[]> {

    return this.datastore.findAll(User).pipe(
      map(usersResult => usersResult.getModels()),
    );
  }
}
