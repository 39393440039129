import * as tslib_1 from "tslib";
import { JsonApiModelConfig, JsonApiModel, Attribute } from 'angular2-jsonapi';
var UserActivity = /** @class */ (function (_super) {
    tslib_1.__extends(UserActivity, _super);
    function UserActivity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        Attribute({ serializedName: 'first-name' }),
        tslib_1.__metadata("design:type", String)
    ], UserActivity.prototype, "firstName", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'last-name' }),
        tslib_1.__metadata("design:type", String)
    ], UserActivity.prototype, "lastName", void 0);
    tslib_1.__decorate([
        Attribute(),
        tslib_1.__metadata("design:type", String)
    ], UserActivity.prototype, "email", void 0);
    tslib_1.__decorate([
        Attribute(),
        tslib_1.__metadata("design:type", String)
    ], UserActivity.prototype, "time", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'activity-type' }),
        tslib_1.__metadata("design:type", String)
    ], UserActivity.prototype, "activityType", void 0);
    UserActivity = tslib_1.__decorate([
        JsonApiModelConfig({
            type: 'user-activities'
        })
    ], UserActivity);
    return UserActivity;
}(JsonApiModel));
export { UserActivity };
