import { Injectable } from '@angular/core';
import '@datadog/browser-rum/bundle/datadog-rum';

declare global {
    interface Window { DD_RUM: any; }
}
const DD_RUM = window.DD_RUM;

@Injectable()

export class DatadogService {

    private activateRum = false;

    load(environment, version: string) {
        this.activateRum = ['qa', 'integration', 'staging', 'production'].includes(environment.name);
        if (this.activateRum) {
            if (environment.datadog_app_id.length === 0 || environment.datadog_client_token.length === 0) { return; }
            const datadogInitValue = {
                applicationId: environment.datadog_app_id,
                clientToken: environment.datadog_client_token,
                site: 'datadoghq.com',
                service: 'registry-ui',

                env: environment.name,
                version: version,

                sessionSampleRate: 100,
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
            };
            DD_RUM.init(datadogInitValue);
        }
    }

    onLogin() {
        if (this.activateRum) {
            // only record sessions for successfully authenticated users
            DD_RUM.startSessionReplayRecording();
        }
    }
}
