import { JsonApiModelConfig, JsonApiModel, Attribute, HasMany, BelongsTo } from 'angular2-jsonapi';
import { ProductionRun } from './production-run';
import { Alert } from './alert';

@JsonApiModelConfig({
    type: 'tunnels'
})

export class Tunnel extends JsonApiModel {
    @Attribute()
    name: string;

    @Attribute({ serializedName: 'software-version'})
    softwareVersion: string;

    @HasMany({key: 'active-alerts'})
    activeAlerts: Alert[];

    @BelongsTo({key: 'active-production-run'})
    activeProductionRun: ProductionRun;
}
