import * as tslib_1 from "tslib";
import { JsonApiModelConfig, JsonApiModel, Attribute } from 'angular2-jsonapi';
var Role = /** @class */ (function (_super) {
    tslib_1.__extends(Role, _super);
    function Role() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        Attribute(),
        tslib_1.__metadata("design:type", String)
    ], Role.prototype, "name", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'display-name' }),
        tslib_1.__metadata("design:type", String)
    ], Role.prototype, "displayName", void 0);
    Role = tslib_1.__decorate([
        JsonApiModelConfig({
            type: 'roles'
        })
    ], Role);
    return Role;
}(JsonApiModel));
export { Role };
