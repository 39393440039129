<mat-card fxLayout="column" fxLayoutAlign="space-between center" class="mat-elevation-z4">
  <mat-card-title *ngIf="page === 'reset'"> {{ 'password_reset.password_reset' | translate }} </mat-card-title>
  <mat-card-title *ngIf="page === 'signup'"> {{ 'password_reset.welcome' | translate: {name: firstName} }}  </mat-card-title>
  <p *ngIf="page === 'signup'"> {{ 'password_reset.lets_set' | translate }} </p>
  <p fxFlexAlign="start"> {{ 'password_reset.the_rules' | translate }} </p>
  <div fxLayout="row">
    <ul *ngIf="passwordSettings">
      <li [ngClass]="{'valid': valid('minLength')}"> {{ 'reset_password_page.min_characters' | translate: {minLength: passwordSettings?.minLength} }} </li>
      <li [ngClass]="{'valid': valid('containsDigit')}" *ngIf="passwordSettings?.containsDigit"> {{ 'reset_password_page.one_number' | translate }} </li>
      <li [ngClass]="{'valid': valid('containsSpecial')}" *ngIf="passwordSettings?.containsSpecial"> {{ 'reset_password_page.special_character' | translate }} </li>
    </ul>
    <ul>
      <li [ngClass]="{'valid': valid('containsLowercase')}" *ngIf="passwordSettings?.containsLowercase"> {{ 'reset_password_page.lowercase' | translate }} </li>
      <li [ngClass]="{'valid': valid('containsUppercase')}" *ngIf="passwordSettings?.containsUppercase"> {{ 'reset_password_page.uppercase' | translate }} </li>
      <li [ngClass]="{'valid': valid('containsBlank')}" *ngIf="passwordSettings?.containsBlank"> {{ 'reset_password_page.blank_character' | translate }} </li>
    </ul>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between" fxFlexAlign="stretch">
    <mat-form-field>
      <input type="password" [(ngModel)]="newPassword" matInput required [placeholder]="'password_reset.new_password' | translate">
    </mat-form-field>
    <mat-form-field [ngClass]="{'error-hint': reEnteredPassword && reEnteredPassword !== newPassword}">
      <input type="password" [(ngModel)]="reEnteredPassword" matInput required [placeholder]="'password_reset.re-enter_password' | translate">
      <mat-hint *ngIf="reEnteredPassword && reEnteredPassword !== newPassword">{{ 'reset_password_page.must_match' | translate }}</mat-hint>
    </mat-form-field>
  </div>
  <button mat-raised-button color="accent" (click)="resetAndLogin()" [disabled]="!newPasswordIsValid()">
      {{ (page === 'signup' ? 'password_reset.set_and_login' : 'password_reset.reset_and_login') | translate }}
  </button>
</mat-card>
