import { Component, OnInit, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

import { PasswordSettings } from '../../models/password-settings';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'reg-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {

  @Input() page: string;
  passwordSettings: PasswordSettings;
  newPassword: string;
  reEnteredPassword: string;
  resetToken: string;
  dismiss: string;
  successMessage: string;
  firstName: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private toast: MatSnackBar,
    private translate: TranslateService
  ) {
    this.translate.get([
      'dismiss',
      'reset_password_page.reset_successfully'
    ]).subscribe(translation => {
      this.dismiss = translation['dismiss'];
      this.successMessage = translation['reset_password_page.reset_successfully'];
    });
   }

  ngOnInit() {
   this.route.queryParams.subscribe(params => {
     this.resetToken = params['reset_token'];
     this.firstName = params['name'] || '';
   });

    this.authService.getPasswordSettingsWithToken(this.resetToken, this.page).subscribe((passwordSettings) => {
      this.passwordSettings = passwordSettings;
    });
  }

  resetAndLogin() {
    this.authService.resetPasswordWithToken(this.newPassword, this.resetToken).subscribe((authResult) => {
      this.toast.open(this.successMessage, this.dismiss, {
        duration: 3000
      });

      this.authService.postLoginRouting(authResult);
    });
  }

  newPasswordIsValid() {
    let meetsRules = true;
    for (const rule in this.passwordSettings) {
      if (this.passwordSettings.hasOwnProperty(rule) && !!this.passwordSettings[rule]) {
        meetsRules = meetsRules && this.valid(rule.replace('_', ''));
      }
    }

    return this.newPassword === this.reEnteredPassword && meetsRules;
  }

  valid(validation: string) {
    if (!this.passwordSettings) {
          return false;
    }

    if (this.newPassword) {
      // account for French characters
      switch (validation) {
        case 'containsLowercase': {
          return this.newPassword.toUpperCase() !== this.newPassword;
        }
        case 'containsUppercase': {
          return this.newPassword.toLowerCase() !== this.newPassword;
        }
        case 'minLength': {
          return (this.newPassword.length >= this.passwordSettings.minLength);
        }
        case 'containsSpecial': {
          return /[\x21-\x2F | \x38-\x40 | \x5B-\x60 | \x7B-\x7E ]/.test(this.newPassword);
        }
        case 'containsBlank': {
          return /[\s]/.test(this.newPassword);
        }
        case 'containsDigit': {
          return /[0-9]/.test(this.newPassword);
        }
        case 'maxLength': {
            return this.newPassword.length <= this.passwordSettings.maxLength;
        }
        // default to valid if we don't understand the rule
        default: return true;
      }
    }
  }
}
