import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataStore } from 'src/app/services/datastore/datastore.service';
import { ProductionRun } from 'src/app/models/production-run';

@Injectable({
  providedIn: 'root'
})
export class ApprovedBatchesResolverService implements Resolve<any> {

  constructor(private datastore: DataStore) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
      const last30Days = new Date();
      last30Days.setDate(last30Days.getDate() - 30);

      return this.datastore
         .findAll(ProductionRun, {state: 'approved', time_filter_event: 'approve', time_filter_start: last30Days})
         .pipe(
           map(productionRunsResult => productionRunsResult.getModels())
         );
    }
}
