import { JsonApiModelConfig, JsonApiModel, Attribute } from 'angular2-jsonapi';

@JsonApiModelConfig({
    type: 'roles'
})

export class Role extends JsonApiModel {

    @Attribute()
    name: string;

    @Attribute({ serializedName: 'display-name' })
    displayName: string;
}
