import { OnInit, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { of } from 'rxjs';
import { delay, tap, filter, map, mergeMap, concatMap } from 'rxjs/operators';
import { CatalogEntryDetailsComponent } from 'src/app/dialogs/catalog-entry-details/catalog-entry-details.component';
import { AuthService } from 'src/app/services/auth/auth.service';
var CatalogEntryIndexComponent = /** @class */ (function () {
    function CatalogEntryIndexComponent(router, route, dialog, 
    /* tslint:disable:no-unused-variable */
    authService) {
        this.router = router;
        this.route = route;
        this.dialog = dialog;
        this.authService = authService;
        this.displayedColumns = ['ndcFull', 'unitOfSales', 'itemName', 'manufacturerName', 'itemStrength', 'packageSize',
            'expiringFridgeDays', 'expiringMultidoseDays'];
        this.queryValue = '';
    }
    CatalogEntryIndexComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.routeDataSub = this.route.data.subscribe(function (_a) {
            var catalogs = _a.catalogs;
            // adds unit of sale identifiers to top level of catalog object, for filtering
            catalogs.forEach(function (catalog) {
                var unitsOfSale = {};
                var unitOfSaleId = 0;
                catalog.unitOfSales.forEach(function (unit) {
                    unitsOfSale[unitOfSaleId++] = unit.ndcFull;
                    unitsOfSale[unitOfSaleId++] = unit.gtin;
                });
                return catalog = Object.assign(catalog, unitsOfSale);
            });
            _this.dataSource = new MatTableDataSource(catalogs);
            _this.dataSource.paginator = _this.paginator;
            _this.dataSource.sort = _this.sort;
            _this.sort.sort({ id: 'ndcFull', start: 'asc', disableClear: false });
        });
        this.routeParamSub = this.route.queryParams.pipe(filter(function (_a) {
            var search = _a.search;
            return search;
        }), map(function (_a) {
            var search = _a.search;
            return search;
        }), delay(250), tap(function () { return _this.queryInput.nativeElement.focus(); }), delay(300), mergeMap(function (query) { return query.split(''); }), concatMap(function (charArray) { return of(charArray)
            .pipe(tap(function (char) { return _this.queryValue += char; }), delay(50), tap(function () { return _this.applyFilter(_this.queryValue); })); })).subscribe();
    };
    CatalogEntryIndexComponent.prototype.ngOnDestroy = function () {
        this.routeDataSub.unsubscribe();
        this.routeParamSub.unsubscribe();
    };
    CatalogEntryIndexComponent.prototype.details = function (item) {
        if (item.unitOfSales.length) {
            // ie is dumb and doesn't size correctly
            // so we have to calculate the height ourselves
            var height = 200 + (Math.min(item.unitOfSales.length, 6) * 50);
            this.dialog.open(CatalogEntryDetailsComponent, {
                width: '800px',
                height: height + "px",
                data: {
                    item: item
                }
            });
        }
    };
    CatalogEntryIndexComponent.prototype.applyFilter = function (filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    };
    CatalogEntryIndexComponent.prototype.clearSeach = function () {
        this.queryValue = '';
        this.applyFilter(this.queryValue);
    };
    CatalogEntryIndexComponent.prototype.createCatalogEntry = function () {
        this.router.navigate(['catalog/create-entry']);
    };
    CatalogEntryIndexComponent.prototype.onSortData = function (sort) {
        this.dataSource.sortingDataAccessor = function (item, property) {
            switch (property) {
                case 'ndcFull': {
                    // coerce to string
                    return item[property] + '';
                }
                default: {
                    return item[property];
                }
            }
        };
    };
    return CatalogEntryIndexComponent;
}());
export { CatalogEntryIndexComponent };
