import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment-timezone';

const NO_AUTH_URLS = [
    'version.txt',
    'assets',
    'sessions',
    'emails.json',
];

@Injectable({
  providedIn: 'root'
})

export class HttpHeadersInterceptService implements HttpInterceptor {

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        let authToken = localStorage.getItem('authToken');
        if (req.method === 'DELETE' && req.url.includes('sessions') && localStorage.getItem('lastToken')) {
            authToken = localStorage.getItem('lastToken');
        }

        if (!authToken) {
            const noAuthAllowed = NO_AUTH_URLS.reduce((contains, u) => {
                return contains || req.url.includes(u);
            }, false);
            if (!noAuthAllowed) {
                throw new Error('No auth token to make this request: ' + req.url);
            }
        }
        const tz = moment.tz.guess();

        return next.handle(req.clone({
            headers: req.headers
            .set('authorization', 'Bearer ' + authToken)
            .set('JSONAPI', 'true')
            .set('timezone', tz),
        }));
    }
}
