import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material';
import { tap, shareReplay, map } from 'rxjs/operators';
import { urls } from '../../../urls';
import { DataStore } from '../datastore/datastore.service';
import { UserSession } from '../../models/user-session';
import { KioskSession } from '../../models/kiosk-session';
import { PasswordSettings } from '../../models/password-settings';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../datastore/datastore.service";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "@angular/material/dialog";
var AuthService = /** @class */ (function () {
    function AuthService(http, dataStore, router, location, dialog) {
        this.http = http;
        this.dataStore = dataStore;
        this.router = router;
        this.location = location;
        this.dialog = dialog;
        this.KIOSK_URL = urls.API_URL + "/kiosk-sessions";
        this.PASSWORD_URL = urls.API_URL + "/users/password";
        this.SESSION_URL = urls.API_URL + "/sessions";
        this.SESSION_ACTIVITY_URL = urls.API_URL + "/sessions/activity";
        this.SESSION_ACCOUNT_URL = urls.API_URL + "/sessions/account";
    }
    AuthService.prototype.resetPassword = function (password) {
        return this.http.put(this.PASSWORD_URL, { password: password }).pipe(
        // tap(pwResetResponse => console.log(pwResetResponse)),
        shareReplay());
    };
    AuthService.prototype.getPasswordSettings = function () {
        return this.dataStore.findRecord(PasswordSettings, null).pipe(
        // tap(pwSettingsResult => console.log(pwSettingsResult)),
        );
    };
    AuthService.prototype.login = function (email, password) {
        var _this = this;
        return this.http.post(this.SESSION_URL, { email: email, password: password }, { observe: 'response' }).pipe(map(function (res) { return _this.dataStore.deserialize(res, UserSession); }), tap(this.setSession), shareReplay());
    };
    AuthService.prototype.notifyInactivity = function (secondsSinceActivity) {
        if (!this.isInKioskMode()) {
            return this.http.post(this.SESSION_ACTIVITY_URL, { secondsSinceActivity: secondsSinceActivity }).pipe(
            // tap(notifyInactivityResult => console.log(notifyInactivityResult)),
            shareReplay());
        }
    };
    AuthService.prototype.forgotPassword = function (email) {
        return this.http.post(this.SESSION_URL + "/forgot-password", { email: email }).pipe(shareReplay());
    };
    AuthService.prototype.resetPasswordWithToken = function (password, resetToken) {
        var _this = this;
        return this.http.post(this.SESSION_URL + "/reset-password", { password: password, resetToken: resetToken }, { observe: 'response' }).pipe(map(function (res) { return _this.dataStore.deserialize(res, UserSession); }), tap(this.setSession), shareReplay());
    };
    AuthService.prototype.getPasswordSettingsWithToken = function (resetToken, page) {
        var _this = this;
        return this.http.get(this.SESSION_URL + "/password-reset-settings?reset_token=" + resetToken + "&page=" + page, { observe: 'response' }).pipe(map(function (res) { return _this.dataStore.deserialize(res, PasswordSettings); }), shareReplay());
    };
    AuthService.prototype.setSession = function (authResult) {
        localStorage.removeItem('kioskMode');
        localStorage.setItem('userId', authResult.id.toString()); // used to know which user is me
        if (authResult.userType === 'registry_admin_user') {
            localStorage.setItem('roles', JSON.stringify(['registry_admin_user'])); // roles available
        }
        else {
            localStorage.setItem('roles', JSON.stringify(authResult.roles.map(function (r) { return r.name; }))); // roles available
        }
        localStorage.setItem('navName', authResult.navName); // used to display email in nav bar
        localStorage.setItem('authToken', authResult.authenticationToken); // token for authentication
    };
    AuthService.prototype.setKioskSession = function (authResult) {
        localStorage.setItem('kioskName', authResult.navName);
        localStorage.setItem('kioskAuthToken', authResult.authenticationToken);
    };
    AuthService.prototype.destroySession = function () {
        localStorage.setItem('lastToken', localStorage.getItem('authToken'));
        this.logOut();
        return this.http.delete(this.SESSION_URL).pipe(tap(function () { return localStorage.removeItem('lastToken'); }), shareReplay());
    };
    AuthService.prototype.logOut = function () {
        localStorage.removeItem('authToken');
        localStorage.removeItem('roles');
        localStorage.removeItem('userId');
        localStorage.removeItem('navName');
        // enter kiosk mode if configured
        if (this.kioskIsConfigured()) {
            localStorage.setItem('navName', localStorage.getItem('kioskName'));
            localStorage.setItem('authToken', localStorage.getItem('kioskAuthToken'));
            localStorage.setItem('kioskMode', 'true');
            if (this.location.path() !== '/batches/lines') {
                this.router.navigate(['batches/lines']);
            }
        }
        else {
            localStorage.removeItem('kioskMode');
            this.router.navigate(['login']);
        }
        // close modal if open
        this.dialog.closeAll();
    };
    AuthService.prototype.postLoginRouting = function (authResult) {
        if (authResult.userType === 'registry_admin_user') {
            this.router.navigate(['accounts']);
            return;
        }
        var roleRoutes = {
            line_operator: 'batches',
            batch_approver: 'batches/approve',
            catalog_manager: 'catalog',
            auditor: 'reports',
            user_manager: 'users',
        };
        for (var role in roleRoutes) {
            if (this.hasRole(role)) {
                this.router.navigate([roleRoutes[role]]);
                return;
            }
        }
        // should never reach this but just in case
        this.router.navigate(['catalog']);
    };
    AuthService.prototype.isLoggedIn = function () {
        return !!localStorage.getItem('authToken');
    };
    AuthService.prototype.isLoggedOut = function () {
        return !this.isLoggedIn();
    };
    AuthService.prototype.configureKiosk = function (name) {
        var _this = this;
        return this.http.post(this.KIOSK_URL, { name: name }, { observe: 'response' }).pipe(map(function (res) { return _this.dataStore.deserialize(res, KioskSession); }), tap(this.setKioskSession), shareReplay());
    };
    AuthService.prototype.isInKioskMode = function () {
        return !!localStorage.getItem('kioskMode');
    };
    AuthService.prototype.kioskIsConfigured = function () {
        return !!localStorage.getItem('kioskAuthToken');
    };
    AuthService.prototype.getRoles = function () {
        if (this.isInKioskMode()) {
            return ['kiosk'];
        }
        else {
            return JSON.parse(localStorage.getItem('roles') || '[]');
        }
    };
    AuthService.prototype.hasRole = function (roles) {
        var _this = this;
        if (typeof roles === 'string') {
            return this.getRoles().includes(roles);
        }
        else {
            return roles.some(function (role) { return _this.getRoles().includes(role); });
        }
    };
    AuthService.prototype.isLoginRequest = function (request) {
        return request.method === 'POST' && request.url.endsWith('/sessions');
    };
    AuthService.prototype.requestWasMadeWithCurrentToken = function (request) {
        return request.headers.get('authorization') === 'Bearer ' + localStorage.getItem('authToken');
    };
    AuthService.prototype.setCurrentAccount = function (accountId) {
        var _this = this;
        return this.http.post(this.SESSION_ACCOUNT_URL + "/" + accountId, {}, { observe: 'response' }).pipe(map(function (res) { return _this.dataStore.deserialize(res, UserSession); }), tap(this.setSession), tap(function (authResult) {
            localStorage.setItem('navName', authResult.navName + " / " + authResult.accountName);
        }), shareReplay());
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.HttpClient), i0.inject(i2.DataStore), i0.inject(i3.Router), i0.inject(i4.Location), i0.inject(i5.MatDialog)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
