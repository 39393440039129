import * as tslib_1 from "tslib";
import { JsonApiModelConfig, JsonApiModel, Attribute, BelongsTo } from 'angular2-jsonapi';
import { User } from './user';
import { ProductionRun } from './production-run';
var Alert = /** @class */ (function (_super) {
    tslib_1.__extends(Alert, _super);
    function Alert() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        Attribute({ serializedName: 'alert-type' }),
        tslib_1.__metadata("design:type", String)
    ], Alert.prototype, "alertType", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'created-at' }),
        tslib_1.__metadata("design:type", String)
    ], Alert.prototype, "createdAt", void 0);
    tslib_1.__decorate([
        Attribute(),
        tslib_1.__metadata("design:type", String)
    ], Alert.prototype, "resolution", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'resolved-at' }),
        tslib_1.__metadata("design:type", String)
    ], Alert.prototype, "resolvedAt", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'tunnel-name' }),
        tslib_1.__metadata("design:type", String)
    ], Alert.prototype, "tunnelName", void 0);
    tslib_1.__decorate([
        BelongsTo({ key: 'resolved-by-user' }),
        tslib_1.__metadata("design:type", User)
    ], Alert.prototype, "user", void 0);
    tslib_1.__decorate([
        BelongsTo({ key: 'production-run' }),
        tslib_1.__metadata("design:type", ProductionRun)
    ], Alert.prototype, "productionRun", void 0);
    Alert = tslib_1.__decorate([
        JsonApiModelConfig({
            type: 'alerts'
        })
    ], Alert);
    return Alert;
}(JsonApiModel));
export { Alert };
