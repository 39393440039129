import { map } from 'rxjs/operators';
import { DataStore } from '../../services/datastore/datastore.service';
import { CatalogEntry } from '../../models/catalog-entry';
import * as i0 from "@angular/core";
import * as i1 from "../../services/datastore/datastore.service";
var CatalogIndexResolverService = /** @class */ (function () {
    function CatalogIndexResolverService(datastore) {
        this.datastore = datastore;
    }
    CatalogIndexResolverService.prototype.resolve = function (_route, _state) {
        return this.datastore.findAll(CatalogEntry).pipe(map(function (catalogEntriesResult) { return catalogEntriesResult.getModels(); }));
    };
    CatalogIndexResolverService.ngInjectableDef = i0.defineInjectable({ factory: function CatalogIndexResolverService_Factory() { return new CatalogIndexResolverService(i0.inject(i1.DataStore)); }, token: CatalogIndexResolverService, providedIn: "root" });
    return CatalogIndexResolverService;
}());
export { CatalogIndexResolverService };
