import * as tslib_1 from "tslib";
import { JsonApiModelConfig, JsonApiModel, Attribute, BelongsTo } from 'angular2-jsonapi';
import { CatalogEntry } from './catalog-entry';
var UnitOfSale = /** @class */ (function (_super) {
    tslib_1.__extends(UnitOfSale, _super);
    function UnitOfSale() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        Attribute(),
        tslib_1.__metadata("design:type", String)
    ], UnitOfSale.prototype, "gtin", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'ndc-digits' }),
        tslib_1.__metadata("design:type", String)
    ], UnitOfSale.prototype, "ndcDigits", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'ndc-full' }),
        tslib_1.__metadata("design:type", String)
    ], UnitOfSale.prototype, "ndcFull", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'package-count' }),
        tslib_1.__metadata("design:type", String)
    ], UnitOfSale.prototype, "packageCount", void 0);
    tslib_1.__decorate([
        BelongsTo({ key: 'catalog-entry' }),
        tslib_1.__metadata("design:type", CatalogEntry)
    ], UnitOfSale.prototype, "catalogEntry", void 0);
    UnitOfSale = tslib_1.__decorate([
        JsonApiModelConfig({
            type: 'unit-of-sales'
        })
    ], UnitOfSale);
    return UnitOfSale;
}(JsonApiModel));
export { UnitOfSale };
