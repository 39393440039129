import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, interval, merge } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import * as moment from 'moment';

import { DataStore } from 'src/app/services/datastore/datastore.service';
import { ProductionRun } from 'src/app/models/production-run';

interface LinesData {
    id: string;
    drug: string;
    lotNumber: string;
    manufacturerExpiration: string;
    line: string;
    lastScan: string;
    unitUseNdc: string;
    unitSaleNdc: string;
    totalCartons: number;
    uniqueValidEpcs: number;
    uniqueInvalidEpcs: number;
    uniqueEpcs: number;
}

@Component({
    selector: 'reg-finish',
    templateUrl: './finish.component.html',
    styleUrls: ['./finish.component.scss']
})
export class FinishComponent implements OnInit {

    routeData$: Observable<ProductionRun[]>;
    pollingData$: Observable<ProductionRun[]>;
    dataSource$: Observable<Array<LinesData>>;
    POLLING_INTERVAL = 5000;

    constructor(
        public route: ActivatedRoute,
        private dataStore: DataStore
    ) {}

    ngOnInit() {
        this.routeData$ = this.route.data.pipe(
            map((routeData: any) => routeData.batches.filter((batch) => !batch.activeBatch))
        );

        this.pollingData$ = interval(this.POLLING_INTERVAL).pipe(
            concatMap(() => this.dataStore.findAll(ProductionRun, {state: 'started'})),
            map(productionRunsResult => productionRunsResult.getModels()),
            map(batches => batches.filter((batch) => !batch.activeBatch))
        );

        this.dataSource$ = merge(
            this.routeData$,
            this.pollingData$
        ).pipe(
            map((productionRunData: ProductionRun[]) => this.mapRunsDataLinesData(productionRunData)),
            map((tableData: LinesData[]) => tableData)
        );
    }

    mapRunsDataLinesData(productionRunData: ProductionRun[]): LinesData[] {
        return productionRunData.map((batch) => {
            const catalogEntry = batch.unitOfSale.catalogEntry;
            const accountType = batch.account ? batch.account.accountType : null;
            const accountName = accountType ? accountType.name : null;

            try {
              return {
                  id: batch.id,
                  drug: batch.drugInformation,
                  lotNumber: batch.lotNumber,
                  manufacturerExpiration: accountName === 'compounder' ? moment(batch.expirationManufacturer).format('DDMMMYYYY').toUpperCase() :
                  moment(batch.expirationManufacturer).format('MMMYYYY').toUpperCase(),
                  expirationFull: batch.expirationManufacturer,
                  line: batch.tunnelName,
                  lastScan: moment(batch.lastScan).format('YYYY-MM-DD HH:mm:ss'),
                  unitUseNdc: catalogEntry.ndcFull,
                  unitSaleNdc: batch.unitOfSale && batch.unitOfSale.ndcFull,
                  totalCartons: batch.totalCartons,
                  uniqueValidEpcs: batch.totalUniqueValidEpcs,
                  uniqueInvalidEpcs: batch.totalUniqueInvalidEpcs,
                  uniqueEpcs: batch.totalUniqueValidEpcs + batch.totalUniqueInvalidEpcs
              };
            } catch (error) {
              console.error('Error while trying to map the batch', error);
            }
        });
    }
}
