import { Injectable} from '@angular/core';
import { MatDialog } from '@angular/material';

import { PasswordResetDialogComponent } from 'src/app/dialogs/password-reset/password-reset.component';

@Injectable({
    providedIn: 'root'
})

export class PasswordResetService {

    constructor(
        private dialog: MatDialog
    ) { }

    public resetPasswordModal() {
        this.dialog.open(PasswordResetDialogComponent, {
            width: '480px'
        });
    }
}
