<h1 mat-dialog-title> {{'catalog_entry_details.title' | translate }} {{data.item.ndcFull}} </h1>
    <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="ndcFull">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'catalog_entry_details.ndc' | translate }} </th>
            <td mat-cell *matCellDef="let row"> {{row.ndcFull}} </td>
        </ng-container>

        <ng-container matColumnDef="gtin">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'catalog_entry_details.gtin' | translate }} </th>
            <td mat-cell *matCellDef="let row"> {{row.gtin}} </td>
        </ng-container>

        <ng-container matColumnDef="packageCount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'catalog_entry_details.package_count' | translate }} </th>
            <td mat-cell *matCellDef="let row"> {{row.packageCount}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

<div class="buttons" mat-dialog-actions fxLayoutAlign="end">
    <button mat-stroked-button (click)="onCancel()"> {{'transition_batch.done' | translate }} </button>
</div>
