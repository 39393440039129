import { JsonApiModelConfig, JsonApiModel, Attribute } from 'angular2-jsonapi';

@JsonApiModelConfig({
    type: 'user-activities'
})

export class UserActivity extends JsonApiModel {
    @Attribute({ serializedName: 'first-name' })
     firstName: string;

    @Attribute({ serializedName: 'last-name' })
    lastName: string;

    @Attribute()
    email: string;

    @Attribute()
    time: string;

    @Attribute({ serializedName: 'activity-type' })
    activityType: string;
}
