<section fxLayout="column" fxLayoutAlign="center center">
    <mat-card fxLayout="column" fxLayoutAlign="start center">
        <mat-form-field appearance="standard">
            <mat-label>{{ 'login_page.email' | translate }}</mat-label>
            <input matInput type="email" autocomplete="email" #email>
            <mat-icon matSuffix>perm_identity</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="standard">
            <mat-label>{{ 'login_page.password' | translate }}</mat-label>
            <input matInput type="password" autocomplete="current-password" #password
            (keydown.enter)="(!email.value || !password.value) ? '' : login(email.value, password.value)">
            <mat-icon matSuffix>lock_open</mat-icon>
        </mat-form-field>
        
        <button mat-raised-button color="accent" (click)="login(email.value, password.value)" [disabled]="!email.value || !password.value">
            {{ 'login_page.login' | translate }}
        </button>
    </mat-card>
    <a class="no-underline" fxFlexOffset="1" (click)="forgotPassword()"> {{ 'login_page.forgot_password' | translate }} </a>
</section>
