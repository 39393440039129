import { AccountModel } from './account';

export interface UserModel {
    Account: AccountModel;
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    authentication_token: string;
    password_reset_required: boolean;
    roles: string[];
}

import { JsonApiModelConfig, JsonApiModel, Attribute, HasMany } from 'angular2-jsonapi';
import { Role } from './role';
import { Alert } from './alert';

@JsonApiModelConfig({
    type: 'users'
})

export class User extends JsonApiModel {

    @Attribute({ serializedName: 'first-name' })
    firstName: string;

    @Attribute({ serializedName: 'last-name' })
    lastName: string;

    @Attribute()
    email: string;

    @HasMany()
    roles: Role[];

    @Attribute({ serializedName: 'has-logged-in?' })
    hasLoggedIn: boolean;

    @HasMany()
    alerts: Alert[];
}
