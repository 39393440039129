import { urls } from 'src/urls';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DataStore } from '../datastore/datastore.service';
import { UserActivity } from 'src/app/models/user-activity';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../datastore/datastore.service";
var UserActivityService = /** @class */ (function () {
    function UserActivityService(http, datastore) {
        this.http = http;
        this.datastore = datastore;
        this.USER_ACTIVITY_URL = urls.API_URL + "/user-activities";
    }
    UserActivityService.prototype.getUserActivities = function (startDate, endDate, email, activityType) {
        var parameters = {};
        if (startDate) {
            parameters.time_filter_start = startDate;
        }
        if (endDate) {
            parameters.time_filter_end = endDate;
        }
        if (email) {
            parameters.email = email;
        }
        if (activityType) {
            parameters.activity_type = activityType;
        }
        return this.datastore.findAll(UserActivity, parameters).pipe(map(function (activityResult) { return activityResult.getModels(); }));
    };
    UserActivityService.prototype.downloadUserActivity = function (downloadFilters) {
        var params = new HttpParams();
        if (downloadFilters.startDate) {
            params = params.append('time_filter_start', downloadFilters.startDate);
        }
        if (downloadFilters.endDate) {
            params = params.append('time_filter_end', downloadFilters.endDate);
        }
        if (downloadFilters.email) {
            params = params.append('email', downloadFilters.email);
        }
        if (downloadFilters.type) {
            params = params.append('activity_type', downloadFilters.type);
        }
        var options = { responseType: 'text', params: params };
        return this.http.get(this.USER_ACTIVITY_URL + ".csv", options).pipe(
        // tap(result => console.log(result))
        );
    };
    UserActivityService.ngInjectableDef = i0.defineInjectable({ factory: function UserActivityService_Factory() { return new UserActivityService(i0.inject(i1.HttpClient), i0.inject(i2.DataStore)); }, token: UserActivityService, providedIn: "root" });
    return UserActivityService;
}());
export { UserActivityService };
