import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { urls } from '../../../urls';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DEFAULT_SUPPORT_EMAIL = 'help@bluesight.com';
var EmailService = /** @class */ (function () {
    function EmailService(http) {
        this.http = http;
    }
    EmailService.prototype.setEmails = function () {
        this.http.get(urls.API_BASE_URL + "/emails.json").pipe(tap(function (emails) {
            if (emails.support_email) {
                localStorage.setItem('supportEmail', emails.support_email);
            }
        })).subscribe();
    };
    EmailService.prototype.getSupportEmail = function () {
        return localStorage.getItem('supportEmail') || DEFAULT_SUPPORT_EMAIL;
    };
    EmailService.ngInjectableDef = i0.defineInjectable({ factory: function EmailService_Factory() { return new EmailService(i0.inject(i1.HttpClient)); }, token: EmailService, providedIn: "root" });
    return EmailService;
}());
export { EmailService };
