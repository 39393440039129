import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { JsonApiDatastoreConfig, JsonApiDatastore } from 'angular2-jsonapi';
import { urls } from '../../../urls';
import { PackageDescription } from '../../models/package-description';
import { CatalogEntry } from '../../models/catalog-entry';
import { UnitOfSale } from '../../models/unit-of-sale';
import { PasswordSettings } from '../../models/password-settings';
import { Tunnel } from 'src/app/models/tunnel';
import { ProductionRun } from 'src/app/models/production-run';
import { Alert } from 'src/app/models/alert';
import { User } from 'src/app/models/user';
import { UserSession } from 'src/app/models/user-session';
import { KioskSession } from 'src/app/models/kiosk-session';
import { Counts } from 'src/app/models/counts';
import { Role } from 'src/app/models/role';
import { Account } from 'src/app/models/account';
import { AccountType } from 'src/app/models/account-type';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var config = {
    baseUrl: urls.API_URL,
    models: {
        'catalog-entries': CatalogEntry,
        'package-descriptions': PackageDescription,
        'unit-of-sales': UnitOfSale,
        'password-settings': PasswordSettings,
        'tunnels': Tunnel,
        'production-runs': ProductionRun,
        'users': User,
        'user-sessions': UserSession,
        'kiosk-sessions': KioskSession,
        'alerts': Alert,
        'counts': Counts,
        'roles': Role,
        'accounts': Account,
        'account-types': AccountType
    }
};
var DataStore = /** @class */ (function (_super) {
    tslib_1.__extends(DataStore, _super);
    function DataStore(http) {
        return _super.call(this, http) || this;
    }
    DataStore.prototype.deserialize = function (res, modelType) {
        return this.extractRecordData(res, modelType);
    };
    DataStore.ngInjectableDef = i0.defineInjectable({ factory: function DataStore_Factory() { return new DataStore(i0.inject(i1.HttpClient)); }, token: DataStore, providedIn: "root" });
    DataStore = tslib_1.__decorate([
        JsonApiDatastoreConfig(config),
        tslib_1.__metadata("design:paramtypes", [HttpClient])
    ], DataStore);
    return DataStore;
}(JsonApiDatastore));
export { DataStore };
