import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'reg-reject-batch',
  templateUrl: './reject-batch.component.html',
  styleUrls: ['./reject-batch.component.scss']
})
export class RejectBatchDialogComponent {
  constructor(
      public dialogRef: MatDialogRef<RejectBatchDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data
  ) { }

  onReject() {
    this.dialogRef.close(true);
  }

  onCancel() {
    this.dialogRef.close(false);
  }
}
