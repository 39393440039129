<h1 mat-dialog-title fxLayoutAlign="center">{{ 'kiosk_configure_page.title' | translate }}</h1>
<div mat-dialog-content fxLayout="row wrap" fxLayoutAlign="center">
    <p fxFlex="grow" fxLayoutAlign="center">{{ 'kiosk_configure_page.name_kiosk' | translate }}</p>

    <mat-form-field appearance="outline">
        <mat-label>{{ 'kiosk_configure_page.kiosk_name' | translate }}</mat-label>
        <input data-e2e="kiosk.name" matInput type="text" [minlength]="2" [(ngModel)]="kioskName">
    </mat-form-field>
</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="center" fxLayoutGap="12px">
    <button mat-stroked-button (click)="onCancel()">{{ 'cancel' | translate }}</button>
    <button mat-raised-button color="accent" [disabled]="(kioskName?.length < 2)" (click)="configureKiosk()">{{ 'kiosk_configure_page.configure' | translate }}</button>
</div>
