import { map } from 'rxjs/operators';
import { DataStore } from 'src/app/services/datastore/datastore.service';
import { ProductionRun } from 'src/app/models/production-run';
import * as i0 from "@angular/core";
import * as i1 from "../../services/datastore/datastore.service";
var ApprovedBatchesResolverService = /** @class */ (function () {
    function ApprovedBatchesResolverService(datastore) {
        this.datastore = datastore;
    }
    ApprovedBatchesResolverService.prototype.resolve = function (route, state) {
        var last30Days = new Date();
        last30Days.setDate(last30Days.getDate() - 30);
        return this.datastore
            .findAll(ProductionRun, { state: 'approved', time_filter_event: 'approve', time_filter_start: last30Days })
            .pipe(map(function (productionRunsResult) { return productionRunsResult.getModels(); }));
    };
    ApprovedBatchesResolverService.ngInjectableDef = i0.defineInjectable({ factory: function ApprovedBatchesResolverService_Factory() { return new ApprovedBatchesResolverService(i0.inject(i1.DataStore)); }, token: ApprovedBatchesResolverService, providedIn: "root" });
    return ApprovedBatchesResolverService;
}());
export { ApprovedBatchesResolverService };
