import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { urls } from '../../../urls';
import { Observable } from 'rxjs';
import { ProductionRun } from 'src/app/models/production-run';
import { DataStore } from '../datastore/datastore.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProductionRunsResourceService {
    PRODUCTION_RUNS_URL = `${urls.API_URL}/production-runs`;

    constructor(private http: HttpClient, private datastore: DataStore) { }

    public finish(id, test): Observable<any> {
        return this.http.post<any>(`${this.PRODUCTION_RUNS_URL}/${id}/finish?test=${test}`, null).pipe(
            //  tap(result => console.log(result))
        );
    }

    public approve(id): Observable<any> {
        return this.http.post<any>(`${this.PRODUCTION_RUNS_URL}/${id}/approve`, null).pipe(
            //  tap(result => console.log(result))
        );
    }

    public reject(id): Observable<any> {
      return this.http.post<any>(`${this.PRODUCTION_RUNS_URL}/${id}/reject`, null);
    }

    public downloadEpcs(id): Observable<any> {
       const options = {responseType: 'text' as 'json'};
        return this.http.get<any>(`${this.PRODUCTION_RUNS_URL}/${id}/tags.csv`, options).pipe(
            // tap(result => console.log(result))
        );
    }

    public downloadPdf(id): Observable<any> {
        const options = {responseType: 'text' as 'json'};
        return this.http.get<any>(`${this.PRODUCTION_RUNS_URL}/${id}.pdf`, options).pipe(
        );
    }

    public getBatches(dateRange: string, startDate: string, endDate: string, test: any): Observable<any> {
        const parameters: any = {state: 'approved'};
        if (dateRange && startDate) {
            parameters.time_filter_event = dateRange;
        }

        if (startDate) {
            parameters.time_filter_start = startDate;
        }

        if (endDate) {
            parameters.time_filter_end = endDate;
        }

        if (test || test === false) {
            parameters.test = test;
        }

        return this.datastore.findAll(ProductionRun, parameters).pipe(
            map(batchesResult => batchesResult.getModels())
        );
    }

    public downloadBatchActivity(downloadFilters: any): Observable<any> {
        let params = new HttpParams();
        params = params.append('state', 'approved');
        if (downloadFilters.timeFrame && downloadFilters.startDate) {
            params.append('time_filter_event', downloadFilters.timeFrame);
        }

        if (downloadFilters.startDate) {
            params = params.append('time_filter_start', downloadFilters.startDate);
        }

        if (downloadFilters.endDateTime) {
            params = params.append('time_filter_end', downloadFilters.endDateTime);
        }

        if (downloadFilters.test || downloadFilters.test === false) {
            params = params.append('test', downloadFilters.test);
        }

        const options = {responseType: 'text' as 'json', params: params};

        return this.http.get<any>(`${this.PRODUCTION_RUNS_URL}.csv`, options ).pipe(
            // tap(result => console.log(result))
        );
    }
}
