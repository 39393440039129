import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PasswordSettings } from 'src/app/models/password-settings';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'reg-password-reset-dialog',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss']
})

export class PasswordResetDialogComponent implements OnInit {
    reEnteredPassword: string;
    passwordSettings: PasswordSettings;
    dialogContentHeight = 165;
    PASSWORD_HINTS: string[] = ['containsBlank', 'containsDigit', 'containsLowercase', 'containsUppercase', 'containsSpecial'];
    dismiss: string;
    successMessage: string;
    passwordFormControl: FormControl = new FormControl('');

    constructor(
        public dialogRef: MatDialogRef<PasswordResetDialogComponent>,
        public authService: AuthService,
        private toast: MatSnackBar,
        private translate: TranslateService
    ) {
        this.translate.get([
            'reset_password_page.reset_successfully',
            'dismiss'
        ]).subscribe(translation => {
            this.dismiss = translation['dismiss'];
            this.successMessage = translation['reset_password_page.reset_successfully'];
        });
    }

    ngOnInit() {
        this.authService.getPasswordSettings().pipe(
            tap(passwordSettingsResponse => this.passwordSettings = passwordSettingsResponse),
            tap(() => this.calcDialogHeight()),
            tap(() => this.passwordFormControl = new FormControl('', this.validatePassword.bind(this))),
        ).subscribe();
    }

    calcDialogHeight() {
        this.PASSWORD_HINTS.forEach((hint) => {
            if (this.passwordSettings[hint]) {
                this.dialogContentHeight += 35;
            }
        });
    }

    onCancel() {
        this.dialogRef.close();
    }

    passwordChanged(password: string) {
        if (!password && !this.passwordFormControl.pristine) {
            this.passwordFormControl.reset('');
        }
    }

    updatePassword(newPassword: string) {
        if (newPassword) {
            this.authService.resetPassword(newPassword).subscribe(() => {
                this.dialogRef.close();

                this.toast.open(this.successMessage, this.dismiss, {
                    duration: 3000,
                    panelClass: 'success-snackbar',
                });
            });
        }
    }

    validatePassword(c: FormControl) {
        const errors: any = {};

        if (!this.passwordSettings) {
            return errors;
        }

        if (!c.value) {
            errors['reset_password_page.password_required'] = {};
        } else {
            if (this.passwordSettings.minLength) {
                if (c.value.length < this.passwordSettings.minLength) {
                    errors['reset_password_page.more_chars'] = {moreChars: this.passwordSettings.minLength - c.value.length};
                }
            }
        }

        if (this.passwordSettings.maxLength) {
            if (c.value.length > this.passwordSettings.maxLength) {
                errors['reset_password_page.less_chars'] = {lessChars: c.value.length - this.passwordSettings.maxLength};
            }
        }

        if (this.passwordSettings.containsBlank) {
            if (!c.value.match(/ /)) {
                errors['reset_password_page.blank_character'] = {};
            }
        }

        if (this.passwordSettings.containsDigit) {
            if (!c.value.match(/[0-9]/)) {
                errors['reset_password_page.one_number'] = {};
            }
        }

        if (this.passwordSettings.containsLowercase) {
            if (!c.value.match(/[a-z]/)) {
                errors['reset_password_page.lowercase'] = {};
            }
        }

        if (this.passwordSettings.containsUppercase) {
            if (!c.value.match(/[A-Z]/)) {
                errors['reset_password_page.uppercase'] = {};
            }
        }

        if (this.passwordSettings.containsSpecial) {
            // all the ascii characters that aren't letters numbers or whitespace
            // 32-47, 58-64, 91-96, 123-126
            if (!c.value.match(/[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]/)) {
                errors['reset_password_page.special_character'] = {};
            }
        }

        return errors;
    }

    get newPassword(): string {
        return this.passwordFormControl.value;
    }

    get errors(): string[] {
        return !!this.passwordFormControl.errors ? Object.keys(this.passwordFormControl.errors) : [];
    }
}
