import * as tslib_1 from "tslib";
import { JsonApiModelConfig, JsonApiModel, Attribute, HasMany } from 'angular2-jsonapi';
var User = /** @class */ (function (_super) {
    tslib_1.__extends(User, _super);
    function User() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        Attribute({ serializedName: 'first-name' }),
        tslib_1.__metadata("design:type", String)
    ], User.prototype, "firstName", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'last-name' }),
        tslib_1.__metadata("design:type", String)
    ], User.prototype, "lastName", void 0);
    tslib_1.__decorate([
        Attribute(),
        tslib_1.__metadata("design:type", String)
    ], User.prototype, "email", void 0);
    tslib_1.__decorate([
        HasMany(),
        tslib_1.__metadata("design:type", Array)
    ], User.prototype, "roles", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'has-logged-in?' }),
        tslib_1.__metadata("design:type", Boolean)
    ], User.prototype, "hasLoggedIn", void 0);
    tslib_1.__decorate([
        HasMany(),
        tslib_1.__metadata("design:type", Array)
    ], User.prototype, "alerts", void 0);
    User = tslib_1.__decorate([
        JsonApiModelConfig({
            type: 'users'
        })
    ], User);
    return User;
}(JsonApiModel));
export { User };
