<mat-card class="mat-elevation-z4">
  <div fxLayout="row" fxLayoutAlign="space-between">
      <mat-card-title> {{ 'users.users' | translate }} </mat-card-title>
      <button class="create-user" *ngIf="canManageUsers()" mat-raised-button color="accent" (click)="createUser()"> {{ 'users.add_user' | translate }} </button>
  </div>
  <table mat-table [dataSource]="dataSource" matSort fxFill>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'users.email' | translate }} </th>
      <td mat-cell *matCellDef="let row"> {{row.email}} </td>
  </ng-container>

  <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'users.first_name' | translate }} </th>
      <td mat-cell *matCellDef="let row"> {{row.firstName}} </td>
  </ng-container>

  <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'users.last_name' | translate }} </th>
      <td mat-cell *matCellDef="let row"> {{row.lastName}} </td>
  </ng-container>

  <ng-container matColumnDef="roles">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'users.roles' | translate }} </th>
      <td mat-cell *matCellDef="let row"> {{row.displayRoles}} </td>
  </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="updateUser(row)"></tr>
</table>

<mat-paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
</mat-card>
