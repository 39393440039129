import { MatDialog } from '@angular/material';
import { PasswordResetDialogComponent } from 'src/app/dialogs/password-reset/password-reset.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var PasswordResetService = /** @class */ (function () {
    function PasswordResetService(dialog) {
        this.dialog = dialog;
    }
    PasswordResetService.prototype.resetPasswordModal = function () {
        this.dialog.open(PasswordResetDialogComponent, {
            width: '480px'
        });
    };
    PasswordResetService.ngInjectableDef = i0.defineInjectable({ factory: function PasswordResetService_Factory() { return new PasswordResetService(i0.inject(i1.MatDialog)); }, token: PasswordResetService, providedIn: "root" });
    return PasswordResetService;
}());
export { PasswordResetService };
