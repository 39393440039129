/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./permission-denied.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./permission-denied.component";
var styles_PermissionDeniedComponent = [i0.styles];
var RenderType_PermissionDeniedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PermissionDeniedComponent, data: {} });
export { RenderType_PermissionDeniedComponent as RenderType_PermissionDeniedComponent };
export function View_PermissionDeniedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["fxLayout", "row"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("permission_denied")); _ck(_v, 4, 0, currVal_2); }); }
export function View_PermissionDeniedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "reg-permission-denied", [], null, null, null, View_PermissionDeniedComponent_0, RenderType_PermissionDeniedComponent)), i1.ɵdid(1, 114688, null, 0, i5.PermissionDeniedComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PermissionDeniedComponentNgFactory = i1.ɵccf("reg-permission-denied", i5.PermissionDeniedComponent, View_PermissionDeniedComponent_Host_0, {}, {}, []);
export { PermissionDeniedComponentNgFactory as PermissionDeniedComponentNgFactory };
