<h1 mat-dialog-title *ngIf="data.action === 'finish'"> {{'transition_batch.finish_batch' | translate }} </h1>
<h1 mat-dialog-title *ngIf="data.action === 'approve'"> {{'transition_batch.approve_batch' | translate }} </h1>
<div fxLayout="row">
  <div fxLayout="column" fxFlex="60">
    <div fxLayout="row">
      <label> {{'transition_batch.drug' | translate }}: </label>
      <span fxFlexOffset="2"> {{data.batch.drug}} </span>
    </div>
    <div fxLayout="row">
      <label> {{'transition_batch.unit_sale_ndc' | translate }}: </label>
      <span fxFlexOffset="2"> {{data.batch.unitSaleNdc}} </span>
    </div>
    <div fxLayout="row">
      <label> {{'transition_batch.unit_use_ndc' | translate }}: </label>
      <span fxFlexOffset="2"> {{data.batch.unitUseNdc}} </span>
    </div>
  </div>

  <div fxLayout="column" fxFlex="40" fxFlexOffset="15">
    <div fxLayout="row">
      <label> {{'transition_batch.lot' | translate }}: </label>
      <span fxFlexOffset="2"> {{data.batch.lotNumber}} </span>
    </div>
    <div fxLayout="row">
      <label> {{'transition_batch.expiration' | translate }}: </label>
      <span fxFlexOffset="2"> {{data.batch.manufacturerExpiration}} </span>
    </div>
  </div>
</div>

<mat-divider [inset]="true"></mat-divider>

<div fxLayout="row">
  <div fxLayout="column" fxFlex="60">
    <div fxLayout="row">
      <label>  {{'transition_batch.line' | translate }}:  </label>
      <span fxFlexOffset="2"> {{data.batch.line}} </span>
    </div>
    <div fxLayout="row">
      <label> {{'transition_batch.total_cartons' | translate }}:  </label>
      <span fxFlexOffset="2"> {{data.batch.totalCartons}} </span>
    </div>
    <div fxLayout="row">
      <label> {{'transition_batch.total_unique_epcs' | translate }}:  </label>
      <span fxFlexOffset="2"> {{data.batch.uniqueEpcs}} </span>
    </div>
    <div fxLayout="row">
      <label> {{'transition_batch.unique_valid_epcs' | translate }}:  </label>
      <span fxFlexOffset="2"> {{data.batch.uniqueValidEpcs}} </span>
    </div>
    <div fxLayout="row">
      <label> {{'transition_batch.unique_invalid_epcs' | translate }}:  </label>
      <span fxFlexOffset="2"> {{data.batch.uniqueInvalidEpcs}} </span>
    </div>
  </div>

  <div *ngIf="data.action === 'finish'" fxLayout="column" fxFlex="40" fxFlexOffset="15">
      <label > {{'transition_batch.batch_type' | translate }}: </label>
      <mat-form-field>
        <mat-select [(ngModel)]="test">
        <mat-option *ngFor="let type of batchTypes" [value]="type[1]">
          {{type[0]}}
        </mat-option>
        </mat-select>
      </mat-form-field>
  </div>
  <div *ngIf="data.action === 'approve'" fxLayout="column"  fxFlex="40" fxFlexOffset="15">
    <div fxLayout="row">
      <label> {{'transition_batch.batch_type' | translate }}: </label>
      <span fxFlexOffset="2"> {{data.batch.test}} </span>
    </div>
    <div fxLayout="row">
      <label> {{'transition_batch.finished_at' | translate }}: </label>
      <span fxFlexOffset="2"> {{data.batch.processedAt}} </span>
    </div>
    <div fxLayout="row">
      <label> {{'transition_batch.finished_by' | translate }}: </label>
      <span fxFlexOffset="2"> {{data.batch.processedBy}} </span>
    </div>
  </div>
</div>

<div class="buttons" mat-dialog-actions  fxLayout="row" fxLayoutAlign="end" fxLayoutGap="30px">
    <button mat-stroked-button (click)="onCancel()"> {{'cancel' | translate }} </button>
    <button mat-raised-button color="accent" [mat-dialog-close]="{'test': test, 'id': data.batch.id}"> {{'transition_batch.submit' | translate }} </button>
</div>
