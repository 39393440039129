import * as tslib_1 from "tslib";
import { JsonApiModelConfig, JsonApiModel, Attribute } from 'angular2-jsonapi';
var PackageDescription = /** @class */ (function (_super) {
    tslib_1.__extends(PackageDescription, _super);
    function PackageDescription() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        Attribute(),
        tslib_1.__metadata("design:type", String)
    ], PackageDescription.prototype, "abbreviation", void 0);
    tslib_1.__decorate([
        Attribute(),
        tslib_1.__metadata("design:type", String)
    ], PackageDescription.prototype, "name", void 0);
    PackageDescription = tslib_1.__decorate([
        JsonApiModelConfig({
            type: 'package-descriptions'
        })
    ], PackageDescription);
    return PackageDescription;
}(JsonApiModel));
export { PackageDescription };
