<h1 mat-dialog-title> {{'transition_batch.batch_report' | translate }} </h1>
<div fxLayout="row">
  <div fxLayout="column" fxFlex="50">
    <div fxLayout="row">
      <label> {{'transition_batch.drug' | translate }}: </label>
      <span fxFlexOffset="2"> {{data.batch.drug}} </span>
    </div>
    <div fxLayout="row">
      <label> {{'transition_batch.unit_sale_ndc' | translate }}: </label>
      <span fxFlexOffset="2"> {{data.batch.unitSaleNdc}} </span>
    </div>
    <div fxLayout="row">
      <label> {{'transition_batch.unit_use_ndc' | translate }}: </label>
      <span fxFlexOffset="2"> {{data.batch.unitUseNdc}} </span>
    </div>
  </div>

  <div fxLayout="column" fxFlex="50" fxFlexOffset="15">
    <div fxLayout="row">
      <label> {{'transition_batch.lot' | translate }}: </label>
      <span fxFlexOffset="2"> {{data.batch.lotNumber}} </span>
    </div>
    <div fxLayout="row">
      <label> {{'transition_batch.expiration' | translate }}: </label>
      <span fxFlexOffset="2"> {{data.batch.manufacturerExpiration}} </span>
    </div>
  </div>
</div>

<mat-divider [inset]="true"></mat-divider>

<div fxLayout="row">
  <div fxLayout="column" fxFlex="50">
    <div fxLayout="row">
      <label>  {{'transition_batch.line' | translate }}:  </label>
      <span fxFlexOffset="2"> {{data.batch.line}} </span>
    </div>
    <div fxLayout="row">
      <label> {{'transition_batch.total_cartons' | translate }}:  </label>
      <span fxFlexOffset="2"> {{data.batch.totalCartons}} </span>
    </div>
    <div fxLayout="row">
      <label> {{'transition_batch.total_unique_epcs' | translate }}:  </label>
      <span fxFlexOffset="2"> {{data.batch.uniqueEpcs}} </span>
    </div>
    <div fxLayout="row">
      <label> {{'transition_batch.unique_valid_epcs' | translate }}:  </label>
      <span fxFlexOffset="2"> {{data.batch.uniqueValidEpcs}} </span>
    </div>
    <div fxLayout="row">
      <label> {{'transition_batch.unique_invalid_epcs' | translate }}:  </label>
      <span fxFlexOffset="2"> {{data.batch.uniqueInvalidEpcs}} </span>
    </div>
  </div>

  <div fxLayout="column"  fxFlex="50" fxFlexOffset="15">
    <div fxLayout="row">
      <label> {{'transition_batch.batch_type' | translate }}: </label>
      <span fxFlexOffset="2"> {{data.batch.test}} </span>
    </div>
    <div fxLayout="row">
      <label> {{'transition_batch.finished_at' | translate }}: </label>
      <span fxFlexOffset="2"> {{data.batch.processedAt}} </span>
    </div>
    <div fxLayout="row">
      <label> {{'transition_batch.finished_by' | translate }}: </label>
      <span fxFlexOffset="2"> {{data.batch.processedBy}} </span>
    </div>
    <div fxLayout="row">
      <label> {{'transition_batch.approved_at' | translate }}: </label>
      <span fxFlexOffset="2"> {{data.batch.approvedAt}} </span>
    </div>
    <div fxLayout="row">
      <label> {{'transition_batch.approved_by' | translate }}: </label>
      <span fxFlexOffset="2"> {{data.batch.approvedBy}} </span>
    </div>
  </div>
  </div>

<div id="batch-report-buttons" class="buttons" mat-dialog-actions  fxLayout="row" fxLayoutAlign="end" fxLayoutGap="30px">
  <button mat-button color="accent" (click)="downloadEpcList()"> {{'transition_batch.download_epcs' | translate }} </button>
  <button mat-raised-button color="accent" (click)="printBatchReport()"> {{'transition_batch.print' | translate }} </button>
  <button mat-stroked-button (click)="onCancel()"> {{'transition_batch.done' | translate }} </button>
</div>
