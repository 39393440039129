<section fxLayout="column" fxLayoutAlign="center center">
  <mat-card *ngIf="!sent" fxLayout="column" fxLayoutAlign="space-around center" class="mat-elevation-z4">
    <mat-card-title> {{ 'password_reset.password_reset' | translate }} </mat-card-title>
    <p data-e2e="enter.email">{{ 'password_reset.enter_email' | translate }}</p>
    <mat-form-field [ngClass]="{'error-hint': email && !validEmail()}">
      <input [(ngModel)]="email" matInput required [placeholder]="'password_reset.email' | translate">
      <mat-hint *ngIf="email && !validEmail()"> {{ 'password_reset.invalid_email' | translate }} </mat-hint>
    </mat-form-field>
  <button mat-raised-button color="accent" [disabled]="!email" (click)="send()"> {{ 'password_reset.send' | translate }} </button>
  </mat-card>
  <mat-card *ngIf="sent" fxLayout="column" fxLayoutAlign="center center" class="mat-elevation-z4">
    <mat-card-title> {{ 'password_reset.sent' | translate }} </mat-card-title>
    <p data-e2e="check.email"> {{ 'password_reset.check_email' | translate }} </p>
    <p data-e2e="dont.wait"> {{ 'password_reset.dont_wait' | translate }} </p>
  </mat-card>
</section>
