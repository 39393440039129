import { map } from 'rxjs/operators';
import { ProductionRun } from '../../models/production-run';
import { DataStore } from '../../services/datastore/datastore.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/datastore/datastore.service";
var FinishedBatchesResolverService = /** @class */ (function () {
    function FinishedBatchesResolverService(datastore) {
        this.datastore = datastore;
    }
    FinishedBatchesResolverService.prototype.resolve = function (route, state) {
        return this.datastore.findAll(ProductionRun, { state: 'finished' }).pipe(map(function (productionRunsResult) { return productionRunsResult.getModels(); }));
    };
    FinishedBatchesResolverService.ngInjectableDef = i0.defineInjectable({ factory: function FinishedBatchesResolverService_Factory() { return new FinishedBatchesResolverService(i0.inject(i1.DataStore)); }, token: FinishedBatchesResolverService, providedIn: "root" });
    return FinishedBatchesResolverService;
}());
export { FinishedBatchesResolverService };
