import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { CatalogResourceService } from 'src/app/services/catalog/catalog-resource.service';
import { ExpiringDays } from 'src/app/models/catalog-entry';
import { PackageDescriptionModel } from 'src/app/models/package-description';
import { FormBuilder, FormArray, Validators, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'reg-create-catalog-entry',
    templateUrl: './create-catalog-entry.component.html',
    styleUrls: ['./create-catalog-entry.component.scss']
})
export class CreateCatalogEntryComponent implements OnInit {

    @ViewChild('manufacturerName') focusInput: ElementRef;
    @ViewChild('stepper') stepper: ElementRef;

    packageType: PackageDescriptionModel;
    manufacturer: FormGroup;
    drugInformation: FormGroup;
    packageInformation: FormGroup;
    identifiers: FormGroup;
    dismiss: string;

    constructor(
        private router: Router,
        public route: ActivatedRoute,
        private toast: MatSnackBar,
        private catalogService: CatalogResourceService,
        private formBuilder: FormBuilder,
        private translate: TranslateService
    ) {
        this.translate.get([
            'dismiss'
          ]).subscribe(translation => {
            this.dismiss = translation['dismiss'];
          });

    }

    ngOnInit() {
        this.manufacturer = this.formBuilder.group({
            manufacturerName: ['', Validators.required]
          });
          this.drugInformation = this.formBuilder.group({
            drugName: ['', Validators.required],
            drugStrength: ['', Validators.required],
            drugStrengthUom: ['', Validators.required]
          });
          this.packageInformation = this.formBuilder.group({
            packageSize: ['', Validators.required],
            packageSizeUom: ['', Validators.required],
            packageType: ['', Validators.required],
            expiringFridge: [''],
            expiringMultidose: ['']
          });
          this.identifiers = this.formBuilder.group({
            unitOfUseNdc: ['', Validators.required],
            unitOfUseGtin: [''],
            unitsOfSale: this.formBuilder.array([this.formBuilder.group(
              {ndc: '', gtin: '', packageCount: ''}
            )])
          });
        setTimeout(() => this.focusInput.nativeElement.focus(), 100);
    }

    onConfirmation() {
      return this.stepper['selectedIndex'] === 4;
    }

    submit() {
        const newCatalogEntry = {
            ndc: this.identifiers.get('unitOfUseNdc').value,
            gtin: this.identifiers.get('unitOfUseGtin').value,
            itemName: this.drugInformation.get('drugName').value,
            manufacturerName: this.manufacturer.get('manufacturerName').value,
            packageDescriptionId: this.packageInformation.get('packageType').value.id,
            itemStrength: {
                scalar: parseFloat(this.drugInformation.get('drugStrength').value),
                uom: this.drugInformation.get('drugStrengthUom').value
            },
            packageSize: {
                scalar: parseFloat(this.packageInformation.get('packageSize').value),
                uom: this.packageInformation.get('packageSizeUom').value
            },
            unit_of_sales: this.unitsOfSale.controls.map(control => control.value),
        };

        const expiringDays: ExpiringDays = {};

        if (this.packageInformation.get('expiringFridge').value) {
            expiringDays.fridge = parseFloat(this.packageInformation.get('expiringFridge').value);
            }
        if (this.packageInformation.get('expiringMultidose').value) {
            expiringDays.multidose = parseFloat(this.packageInformation.get('expiringMultidose').value);
        }

        if (this.packageInformation.get('expiringFridge').value || this.packageInformation.get('expiringMultidose').value) {
            newCatalogEntry['expiringDays'] = expiringDays;
        }

        this.catalogService.createCatalogEntry(newCatalogEntry).subscribe((newCatalogCreated) => {
           this.router.navigate(['catalog'], { queryParams: { search: newCatalogCreated.data.attributes['ndc-full'] } });
           this.translate.get('create_catalog.created_successfully', {itemName: newCatalogCreated.data.attributes['item-name']}).subscribe(translation => {
            this.toast.open(translation, this.dismiss, {
                duration: 3000,
                panelClass: 'success-snackbar',
            });
           });
        });
      }

      get unitsOfSale() {
        return this.identifiers.get('unitsOfSale') as FormArray;
      }

      unitsOfSaleValues() {
        return this.unitsOfSale.controls.map(control => control.value);
      }

      addUnitOfSale() {
        this.unitsOfSale.push(this.formBuilder.group({ndc: '', gtin: '', packageCount: ''}));
        console.log(this.unitsOfSale.controls.map(control => control.value));
      }

      deleteUnitOfSale(index) {
        this.unitsOfSale.removeAt(index);
        console.log(this.unitsOfSale.controls.map(control => control.value));
      }

      cancel() {
        this.router.navigate(['catalog']);
      }
}
