import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort, MatDialog } from '@angular/material';
import { TransitionBatchService } from '../../services/transition-batch/transition-batch.service';
import { BatchReportComponent } from '../../dialogs/batch-report/batch-report.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'reg-batches-table',
    templateUrl: './batches-table.component.html',
    styleUrls: ['./batches-table.component.scss']
})
export class BatchesTableComponent implements OnInit, OnChanges {

    @Input() tableName: string;
    @Input() displayedColumns: string[];
    @Input() batchReport: boolean;
    @Input() data: Array<any>;
    @Input() defaultSort: string | null;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    dataSource: MatTableDataSource<any>;
    pageName: string;

  constructor(
      private _transitionBatch: TransitionBatchService,
      private dialog: MatDialog,
      private router: Router,
      /* tslint:disable:no-unused-variable */
      protected authService: AuthService
    ) { }

  ngOnInit() {
      this.dataSource = new MatTableDataSource(this.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.pageName = this.router.url.replace('/batches/', '');
      if (this.defaultSort) {
          this.sort.sort({ id: this.defaultSort, start: 'asc', disableClear: false });
      }
  }

  ngOnChanges(changes) {
    if (changes.data) {
          this.dataSource = new MatTableDataSource(this.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          if (this.dataSource.sort.active === 'manufacturerExpiration') {
            this.sort.sortChange.emit();
          }
    }
  }

  onSortData(sort) {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'manufacturerExpiration': {
          const date = new Date(item.expirationFull);
          return date;
        }
        default: {
          return item[property];
        }
      }
    };
  }

  openBatchReport(batchReport, batch) {
      if (batchReport) {
          const batchReportDialog = this.dialog.open(BatchReportComponent, {
              width: '800px',
              data: {
                  batch: batch
              }
          });
        batchReportDialog.afterClosed().subscribe(result => {
              if (result) {
                // when set up printing, will have something here
              } else {

              }
          });
      }
  }
}
