import { HttpClient, HttpParams } from '@angular/common/http';
import { urls } from '../../../urls';
import { ProductionRun } from 'src/app/models/production-run';
import { DataStore } from '../datastore/datastore.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../datastore/datastore.service";
var ProductionRunsResourceService = /** @class */ (function () {
    function ProductionRunsResourceService(http, datastore) {
        this.http = http;
        this.datastore = datastore;
        this.PRODUCTION_RUNS_URL = urls.API_URL + "/production-runs";
    }
    ProductionRunsResourceService.prototype.finish = function (id, test) {
        return this.http.post(this.PRODUCTION_RUNS_URL + "/" + id + "/finish?test=" + test, null).pipe(
        //  tap(result => console.log(result))
        );
    };
    ProductionRunsResourceService.prototype.approve = function (id) {
        return this.http.post(this.PRODUCTION_RUNS_URL + "/" + id + "/approve", null).pipe(
        //  tap(result => console.log(result))
        );
    };
    ProductionRunsResourceService.prototype.reject = function (id) {
        return this.http.post(this.PRODUCTION_RUNS_URL + "/" + id + "/reject", null);
    };
    ProductionRunsResourceService.prototype.downloadEpcs = function (id) {
        var options = { responseType: 'text' };
        return this.http.get(this.PRODUCTION_RUNS_URL + "/" + id + "/tags.csv", options).pipe(
        // tap(result => console.log(result))
        );
    };
    ProductionRunsResourceService.prototype.downloadPdf = function (id) {
        var options = { responseType: 'text' };
        return this.http.get(this.PRODUCTION_RUNS_URL + "/" + id + ".pdf", options).pipe();
    };
    ProductionRunsResourceService.prototype.getBatches = function (dateRange, startDate, endDate, test) {
        var parameters = { state: 'approved' };
        if (dateRange && startDate) {
            parameters.time_filter_event = dateRange;
        }
        if (startDate) {
            parameters.time_filter_start = startDate;
        }
        if (endDate) {
            parameters.time_filter_end = endDate;
        }
        if (test || test === false) {
            parameters.test = test;
        }
        return this.datastore.findAll(ProductionRun, parameters).pipe(map(function (batchesResult) { return batchesResult.getModels(); }));
    };
    ProductionRunsResourceService.prototype.downloadBatchActivity = function (downloadFilters) {
        var params = new HttpParams();
        params = params.append('state', 'approved');
        if (downloadFilters.timeFrame && downloadFilters.startDate) {
            params.append('time_filter_event', downloadFilters.timeFrame);
        }
        if (downloadFilters.startDate) {
            params = params.append('time_filter_start', downloadFilters.startDate);
        }
        if (downloadFilters.endDateTime) {
            params = params.append('time_filter_end', downloadFilters.endDateTime);
        }
        if (downloadFilters.test || downloadFilters.test === false) {
            params = params.append('test', downloadFilters.test);
        }
        var options = { responseType: 'text', params: params };
        return this.http.get(this.PRODUCTION_RUNS_URL + ".csv", options).pipe(
        // tap(result => console.log(result))
        );
    };
    ProductionRunsResourceService.ngInjectableDef = i0.defineInjectable({ factory: function ProductionRunsResourceService_Factory() { return new ProductionRunsResourceService(i0.inject(i1.HttpClient), i0.inject(i2.DataStore)); }, token: ProductionRunsResourceService, providedIn: "root" });
    return ProductionRunsResourceService;
}());
export { ProductionRunsResourceService };
