import { JsonApiModelConfig, JsonApiModel, Attribute, BelongsTo } from 'angular2-jsonapi';
import { CatalogEntry } from './catalog-entry';

@JsonApiModelConfig({
    type: 'unit-of-sales'
})
export class UnitOfSale extends JsonApiModel {

    @Attribute()
    gtin: string;

    @Attribute({ serializedName: 'ndc-digits' })
    ndcDigits: string;

    @Attribute({ serializedName: 'ndc-full' })
    ndcFull: string;

    @Attribute({ serializedName: 'package-count' })
    packageCount: string;

    @BelongsTo({key: 'catalog-entry'})
    catalogEntry: CatalogEntry;
}
