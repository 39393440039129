<mat-card class="mat-elevation-z4">
  <mat-card-title data-e2e="create.user"> {{ 'users.create_user' | translate }} </mat-card-title>
  <div fxLayout="row" fxLayoutAlign="space-around">
    <mat-form-field fxFlex="40" class="input-margin">
      <input data-e2e="first.name" [(ngModel)]="user.firstName" matInput required [placeholder]="'users.first_name' | translate">
    </mat-form-field>
    <mat-form-field fxFlex="40">
      <input data-e2e="last.name" [(ngModel)]="user.lastName" matInput required [placeholder]="'users.last_name' | translate">
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-around">
    <mat-form-field fxFlex="91">
      <input data-e2e="email" [(ngModel)]="user.email" matInput required [placeholder]="'users.email' | translate">
    </mat-form-field>
  </div>
  <div class="checkboxes" fxLayout="row" fxLayoutAlign="space-around">
    <mat-checkbox *ngFor="let role of roles" [(ngModel)]="roleCheckBoxes[role.name]"> {{ role.displayName }} </mat-checkbox>
  </div>

  <div class="buttons" fxLayout="row">
    <button fxFlexOffset="60" mat-stroked-button (click)="cancel()"> {{ 'cancel' | translate }} </button>
    <button fxFlexOffset="5" mat-raised-button color="accent" (click)="create()"> {{ 'users.create' | translate }} </button>
  </div>
</mat-card>
