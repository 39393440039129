import { HttpClient } from '@angular/common/http';
import { urls } from '../../../urls';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var VersionService = /** @class */ (function () {
    function VersionService(http) {
        this.http = http;
    }
    VersionService.prototype.getBackEndVersion = function () {
        var options = {
            responseType: 'text',
        };
        return this.http.get(urls.API_BASE_URL + "/version.txt", options);
    };
    VersionService.ngInjectableDef = i0.defineInjectable({ factory: function VersionService_Factory() { return new VersionService(i0.inject(i1.HttpClient)); }, token: VersionService, providedIn: "root" });
    return VersionService;
}());
export { VersionService };
