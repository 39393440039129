<mat-card class="mat-elevation-z4">
    <div fxLayout="row" fxLayoutAlign="space-around center">
        <mat-card-title class="filters" fxFlexAlign="start"> {{ 'reports.filters' | translate }} </mat-card-title>
    
        <div fxFlex="15" fxFlexOffset="3" fxLayout="column" fxLayoutAlign="space-between">
            <mat-form-field >
                <mat-label [innerText]="'reports.start_date' | translate"></mat-label>
                <input data-e2e="start.date" [required]="this.endDate || this.startDate" matInput [matDatepicker]="start" [(ngModel)]="startDate" (dateChange)="startDateChanged($event)">
                <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
                <mat-datepicker #start></mat-datepicker>
            </mat-form-field>
            
            
            <mat-form-field>
                <mat-label [innerText]="'reports.end_date' | translate"></mat-label>
                <input data-e2e="end.date" [required]="this.endDate || this.startDate" matInput [matDatepicker]="end" [(ngModel)]="endDate" (dateChange)="endDateChanged($event)">
                <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
                <mat-datepicker #end></mat-datepicker>
            </mat-form-field>
        </div>

        <mat-form-field fxFlex="15" fxFlexOffset="3">
            <mat-select data-e2e="activity.type" [(ngModel)]="activityType" [placeholder]="'user_activity.activity_type' | translate">
                <mat-option *ngFor="let type of activityTypes" [value]="type">
                    {{translations[type]}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex="20" fxFlexOffset="3">
            <mat-select data-e2e="email" [(ngModel)]="email" [placeholder]="'user_activity.email' | translate">
                <mat-option data-e2e="email" *ngFor="let email of emails" [value]="email">
                    {{email}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <a (click)="clearFilters()"> {{ 'reports.clear_filters' | translate }} </a>
        <div fxLayout="column" fxLayoutAlign="space-between">
            <button class="run-report" [disabled]="isDisabled()" mat-raised-button (click)="filterActivities()" color="accent"> {{ 'reports.run_report' | translate }} </button>
            <button class="download" fxFlexOffset="10" mat-stroked-button (click)="download()"> {{ 'reports.download' | translate }} </button>
        </div>
    </div>
  </mat-card>
  
  <mat-card class="mat-elevation-z4">
    <mat-card-title> {{ 'user_activity.title' | translate }} </mat-card-title>
    <table mat-table [dataSource]="dataSource" matSort fxFill (matSortChange)="onSortData($event)">
        <ng-container matColumnDef="dateTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'user_activity.date_time' | translate }} </th>
            <td mat-cell *matCellDef="let row"> {{row.dateTime}} </td>
        </ng-container>

        <ng-container matColumnDef="activityType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'user_activity.activity_type' | translate }} </th>
            <td mat-cell *matCellDef="let row"> {{translations[row.activityType] || row.activityType}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th class="last-column" mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'user_activity.email' | translate }} </th>
            <td mat-cell *matCellDef="let row"> {{row.email}} </td>
        </ng-container>

        <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'user_activity.first_name' | translate }} </th>
            <td mat-cell *matCellDef="let row"> {{row.firstName}} </td>
        </ng-container>

        <ng-container matColumnDef="lastName">
            <th class="last-column" mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'user_activity.last_name' | translate }} </th>
            <td mat-cell *matCellDef="let row"> {{row.lastName}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <ng-container *ngIf="!dataSource.data.length">
        <div class="no-users" fxLayout="row" fxLayoutAlign="center center">
            <p> {{ 'reports.select_filters' | translate }} </p>
        </div>
    </ng-container>
    
    <mat-paginator [pageSize]="100" [pageSizeOptions]="[10, 20, 50, 100]"  showFirstLastButtons></mat-paginator>
  </mat-card>
