import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { Subscription, of } from 'rxjs';
import { delay, tap, filter, map, mergeMap, concatMap } from 'rxjs/operators';
import { CatalogEntry } from '../../models/catalog-entry';
import { CatalogEntryDetailsComponent } from 'src/app/dialogs/catalog-entry-details/catalog-entry-details.component';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
    selector: 'reg-catalog-entry',
    templateUrl: './catalog-entry-index.component.html',
    styleUrls: ['./catalog-entry-index.component.scss']
})

export class CatalogEntryIndexComponent implements OnInit, OnDestroy {

    displayedColumns: string[] = ['ndcFull', 'unitOfSales', 'itemName', 'manufacturerName', 'itemStrength', 'packageSize',
        'expiringFridgeDays', 'expiringMultidoseDays'];
    dataSource: MatTableDataSource<CatalogEntry>;

    @ViewChild('queryInput') queryInput: ElementRef;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    routeDataSub: Subscription;
    routeParamSub: Subscription;
    queryValue = '';
    rangeLabelIntl: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        /* tslint:disable:no-unused-variable */
        public authService: AuthService
    ) {
    }

    ngOnInit() {
        this.routeDataSub = this.route.data.subscribe(({ catalogs }) => {
            // adds unit of sale identifiers to top level of catalog object, for filtering
            catalogs.forEach((catalog: CatalogEntry) => {
                const unitsOfSale = {};
                let unitOfSaleId = 0;
                catalog.unitOfSales.forEach(unit => {
                    unitsOfSale[unitOfSaleId++] = unit.ndcFull;
                    unitsOfSale[unitOfSaleId++] = unit.gtin;
                });
                return catalog = Object.assign(catalog, unitsOfSale);
            });

            this.dataSource = new MatTableDataSource(catalogs);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.sort.sort({id: 'ndcFull', start: 'asc', disableClear: false});
        });

        this.routeParamSub = this.route.queryParams.pipe(
            filter(({ search }) => search),
            map(({ search }) => search),
            delay(250),
            tap(() => this.queryInput.nativeElement.focus()),
            delay(300),
            mergeMap(query => query.split('')),
            concatMap(charArray => of(charArray)
                .pipe(
                    tap(char => this.queryValue += char),
                    delay(50),
                    tap(() => this.applyFilter(this.queryValue))
                )
            )
        ).subscribe();
    }

    ngOnDestroy() {
        this.routeDataSub.unsubscribe();
        this.routeParamSub.unsubscribe();
    }

    details(item: CatalogEntry) {
        if (item.unitOfSales.length) {
            // ie is dumb and doesn't size correctly
            // so we have to calculate the height ourselves
            const height = 200 + (Math.min(item.unitOfSales.length, 6) * 50);
            this.dialog.open(CatalogEntryDetailsComponent, {
                width: '800px',
                height: `${height}px`,
                data: {
                    item: item
                }
            });
       }
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    clearSeach() {
        this.queryValue = '';
        this.applyFilter(this.queryValue);
    }

    createCatalogEntry() {
        this.router.navigate(['catalog/create-entry']);
    }

    onSortData(sort) {
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'ndcFull': {
                    // coerce to string
                    return item[property] + '';
                }
                default: {
                    return item[property];
                }
            }
        };
    }
}
