/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reports.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "@angular/flex-layout/flex";
import * as i5 from "@angular/flex-layout/core";
import * as i6 from "@ngx-translate/core";
import * as i7 from "./reports.component";
import * as i8 from "@angular/router";
var styles_ReportsComponent = [i0.styles];
var RenderType_ReportsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportsComponent, data: {} });
export { RenderType_ReportsComponent as RenderType_ReportsComponent };
export function View_ReportsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "mat-card", [["class", "mat-card"], ["fxLayout", "column"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [], null, null), i1.ɵdid(2, 671744, null, 0, i4.DefaultLayoutDirective, [i1.ElementRef, i5.StyleUtils, [2, i4.LayoutStyleBuilder], i5.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 3, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i3.MatCardTitle, [], null, null), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateTo(["batch-activity"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, 0, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateTo(["alert-activity"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(11, null, [" ", " "])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, 0, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateTo(["user-activity"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("titles.reports")); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("reports.batch_activity")); _ck(_v, 8, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("reports.alert_activity")); _ck(_v, 11, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("reports.user_activity")); _ck(_v, 14, 0, currVal_4); }); }
export function View_ReportsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "reg-reports", [], null, null, null, View_ReportsComponent_0, RenderType_ReportsComponent)), i1.ɵdid(1, 49152, null, 0, i7.ReportsComponent, [i8.Router], null, null)], null, null); }
var ReportsComponentNgFactory = i1.ɵccf("reg-reports", i7.ReportsComponent, View_ReportsComponent_Host_0, {}, {}, []);
export { ReportsComponentNgFactory as ReportsComponentNgFactory };
