import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { urls } from '../../../urls';
import { Observable } from 'rxjs';
import { DataStore } from '../datastore/datastore.service';
import { Alert } from 'src/app/models/alert';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AlertsResourceService {
  ALERTS_URL = `${urls.API_URL}/alerts`;

  constructor(
    private http: HttpClient,
    private datastore: DataStore
  ) { }

  public update(id, resolution): Observable<any> {
    return this.http.put<any>(`${this.ALERTS_URL}/${id}`, { resolution }).pipe(
      // tap(result => console.log(result))
    );
  }

  public getAlerts(dateRange: string, startDate: string, endDate: string, alertType: string): Observable<any> {
    const parameters: any = {};
    if (dateRange && startDate) {
        parameters.time_filter_event = dateRange;
    }

    if (startDate) {
        parameters.time_filter_start = startDate;
    }

    if (endDate) {
        parameters.time_filter_end = endDate;
    }

    if (alertType) {
        parameters.type = alertType;
    }

    return this.datastore.findAll(Alert, parameters).pipe(
        map(alertsResult => alertsResult.getModels())
    );
  }

  public downloadAlertActivity(downloadFilters: any): Observable<any> {
    let params = new HttpParams();
    if (downloadFilters.timeFrame && downloadFilters.startDate) {
        params = params.append('time_filter_event', downloadFilters.timeFrame);
    }

    if (downloadFilters.startDate) {
        params = params.append('time_filter_start', downloadFilters.startDate);
    }

    if (downloadFilters.endDateTime) {
        params = params.append('time_filter_end', downloadFilters.endDateTime);
    }

    if (downloadFilters.type) {
        params = params.append('type', downloadFilters.type);
    }

    const options = {responseType: 'text' as 'json', params: params};

    return this.http.get<any>(`${this.ALERTS_URL}.csv`, options ).pipe(
        // tap(result => console.log(result))
    );
  }
}
