import { AccountModel } from './account';
import { PackageDescriptionModel, PackageDescription } from './package-description';

export interface ItemStrength {
    scalar: number;
    uom: string;
}

export interface PackageSize {
    scalar: number;
    uom: string;
}

export interface ExpiringDays {
    fridge?: number;
    multidose?: number;
}

export interface CatalogEntryModel {
    id?: number;
    ndc?: string;
    gtin?: string;
    ndcDigits?: string;
    ndcFull?: string;
    itemName: string;
    manufacturerName: string;
    packageDescriptionId: number;
    itemStrength?: ItemStrength;
    packageSize?: PackageSize;
    expiringDays?: ExpiringDays;
    Account?: AccountModel;
    PackageDescription?: PackageDescriptionModel;
}


import { JsonApiModelConfig, JsonApiModel, Attribute, HasMany, BelongsTo } from 'angular2-jsonapi';
import { UnitOfSale } from './unit-of-sale';

@JsonApiModelConfig({
    type: 'catalog-entries'
})
export class CatalogEntry extends JsonApiModel {

    @Attribute({ serializedName: 'account-id' })
    accountId: number;

    @Attribute({ serializedName: 'expiring-fridge-days' })
    expiringFridgeDays: number;

    @Attribute({ serializedName: 'expiring-multidose-days' })
    expiringMultidoseDays: number;

    @Attribute({ serializedName: 'item-name' })
    itemName: string;

    @Attribute({ serializedName: 'item-strength-scalar' })
    itemStrengthScalar: string;

    @Attribute({ serializedName: 'item-strength-uom' })
    itemStrengthUom: string;

    @Attribute({ serializedName: 'manufacturer-name' })
    manufacturerName: string;

    @Attribute({ serializedName: 'ndc-digits' })
    ndcDigits: string;

    @Attribute({ serializedName: 'ndc-full' })
    ndcFull: string;

    @Attribute({ serializedName: 'package-size-scalar' })
    packageSizeScalar: string;

    @Attribute({ serializedName: 'package-size-uom' })
    packageSizeUom: string;

    @BelongsTo({key: 'package-description'})
    packageDescription: PackageDescription;

    @HasMany({key: 'unit-of-sales'})
    unitOfSales: UnitOfSale[];

    get drugInformation() {
      return `${this.itemName} ${this.itemStrengthScalar} ${this.itemStrengthUom} -
        ${this.packageSizeScalar} ${this.packageSizeUom} ${this.packageDescription.name}`;
    }

    get packageSize() {
        return `${this.packageSizeScalar} ${this.packageSizeUom}`;
    }

    get itemStrength() {
        return `${this.itemStrengthScalar} ${this.itemStrengthUom}`;
    }
}
