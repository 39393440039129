import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { merge, interval, Observable } from 'rxjs';
import { map, concatMap } from 'rxjs/operators';
import * as moment from 'moment';

import { TransitionBatchService } from '../../services/transition-batch/transition-batch.service';
import { AuthService } from '../../services/auth/auth.service';
import { AlertsResourceService } from 'src/app/services/alerts/alerts-resource.service';
import { LineAlertComponent } from 'src/app/dialogs/line-alert/line-alert.component';
import { DataStore } from 'src/app/services/datastore/datastore.service';
import { Tunnel } from 'src/app/models/tunnel';

interface TunnelsData {
    id: string;
    drug: string;
    lotNumber: string;
    manufacturerExpiration: string;
    line: string;
    lastScan: string;
    unitUseNdc: string;
    unitSaleNdc: string;
    activeAlerts: any;
    totalCartons: number;
    uniqueValidEpcs: number;
    uniqueInvalidEpcs: number;
    uniqueEpcs: number;
}

@Component({
    selector: 'reg-lines',
    templateUrl: './lines.component.html',
    styleUrls: ['./lines.component.scss']
})
export class LinesComponent implements OnInit {

    displayedColumns: string[] = ['lot', 'expiration', 'ndc', 'totalCartons', 'uniqueValidEpcs', 'uniqueInvalidEpcs', 'totalEpcs'];
    alerts: any = {};
    successMessage: string;
    dismiss: string;

    routeData$: Observable<Tunnel[]>;
    pollingData$: Observable<Tunnel[]>;
    tunnelsData$: Observable<TunnelsData[]>;
    POLLING_INTERVAL = 1000;

    constructor(
        private authService: AuthService,
        private dialog: MatDialog,
        public route: ActivatedRoute,
        private toast: MatSnackBar,
        private translate: TranslateService,
        private alertsService: AlertsResourceService,
        private _transitionBatch: TransitionBatchService,
        private dataStore: DataStore
    ) {
        this.translate.get([
            'lines.alert_dialog.success_message',
            'lines.alerts.tunnel_offline',
            'lines.alerts.invalid_ndc',
            'lines.alerts.invalid_epc',
            'lines.alerts.rfid_reader',
            'lines.alerts.barcode_reader',
            'lines.alerts.empty_carton',
            'lines.alerts.no_barcode_read',
            'dismiss'
        ]).subscribe(translation => {
            this.alerts['tunnel_offline'] = translation['lines.alerts.tunnel_offline'];
            this.alerts['invalid_ndc'] = translation['lines.alerts.invalid_ndc'];
            this.alerts['invalid_epc'] = translation['lines.alerts.invalid_epc'];
            this.alerts['rfid_reader'] = translation['lines.alerts.rfid_reader'];
            this.alerts['barcode_reader'] = translation['lines.alerts.barcode_reader'];
            this.alerts['empty_carton'] = translation['lines.alerts.empty_carton'];
            this.alerts['no_barcode_read'] = translation['lines.alerts.no_barcode_read'];
            this.successMessage = translation['lines.alert_dialog.success_message'];
            this.dismiss = translation['dismiss'];
        });
    }

    ngOnInit() {
        this.routeData$ = this.route.data.pipe(
            map((routeData: any) => routeData.tunnels),
        );

        this.pollingData$ = interval(this.POLLING_INTERVAL).pipe(
            concatMap(() => this.dataStore.findAll(Tunnel)),
            map(tunnelsResult => tunnelsResult.getModels()),
        );

        this.tunnelsData$ = merge(
            this.routeData$,
            this.pollingData$
        ).pipe(
            map((tunnelsResult: Tunnel[]) => this.mapTunnelsData(tunnelsResult)),
            // map((tableData: TunnelsData[]) => new MatTableDataSource(tableData))
        );
    }

    loggedIn(): boolean {
        return this.authService.isLoggedIn() && !this.authService.isInKioskMode();
    }


    canFinishBatches(): boolean {
        return this.authService.hasRole('line_operator') && this.loggedIn();
    }

    canResolveAlerts(): boolean {
        return this.loggedIn() &&
            (this.authService.hasRole('line_operator') || this.authService.hasRole('batch_approver'));
    }

    mapTunnelsData(tunnelsResult: Tunnel[]): TunnelsData[] {
        return tunnelsResult.map((tunnel) => {
            if (tunnel.activeProductionRun) {
                const productionRun = tunnel.activeProductionRun;
                const accountType = productionRun.account ? productionRun.account.accountType : null;
                const accountName = accountType ? productionRun.account.accountType.name : null;
                const catalogEntry = productionRun.unitOfSale.catalogEntry;
                const unitOfSale = productionRun.unitOfSale;
                const dataSource = [{
                      'ndc': unitOfSale.ndcFull,
                      'lot': productionRun.lotNumber,
                      'expiration': accountName === 'compounder' ? moment(productionRun.expirationManufacturer).format('DDMMMYYYY').toUpperCase() :
                      moment(productionRun.expirationManufacturer).format('MMMYYYY').toUpperCase(),
                      'totalCartons': productionRun.totalCartons,
                      'uniqueValidEpcs': productionRun.totalUniqueValidEpcs,
                      'uniqueInvalidEpcs': productionRun.totalUniqueInvalidEpcs,
                      'uniqueEpcs': productionRun.totalUniqueValidEpcs + productionRun.totalUniqueInvalidEpcs
                  }];
                const drug = productionRun.drugInformation;

                const batchInfo = {
                    id: productionRun.id,
                    line: productionRun.tunnelName,
                    drug: drug,
                    unitSaleNdc: productionRun.unitOfSale.ndcFull,
                    unitUseNdc: catalogEntry.ndcFull,
                    lotNumber: productionRun.lotNumber,
                    manufacturerExpiration: accountName === 'compounder' ? moment(productionRun.expirationManufacturer).format('DDMMMYYYY').toUpperCase() :
                    moment(productionRun.expirationManufacturer).format('MMMYYYY').toUpperCase(),
                    totalCartons: productionRun.totalCartons,
                    uniqueValidEpcs: productionRun.totalUniqueValidEpcs,
                    uniqueInvalidEpcs: productionRun.totalUniqueInvalidEpcs,
                    uniqueEpcs: productionRun.totalUniqueValidEpcs + productionRun.totalUniqueInvalidEpcs
                };
                return Object.assign(tunnel, { dataSource: new MatTableDataSource(dataSource), drug, batchInfo });
            } else {
                return tunnel;
            }
        }).map((tunnel: any) => {
            if (tunnel.activeAlerts && tunnel.activeAlerts.length) {
                return Object.assign(tunnel, { alerts: tunnel.activeAlerts, alert: tunnel.activeAlerts[0].alertType });
            } else {
                return tunnel;
            }
        });
    }

    alertMessage(alert: string) {
        return this.alerts[alert];
    }

    updateTunnelAlert(tunnel: any) {
        tunnel.alerts.shift();
        if (tunnel.alerts.length) {
            tunnel.alert = tunnel.alerts[0].alertType;
        } else {
            tunnel.alert = null;
        }

    }

    acceptError(tunnel: any, alertMessage: string, alertId: string) {
        const alertDialog = this.dialog.open(LineAlertComponent, {
            width: '400px',
            data: {
                name: tunnel.name,
                alertMessage,
                alertId: alertId
            }
        });
        alertDialog.afterClosed().subscribe(result => {
            if (result) {
                this.updateTunnelAlert(tunnel);
                this.alertsService.update(result.alertId, result.acceptanceReason).subscribe((data) => {
                    this.toast.open(this.successMessage, this.dismiss, {
                        duration: 8000,
                        panelClass: 'success-snackbar'
                    });
                });
            }
        });
    }
}
