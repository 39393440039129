import * as tslib_1 from "tslib";
import { JsonApiModelConfig, JsonApiModel, Attribute } from 'angular2-jsonapi';
var PasswordSettings = /** @class */ (function (_super) {
    tslib_1.__extends(PasswordSettings, _super);
    function PasswordSettings() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        Attribute({ serializedName: 'contains-digit' }),
        tslib_1.__metadata("design:type", Boolean)
    ], PasswordSettings.prototype, "containsDigit", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'contains-lowercase' }),
        tslib_1.__metadata("design:type", Boolean)
    ], PasswordSettings.prototype, "containsLowercase", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'contains-uppercase' }),
        tslib_1.__metadata("design:type", Boolean)
    ], PasswordSettings.prototype, "containsUppercase", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'contains-special' }),
        tslib_1.__metadata("design:type", Boolean)
    ], PasswordSettings.prototype, "containsSpecial", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'contains-blank' }),
        tslib_1.__metadata("design:type", Boolean)
    ], PasswordSettings.prototype, "containsBlank", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'min-length' }),
        tslib_1.__metadata("design:type", Number)
    ], PasswordSettings.prototype, "minLength", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'max-length' }),
        tslib_1.__metadata("design:type", Number)
    ], PasswordSettings.prototype, "maxLength", void 0);
    PasswordSettings = tslib_1.__decorate([
        JsonApiModelConfig({
            type: 'users/password-settings'
        })
    ], PasswordSettings);
    return PasswordSettings;
}(JsonApiModel));
export { PasswordSettings };
