import * as Raven from 'raven-js';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { RegistryMissingTranslationHandler } from './util/registry-missing-translation-handler';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { JsonApiModule } from 'angular2-jsonapi';

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { AppRoutingModule } from './app-routing.module';
import { AppMaterialModule } from './app-material.module';
import { AppComponent } from './app.component';

import { environment } from '../environments/environment';
import { httpInterceptorProviders } from './interceptors';
import { DatadogService } from './services/vendor/datadog.service';

import { LoginPageComponent } from './pages/login-page/login-page.component';
import { CatalogEntryIndexComponent } from './pages/catalog-entry-index/catalog-entry-index.component';
import { CreateCatalogEntryComponent } from './pages/create-catalog-entry/create-catalog-entry.component';

import { PasswordResetDialogComponent } from './dialogs/password-reset/password-reset.component';
import { BatchReportComponent } from './dialogs/batch-report/batch-report.component';
import { TransitionBatchComponent } from './dialogs/transition-batch/transition-batch.component';
import { LineAlertComponent } from './dialogs/line-alert/line-alert.component';

import { LinesComponent } from './pages/lines/lines.component';
import { BatchesComponent } from './pages/batches/batches.component';
import { FinishComponent } from './pages/finish/finish.component';
import { ApproveComponent } from './pages/approve/approve.component';
import { ArchiveComponent } from './pages/archive/archive.component';
import { BatchesTableComponent } from './components/batches-table/batches-table.component';
import { CatalogEntryDetailsComponent } from './dialogs/catalog-entry-details/catalog-entry-details.component';
import { InvalidPathComponent } from './pages/invalid-path/invalid-path.component';
import { PermissionDeniedComponent } from './pages/permission-denied/permission-denied.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { ExpiredLinkComponent } from './pages/expired-link/expired-link.component';
import { UsersComponent } from './pages/users/users.component';
import { CreateUserComponent } from './pages/create-user/create-user.component';
import { UpdateUserComponent } from './dialogs/update-user/update-user.component';
import { SignupComponent } from './pages/signup/signup.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { BatchActivityComponent } from './pages/batch-activity/batch-activity.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { AlertActivityComponent } from './pages/alert-activity/alert-activity.component';
import { UserActivityComponent } from './pages/user-activity/user-activity.component';
import { KioskConfigureComponent } from './dialogs/kiosk-configure/kiosk-configure.component';
import { SelectAccountComponent } from './pages/select-account/select-account.component';
import { RejectBatchDialogComponent } from './dialogs/reject-batch/reject-batch.component';

export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    if (environment.production) {
      Raven.captureException(err);
    } else {
      console.error(err);
    }
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const REGISTRY_DATE_FORMAT = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    CatalogEntryIndexComponent,
    CreateCatalogEntryComponent,
    PasswordResetDialogComponent,
    BatchReportComponent,
    RejectBatchDialogComponent,
    LineAlertComponent,
    TransitionBatchComponent,
    LinesComponent,
    FinishComponent,
    ApproveComponent,
    ArchiveComponent,
    BatchesComponent,
    CatalogEntryDetailsComponent,
    BatchesTableComponent,
    CatalogEntryDetailsComponent,
    InvalidPathComponent,
    PermissionDeniedComponent,
    ForgotPasswordComponent,
    PasswordResetComponent,
    ExpiredLinkComponent,
    UsersComponent,
    CreateUserComponent,
    UpdateUserComponent,
    SignupComponent,
    SetPasswordComponent,
    BatchActivityComponent,
    ReportsComponent,
    ReportsComponent,
    AlertActivityComponent,
    UserActivityComponent,
    KioskConfigureComponent,
    SelectAccountComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    FlexLayoutModule,
    FormsModule,
    JsonApiModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: RegistryMissingTranslationHandler},
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  entryComponents: [
      LoginPageComponent,
      PasswordResetDialogComponent,
      CatalogEntryDetailsComponent,
      BatchReportComponent,
      RejectBatchDialogComponent,
      TransitionBatchComponent,
      LineAlertComponent,
      UsersComponent,
      CreateUserComponent,
      UpdateUserComponent,
      BatchActivityComponent,
      ReportsComponent,
      KioskConfigureComponent,
      SelectAccountComponent,
  ],
  providers: [
    httpInterceptorProviders,
    DatadogService,
    { provide: ErrorHandler, useClass: RavenErrorHandler },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: REGISTRY_DATE_FORMAT},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
