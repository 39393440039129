import { OnInit } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { UpdateUserComponent } from 'src/app/dialogs/update-user/update-user.component';
var UsersComponent = /** @class */ (function () {
    function UsersComponent(router, dialog, route, authService) {
        this.router = router;
        this.dialog = dialog;
        this.route = route;
        this.authService = authService;
        this.displayedColumns = ['email', 'firstName', 'lastName', 'roles'];
    }
    UsersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.routeDataSub = this.route.data.subscribe(function (_a) {
            var users = _a.users, roles = _a.roles;
            _this.roles = roles;
            users.forEach(function (user) {
                user.displayRoles = user.roles.map(function (role) { return role.displayName; }).join(', ');
                user.roleCheckBoxes = roles.reduce(function (checkBoxes, r) {
                    checkBoxes[r.name] = false;
                    return checkBoxes;
                }, {});
                user.roles.forEach(function (role) { return user.roleCheckBoxes[role.name] = true; });
            });
            _this.dataSource = new MatTableDataSource(users);
            _this.dataSource.paginator = _this.paginator;
            _this.dataSource.sort = _this.sort;
        });
    };
    UsersComponent.prototype.createUser = function () {
        this.router.navigate(['users/create-user']);
    };
    UsersComponent.prototype.canManageUsers = function () {
        return this.authService.hasRole('user_manager');
    };
    UsersComponent.prototype.updateUser = function (user) {
        if (!this.canManageUsers()) {
            return;
        }
        var data = { user: user, roles: this.roles };
        this.dialog.open(UpdateUserComponent, {
            width: '1230px',
            data: data,
        });
    };
    return UsersComponent;
}());
export { UsersComponent };
