<mat-card class="mat-elevation-z4">
    <div fxLayout="row" fxLayoutAlign="space-around center">
    <mat-card-title class="filters" fxFlexAlign="start"> {{ 'reports.filters' | translate }} </mat-card-title>
    <mat-form-field fxFlex="15">
        <mat-select [(ngModel)]="dateRange" [placeholder]="'reports.date_range' | translate">
                <mat-option *ngFor="let dateRange of dateRanges" [value]="dateRange">
                    {{dateRange}}
                </mat-option>
        </mat-select>
    </mat-form-field>

    <div fxFlex="15" fxFlexOffset="3" fxLayout="column" fxLayoutAlign="space-between">
        <mat-form-field >
            <mat-label [innerText]="'reports.start_date' | translate"></mat-label>
            <input data-e2e="start.date" [required]="this.endDate || this.startDate" matInput [matDatepicker]="start" [(ngModel)]="startDate" (dateChange)="startDateChanged($event)">
            <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
            <mat-datepicker #start></mat-datepicker>
        </mat-form-field>
        
        <mat-form-field>
            <mat-label [innerText]="'reports.end_date' | translate"></mat-label>
            <input data-e2e="end.date" [required]="this.endDate || this.startDate" matInput [matDatepicker]="end" [(ngModel)]="endDate" (dateChange)="endDateChanged($event)">
            <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
            <mat-datepicker #end></mat-datepicker>
        </mat-form-field>
    </div>
    
    <mat-form-field fxFlex="15" fxFlexOffset="3">
        <mat-select data-e2e="batch.type" [(ngModel)]="batchType" [placeholder]="'batch_activity.batch_type' | translate">
                <mat-option *ngFor="let type of batchTypes" [value]="type">
                    {{type}}
                </mat-option>
        </mat-select>
    </mat-form-field>
    <a (click)="clearFilters()"> {{ 'reports.clear_filters' | translate }} </a>
    <div fxLayout="column" fxLayoutAlign="space-between">
        <button class="run-report" [disabled]="isDisabled()" mat-raised-button (click)="filterBatches()" color="accent"> {{ 'reports.run_report' | translate }} </button>
        <button class="download" fxFlexOffset="10" mat-stroked-button (click)="download()"> {{ 'reports.download' | translate }} </button>
    </div>
    </div>
</mat-card>

<mat-card class="mat-elevation-z4">
  <mat-card-title> {{ 'batch_activity.title' | translate }} </mat-card-title>
  <table mat-table [dataSource]="dataSource" (matSortChange)="onSortData($event)" matSort fxFill>
    <ng-container matColumnDef="drug">
        <th class="drug-header" mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'batch_activity.drug' | translate }} </th>
        <td class="drug-row" mat-cell *matCellDef="let row"> {{row.drug}} </td>
    </ng-container>

    <ng-container matColumnDef="lot">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'batch_activity.lot' | translate }} </th>
        <td mat-cell *matCellDef="let row"> {{row.lot}} </td>
    </ng-container>

    <ng-container matColumnDef="expiration">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'batch_activity.expiration' | translate }} </th>
        <td mat-cell *matCellDef="let row"> {{row.expiration}} </td>
    </ng-container>

    <ng-container matColumnDef="unitSaleNdc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'batch_activity.unit_sale_ndc' | translate }} </th>
        <td mat-cell *matCellDef="let row"> {{row.unitSaleNdc}} </td>
    </ng-container>

    <ng-container matColumnDef="unitUseNdc">
        <th class="last-column" mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'batch_activity.unit_use_ndc' | translate }} </th>
        <td mat-cell *matCellDef="let row"> {{row.unitUseNdc}} </td>
    </ng-container>

    <ng-container matColumnDef="processedAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'batch_activity.date_finished' | translate }} </th>
        <td mat-cell *matCellDef="let row"> {{row.processedAt}} </td>
    </ng-container>

    <ng-container matColumnDef="processedBy">
        <th class="last-column" mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'batch_activity.finished_by' | translate }} </th>
        <td mat-cell *matCellDef="let row"> {{row.processedBy}} </td>
    </ng-container>

    <ng-container matColumnDef="approvedAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'batch_activity.date_approved' | translate }} </th>
        <td mat-cell *matCellDef="let row"> {{row.approvedAt}} </td>
    </ng-container>

    <ng-container matColumnDef="approvedBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'batch_activity.approved_by' | translate }} </th>
        <td mat-cell *matCellDef="let row"> {{row.approvedBy}} </td>
    </ng-container>

    <ng-container matColumnDef="test">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'batch_activity.batch_type' | translate }} </th>
        <td mat-cell *matCellDef="let row"> {{row.test}} </td>
    </ng-container>

    <ng-container matColumnDef="line">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="line"> {{ 'batch_activity.line' | translate }} </th>
      <td mat-cell *matCellDef="let row" class="center-align"> {{row.line}} </td>
    </ng-container>

    <ng-container matColumnDef="totalCartons">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'batch_activity.total_cartons' | translate }} </th>
        <td mat-cell *matCellDef="let row"> {{row.totalCartons}} </td>
    </ng-container>

    <ng-container matColumnDef="uniqueValidEpcs">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'batch_activity.unique_valid_epcs' | translate }} </th>
        <td mat-cell *matCellDef="let row"> {{row.uniqueValidEpcs}} </td>
    </ng-container>

    <ng-container matColumnDef="uniqueInvalidEpcs">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'batch_activity.unique_invalid_epcs' | translate }} </th>
        <td mat-cell *matCellDef="let row"> {{row.uniqueInvalidEpcs}} </td>
    </ng-container>

    <ng-container matColumnDef="uniqueEpcs">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'batch_activity.total_unique_epcs' | translate }} </th>
        <td mat-cell *matCellDef="let row"> {{row.uniqueEpcs}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <ng-container *ngIf="!dataSource.data.length">
        <div class="no-batches" fxLayout="row" fxLayoutAlign="center center">
        <p> {{ 'reports.select_filters' | translate }} </p>
        </div>
    </ng-container>

    <mat-paginator [pageSize]="100" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
</mat-card>
