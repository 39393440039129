/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reject-batch.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/flex-layout/flex";
import * as i5 from "@angular/flex-layout/core";
import * as i6 from "@angular/cdk/bidi";
import * as i7 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/platform";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/platform-browser/animations";
import * as i12 from "./reject-batch.component";
var styles_RejectBatchDialogComponent = [i0.styles];
var RenderType_RejectBatchDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RejectBatchDialogComponent, data: {} });
export { RenderType_RejectBatchDialogComponent as RenderType_RejectBatchDialogComponent };
export function View_RejectBatchDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["fxLayout", "row"]], null, null, null, null, null)), i1.ɵdid(5, 671744, null, 0, i4.DefaultLayoutDirective, [i1.ElementRef, i5.StyleUtils, [2, i4.LayoutStyleBuilder], i5.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 12, "div", [["class", "buttons mat-dialog-actions"], ["fxLayout", "row"], ["fxLayoutAlign", "end"], ["fxLayoutGap", "30px"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i2.MatDialogActions, [], null, null), i1.ɵdid(10, 671744, null, 0, i4.DefaultLayoutDirective, [i1.ElementRef, i5.StyleUtils, [2, i4.LayoutStyleBuilder], i5.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(11, 1720320, null, 0, i4.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i6.Directionality, i5.StyleUtils, [2, i4.LayoutGapStyleBuilder], i5.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), i1.ɵdid(12, 671744, null, 0, i4.DefaultLayoutAlignDirective, [i1.ElementRef, i5.StyleUtils, [2, i4.LayoutAlignStyleBuilder], i5.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 3, "button", [["mat-stroked-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(14, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.Platform, i10.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(15, 0, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(17, 0, null, null, 3, "button", [["color", "accent"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onReject() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(18, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.Platform, i10.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(19, 0, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 1, 0); var currVal_2 = "row"; _ck(_v, 5, 0, currVal_2); var currVal_4 = "row"; _ck(_v, 10, 0, currVal_4); var currVal_5 = "30px"; _ck(_v, 11, 0, currVal_5); var currVal_6 = "end"; _ck(_v, 12, 0, currVal_6); var currVal_12 = "accent"; _ck(_v, 18, 0, currVal_12); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("batches_table.reject_batch")); _ck(_v, 2, 0, currVal_1); var currVal_3 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("batches_table.reject_confirmation")); _ck(_v, 6, 0, currVal_3); var currVal_7 = (i1.ɵnov(_v, 14).disabled || null); var currVal_8 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 13, 0, currVal_7, currVal_8); var currVal_9 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform("cancel")); _ck(_v, 15, 0, currVal_9); var currVal_10 = (i1.ɵnov(_v, 18).disabled || null); var currVal_11 = (i1.ɵnov(_v, 18)._animationMode === "NoopAnimations"); _ck(_v, 17, 0, currVal_10, currVal_11); var currVal_13 = i1.ɵunv(_v, 19, 0, i1.ɵnov(_v, 20).transform("reject")); _ck(_v, 19, 0, currVal_13); }); }
export function View_RejectBatchDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "reg-reject-batch", [], null, null, null, View_RejectBatchDialogComponent_0, RenderType_RejectBatchDialogComponent)), i1.ɵdid(1, 49152, null, 0, i12.RejectBatchDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], null, null); }
var RejectBatchDialogComponentNgFactory = i1.ɵccf("reg-reject-batch", i12.RejectBatchDialogComponent, View_RejectBatchDialogComponent_Host_0, {}, {}, []);
export { RejectBatchDialogComponentNgFactory as RejectBatchDialogComponentNgFactory };
