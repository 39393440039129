import { Injectable } from '@angular/core';
import { DataStore } from '../../services/datastore/datastore.service';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Role } from 'src/app/models/role';

@Injectable({
  providedIn: 'root'
})
export class RolesResolverService implements Resolve<Role[]> {

  constructor(private datastore: DataStore) { }

  resolve(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<Role[]> {
    return this.datastore.findAll(Role).pipe(
      map(rolesResult => rolesResult.getModels()),
    );
  }
}
