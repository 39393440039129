<mat-card class="mat-elevation-z4" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="25px">
    <mat-form-field appearance="outline" fxFlex>
        <mat-label>{{ 'search_catalog_entries' | translate }}</mat-label>
        <mat-icon matPrefix color="primary">search</mat-icon>
        <input matInput (keyup)="applyFilter($event.target.value)" [(ngModel)]="queryValue" #queryInput>
        <button matSuffix *ngIf="queryValue" (click)="clearSeach()" mat-icon-button color="primary">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>

    <button *ngIf="authService.hasRole('catalog_manager')" mat-raised-button color="accent" (click)="createCatalogEntry()">
        {{ 'new_catalog_entry' | translate }}
    </button>
</mat-card>

<mat-card class="mat-elevation-z4">
    <table mat-table [dataSource]="dataSource" matSort fxFill (matSortChange)="onSortData($event)">
        <!-- ID Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'catalog_entry_index.ID' | translate }} </th>
            <td mat-cell *matCellDef="let row"> {{row.id}} </td>
        </ng-container>

        <!-- NDC Column -->
        <ng-container matColumnDef="ndcFull">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'catalog_entry_index.unit_use_ndc' | translate }} </th>
            <td mat-cell *matCellDef="let row"> {{row.ndcFull}} </td>
        </ng-container>

        <!-- UNITS OF USE Column -->
        <ng-container matColumnDef="unitOfSales">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="unit-of-sales"> {{ 'catalog_entry_index.units_of_sale' | translate }} </th>
            <td mat-cell *matCellDef="let row" class="center-align" [ngClass]="{'details': row.unitOfSales.length}" (click)="details(row)"> {{row.unitOfSales.length}} </td>
        </ng-container>

        <!-- ITEM Column -->
        <ng-container matColumnDef="itemName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'catalog_entry_index.ITEM' | translate }} </th>
            <td mat-cell *matCellDef="let row"> {{row.itemName}} </td>
        </ng-container>

        <!-- MANUFACTURER Column -->
        <ng-container matColumnDef="manufacturerName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'catalog_entry_index.MANUFACTURER' | translate }} </th>
            <td mat-cell *matCellDef="let row"> {{row.manufacturerName}} </td>
        </ng-container>

        <!-- ITEM STRENGTH Column -->
        <ng-container matColumnDef="itemStrength">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'catalog_entry_index.ITEM_STRENGTH' | translate }} </th>
            <td mat-cell *matCellDef="let row"> {{row.itemStrength}} </td>
        </ng-container>

        <!-- PACKAGE SIZE Column -->
        <ng-container matColumnDef="packageSize">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'catalog_entry_index.PACKAGE_SIZE' | translate }} </th>
            <td mat-cell *matCellDef="let row"> {{row.packageSize}} </td>
        </ng-container>

        <!-- EXPIRING DAYS (REFRIGERATION) Column -->
        <ng-container matColumnDef="expiringFridgeDays">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'catalog_entry_index.expiring_days_refrigeration' | translate }} </th>
            <td mat-cell *matCellDef="let row" class="center-align"> {{row.expiringFridgeDays}} </td>
        </ng-container>

        <!-- EXPIRING DAYS (MULTIDOSE) Column -->
        <ng-container matColumnDef="expiringMultidoseDays">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'catalog_entry_index.expiring_days_multidose' | translate }} </th>
            <td mat-cell *matCellDef="let row" class="center-align"> {{row.expiringMultidoseDays}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons></mat-paginator>
</mat-card>
