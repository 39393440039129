import { HttpClient } from '@angular/common/http';
import { urls } from 'src/urls';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserManagementService = /** @class */ (function () {
    function UserManagementService(http) {
        this.http = http;
        this.USERS_URL = urls.API_URL + "/users";
    }
    UserManagementService.prototype.deleteUser = function (id) {
        return this.http.delete(this.USERS_URL + "/" + id).pipe();
    };
    UserManagementService.prototype.resendInvite = function (id) {
        return this.http.put(this.USERS_URL + "/" + id + "/resend-invite", {}).pipe();
    };
    UserManagementService.prototype.updateUser = function (id, user) {
        return this.http.put(this.USERS_URL + "/" + id, user).pipe();
    };
    UserManagementService.prototype.updateUserRoles = function (id, roles) {
        return this.http.put(this.USERS_URL + "/" + id + "/roles", { roles: roles }).pipe();
    };
    UserManagementService.prototype.createUser = function (user) {
        return this.http.post(this.USERS_URL, user).pipe();
    };
    UserManagementService.ngInjectableDef = i0.defineInjectable({ factory: function UserManagementService_Factory() { return new UserManagementService(i0.inject(i1.HttpClient)); }, token: UserManagementService, providedIn: "root" });
    return UserManagementService;
}());
export { UserManagementService };
