/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./password-reset.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/set-password/set-password.component.ngfactory";
import * as i3 from "../../components/set-password/set-password.component";
import * as i4 from "../../services/auth/auth.service";
import * as i5 from "@angular/router";
import * as i6 from "@angular/material/snack-bar";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./password-reset.component";
var styles_PasswordResetComponent = [i0.styles];
var RenderType_PasswordResetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PasswordResetComponent, data: {} });
export { RenderType_PasswordResetComponent as RenderType_PasswordResetComponent };
export function View_PasswordResetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "reg-set-password", [["page", "reset"]], null, null, null, i2.View_SetPasswordComponent_0, i2.RenderType_SetPasswordComponent)), i1.ɵdid(1, 114688, null, 0, i3.SetPasswordComponent, [i4.AuthService, i5.ActivatedRoute, i6.MatSnackBar, i7.TranslateService], { page: [0, "page"] }, null)], function (_ck, _v) { var currVal_0 = "reset"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PasswordResetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "reg-password-reset", [], null, null, null, View_PasswordResetComponent_0, RenderType_PasswordResetComponent)), i1.ɵdid(1, 49152, null, 0, i8.PasswordResetComponent, [], null, null)], null, null); }
var PasswordResetComponentNgFactory = i1.ɵccf("reg-password-reset", i8.PasswordResetComponent, View_PasswordResetComponent_Host_0, {}, {}, []);
export { PasswordResetComponentNgFactory as PasswordResetComponentNgFactory };
