import { Component } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material';
import {TranslateService, TranslateParser } from '@ngx-translate/core';

@Component({
    template: ''
})

export class CustomPaginatorIntlComponent extends MatPaginatorIntl {
    rangeLabelIntl: string;

    constructor(
        private translate: TranslateService,
        private translateParser: TranslateParser
    ) {
        super();
        this.getPaginatorTranslations();
    }

    getPaginatorTranslations() {
        this.translate.get([
            'paginator.items_per_page',
            'paginator.next_page',
            'paginator.previous_page',
            'paginator.range',
            'paginator.last_page',
            'paginator.first_page'
        ]).subscribe(translation => {
            this.itemsPerPageLabel = translation['paginator.items_per_page'];
            this.nextPageLabel = translation['paginator.next_page'];
            this.previousPageLabel = translation['paginator.previous_page'];
            this.rangeLabelIntl = translation['paginator.range'];
            this.firstPageLabel = translation['paginator.first_page'];
            this.lastPageLabel = translation['paginator.last_page'];
        });
    }

    getRangeLabel = (page, pageSize, length) => {
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
        return this.translateParser.interpolate(this.rangeLabelIntl, { startIndex, endIndex, length });
    }
}
