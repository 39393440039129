import { JsonApiModelConfig, JsonApiModel, Attribute } from 'angular2-jsonapi';

@JsonApiModelConfig({
    type: 'account-types'
})

export class AccountType extends JsonApiModel {
    @Attribute({ serializedName: 'name' })
    name: string;
}
