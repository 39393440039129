import { map } from 'rxjs/operators';
import { DataStore } from 'src/app/services/datastore/datastore.service';
import { Tunnel } from 'src/app/models/tunnel';
import * as i0 from "@angular/core";
import * as i1 from "../../services/datastore/datastore.service";
var TunnelsResolverService = /** @class */ (function () {
    function TunnelsResolverService(datastore) {
        this.datastore = datastore;
    }
    TunnelsResolverService.prototype.resolve = function (route, state) {
        return this.datastore.findAll(Tunnel).pipe(map(function (catalogEntriesResult) { return catalogEntriesResult.getModels(); }));
    };
    TunnelsResolverService.ngInjectableDef = i0.defineInjectable({ factory: function TunnelsResolverService_Factory() { return new TunnelsResolverService(i0.inject(i1.DataStore)); }, token: TunnelsResolverService, providedIn: "root" });
    return TunnelsResolverService;
}());
export { TunnelsResolverService };
