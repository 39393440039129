import { OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { CatalogResourceService } from 'src/app/services/catalog/catalog-resource.service';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
var CreateCatalogEntryComponent = /** @class */ (function () {
    function CreateCatalogEntryComponent(router, route, toast, catalogService, formBuilder, translate) {
        var _this = this;
        this.router = router;
        this.route = route;
        this.toast = toast;
        this.catalogService = catalogService;
        this.formBuilder = formBuilder;
        this.translate = translate;
        this.translate.get([
            'dismiss'
        ]).subscribe(function (translation) {
            _this.dismiss = translation['dismiss'];
        });
    }
    CreateCatalogEntryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.manufacturer = this.formBuilder.group({
            manufacturerName: ['', Validators.required]
        });
        this.drugInformation = this.formBuilder.group({
            drugName: ['', Validators.required],
            drugStrength: ['', Validators.required],
            drugStrengthUom: ['', Validators.required]
        });
        this.packageInformation = this.formBuilder.group({
            packageSize: ['', Validators.required],
            packageSizeUom: ['', Validators.required],
            packageType: ['', Validators.required],
            expiringFridge: [''],
            expiringMultidose: ['']
        });
        this.identifiers = this.formBuilder.group({
            unitOfUseNdc: ['', Validators.required],
            unitOfUseGtin: [''],
            unitsOfSale: this.formBuilder.array([this.formBuilder.group({ ndc: '', gtin: '', packageCount: '' })])
        });
        setTimeout(function () { return _this.focusInput.nativeElement.focus(); }, 100);
    };
    CreateCatalogEntryComponent.prototype.onConfirmation = function () {
        return this.stepper['selectedIndex'] === 4;
    };
    CreateCatalogEntryComponent.prototype.submit = function () {
        var _this = this;
        var newCatalogEntry = {
            ndc: this.identifiers.get('unitOfUseNdc').value,
            gtin: this.identifiers.get('unitOfUseGtin').value,
            itemName: this.drugInformation.get('drugName').value,
            manufacturerName: this.manufacturer.get('manufacturerName').value,
            packageDescriptionId: this.packageInformation.get('packageType').value.id,
            itemStrength: {
                scalar: parseFloat(this.drugInformation.get('drugStrength').value),
                uom: this.drugInformation.get('drugStrengthUom').value
            },
            packageSize: {
                scalar: parseFloat(this.packageInformation.get('packageSize').value),
                uom: this.packageInformation.get('packageSizeUom').value
            },
            unit_of_sales: this.unitsOfSale.controls.map(function (control) { return control.value; }),
        };
        var expiringDays = {};
        if (this.packageInformation.get('expiringFridge').value) {
            expiringDays.fridge = parseFloat(this.packageInformation.get('expiringFridge').value);
        }
        if (this.packageInformation.get('expiringMultidose').value) {
            expiringDays.multidose = parseFloat(this.packageInformation.get('expiringMultidose').value);
        }
        if (this.packageInformation.get('expiringFridge').value || this.packageInformation.get('expiringMultidose').value) {
            newCatalogEntry['expiringDays'] = expiringDays;
        }
        this.catalogService.createCatalogEntry(newCatalogEntry).subscribe(function (newCatalogCreated) {
            _this.router.navigate(['catalog'], { queryParams: { search: newCatalogCreated.data.attributes['ndc-full'] } });
            _this.translate.get('create_catalog.created_successfully', { itemName: newCatalogCreated.data.attributes['item-name'] }).subscribe(function (translation) {
                _this.toast.open(translation, _this.dismiss, {
                    duration: 3000,
                    panelClass: 'success-snackbar',
                });
            });
        });
    };
    Object.defineProperty(CreateCatalogEntryComponent.prototype, "unitsOfSale", {
        get: function () {
            return this.identifiers.get('unitsOfSale');
        },
        enumerable: true,
        configurable: true
    });
    CreateCatalogEntryComponent.prototype.unitsOfSaleValues = function () {
        return this.unitsOfSale.controls.map(function (control) { return control.value; });
    };
    CreateCatalogEntryComponent.prototype.addUnitOfSale = function () {
        this.unitsOfSale.push(this.formBuilder.group({ ndc: '', gtin: '', packageCount: '' }));
        console.log(this.unitsOfSale.controls.map(function (control) { return control.value; }));
    };
    CreateCatalogEntryComponent.prototype.deleteUnitOfSale = function (index) {
        this.unitsOfSale.removeAt(index);
        console.log(this.unitsOfSale.controls.map(function (control) { return control.value; }));
    };
    CreateCatalogEntryComponent.prototype.cancel = function () {
        this.router.navigate(['catalog']);
    };
    return CreateCatalogEntryComponent;
}());
export { CreateCatalogEntryComponent };
