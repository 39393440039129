import { JsonApiModelConfig, JsonApiModel, Attribute, BelongsTo } from 'angular2-jsonapi';
import { User } from './user';
import { ProductionRun } from './production-run';


@JsonApiModelConfig({
    type: 'alerts'
})

export class Alert extends JsonApiModel {
    @Attribute({ serializedName: 'alert-type' })
    alertType: string;

    @Attribute({ serializedName: 'created-at' })
    createdAt: string;

    @Attribute()
    resolution: string;

    @Attribute({ serializedName: 'resolved-at' })
    resolvedAt: string;

    @Attribute({ serializedName: 'tunnel-name' })
    tunnelName: string;

    @BelongsTo({ key: 'resolved-by-user' })
    user: User;

    @BelongsTo({ key: 'production-run' })
    productionRun: ProductionRun;
}
