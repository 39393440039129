import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { UserManagementService } from 'src/app/services/user-management/user-management.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'reg-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss']
})
export class UpdateUserComponent {

  dismiss: string;
  deleteSuccessMessage: string;
  resendSuccessMessage: string;
  userId: string;
  showDeleteConfirm: boolean;

  constructor(
    public dialogRef: MatDialogRef<UpdateUserComponent>,
    private usersService: UserManagementService,
    private router: Router,
    private toast: MatSnackBar,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.translate.get([
      'users.delete_success',
      'dismiss',
      'users.resend_success'
    ]).subscribe(translation => {
      this.dismiss = translation['dismiss'];
      this.deleteSuccessMessage = translation['users.delete_success'];
      this.resendSuccessMessage = translation['users.resend_success'];
    });
    this.userId = localStorage.getItem('userId');
  }

  onCancel() {
    this.dialogRef.close();
  }

  deleteUser() {
    this.showDeleteConfirm = true;
  }

  confirmDelete() {
    this.usersService.deleteUser(this.data.user.id).subscribe(() => {
      this.dialogRef.close();
      this.toast.open(this.deleteSuccessMessage, this.dismiss, {
        duration: 3000,
        panelClass: 'success-snackbar',
      });
      this.router.navigate(['users']);
    });
  }

  updateUser() {
    const { id, firstName, lastName, email } = this.data.user;

    const roles = Object.entries(this.data.user.roleCheckBoxes)
                        .filter(([_, selected]) => selected)
                        .map(([r, _]) => r);

    this.usersService.updateUser(id, { firstName, lastName, email }).subscribe(() => {
      this.usersService.updateUserRoles(id, roles).subscribe(() => {
        if (localStorage.getItem('userId') === id) {
          localStorage.setItem('roles', JSON.stringify(roles));
        }
        this.translate.get(['users.update_success', 'dismiss']).subscribe(translation => {
          this.toast.open(translation['users.update_success'], translation['dismiss'], {
            duration: 3000,
            panelClass: 'success-snackbar',
          });
        });

        this.router.navigate(['users']);
        this.dialogRef.close();
      });
    });
  }

  resendInvite() {
    this.usersService.resendInvite(this.data.user.id).subscribe(() => {
      this.toast.open(this.resendSuccessMessage, this.dismiss, {
        duration: 3000,
        panelClass: 'success-snackbar',
      });
    });
  }
}
