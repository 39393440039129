import { map } from 'rxjs/operators';
import { DataStore } from '../../services/datastore/datastore.service';
import { PackageDescription } from '../../models/package-description';
import * as i0 from "@angular/core";
import * as i1 from "../../services/datastore/datastore.service";
var PackageTypesResolverService = /** @class */ (function () {
    function PackageTypesResolverService(datastore) {
        this.datastore = datastore;
    }
    PackageTypesResolverService.prototype.resolve = function (_route, _state) {
        return this.datastore.findAll(PackageDescription).pipe(map(function (packageTypesResult) { return packageTypesResult.getModels(); }));
    };
    PackageTypesResolverService.ngInjectableDef = i0.defineInjectable({ factory: function PackageTypesResolverService_Factory() { return new PackageTypesResolverService(i0.inject(i1.DataStore)); }, token: PackageTypesResolverService, providedIn: "root" });
    return PackageTypesResolverService;
}());
export { PackageTypesResolverService };
