import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource, MatSort, MatPaginator, MatDatepickerInputEvent } from '@angular/material';
import * as moment from 'moment';
import { AlertsResourceService } from 'src/app/services/alerts/alerts-resource.service';
import { downloadCsv } from '../../util/download';

@Component({
  selector: 'reg-alert-activity',
  templateUrl: './alert-activity.component.html',
  styleUrls: ['./alert-activity.component.scss']
})
export class AlertActivityComponent implements OnInit {

  alertTypes: string[] = ['invalid_ndc', 'tunnel_offline', 'rfid_reader', 'barcode_reader', 'empty_carton', 'no_barcode_read'];
  dateRanges: string[] = [];
  startDate: string;
  endDate: string;
  alertType: string;
  dateRange: string;
  displayedColumns: string[] = ['alertType', 'line', 'createdAt', 'drugInformation', 'lotNumber', 'acknowledgedAt', 'acknowledgedBy', 'resolution'];
  dataSource: MatTableDataSource<any>;
  alerts: any[] = [];
  alertTranslations: any = {};
  downloadFilters: any = {};

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private translate: TranslateService,
    private alertsService: AlertsResourceService
  ) {
    this.getTranslations();
   }

  ngOnInit() {
      this.dataSource = new MatTableDataSource(this.alerts);
      this.sort.sort({ id: 'createdAt', start: 'desc', disableClear: false });
      this.dataSource.sort = this.sort;
    }

  getTranslations() {
    this.translate.get([
      'alert_activity.created',
      'alert_activity.acknowledged',
      'alert_activity.invalid_ndc',
      'alert_activity.tunnel_offline',
      'alert_activity.rfid_reader',
      'alert_activity.barcode_reader',
      'alert_activity.empty_carton',
      'alert_activity.no_barcode_read',
    ]).subscribe(translation => {
      this.alertTranslations['tunnel_offline'] = translation['alert_activity.tunnel_offline'];
      this.alertTranslations['invalid_ndc'] = translation['alert_activity.invalid_ndc'];
      this.alertTranslations['rfid_reader'] = translation['alert_activity.rfid_reader'];
      this.alertTranslations['barcode_reader'] = translation['alert_activity.barcode_reader'];
      this.alertTranslations['empty_carton'] = translation['alert_activity.empty_carton'];
      this.alertTranslations['no_barcode_read'] = translation['alert_activity.no_barcode_read'];
      this.dateRanges.push(translation['alert_activity.created']);
      this.dateRanges.push(translation['alert_activity.acknowledged']);
      this.dateRange = translation['alert_activity.created'];
    });
  }

  clearFilters() {
    this.startDate = null;
    this.endDate = null;
    this.dateRange = this.translate.instant('alert_activity.created');
    this.alertType = null;

  }

  startDateChanged($event: MatDatepickerInputEvent<Date>): void {
    const date = this.startDate ? moment($event.value).format('YYYY-MM-DD') : null;
    this.startDate = date;
  }

  endDateChanged($event: MatDatepickerInputEvent<Date>): void {
    const date = this.endDate ? moment($event.value).format('YYYY-MM-DD') : null;
    this.endDate = date;
  }

  mapAlertsData(alerts: any[]): any[] {
    return alerts.map((alert) => {
      return {
        alertType: alert.alertType,
        createdAt: moment(alert.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        drugInformation: !!alert.productionRun ? alert.productionRun.drugInformation : '',
        lotNumber: !!alert.productionRun ? alert.productionRun.lotNumber : '',
        acknowledgedAt : !!alert.resolvedAt ?  moment(alert.resolvedAt).format('YYYY-MM-DD HH:mm:ss') : '',
        resolution: alert.resolution,
        line: alert.tunnelName,
        acknowledgedBy: alert.user ? `${alert.user.firstName} ${alert.user.lastName}` : null
      };
    });
  }

  filterAlerts() {
    const timeFrame = this.dateRange === 'Created' ? 'create' : 'resolve';

    let endDateTime;
    if (this.endDate) {
      endDateTime = moment(this.endDate).format('YYYY-MM-DD 23:59:59');
    }

    this.downloadFilters = {
      timeFrame,
      startDate: this.startDate,
      endDateTime,
      type: this.alertType
    };

    this.alertsService.getAlerts(timeFrame, this.startDate, endDateTime, this.alertType).subscribe((alerts) => {
      this.alerts = this.mapAlertsData(alerts);
      this.dataSource = new MatTableDataSource(this.alerts);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  download() {
    this.alertsService.downloadAlertActivity(this.downloadFilters).subscribe((batches) => {
      downloadCsv(`alert_activity.csv`, batches);
    });
  }

  isDisabled() {
    return (this.startDate && !this.endDate) || (!this.startDate && this.endDate) || this.startDate === 'Invalid date' || this.endDate === 'Invalid date';
  }

  onSortData(sort) {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'alertType': {
          return this.alertTranslations[item[property]];
        }
        default: {
          return item[property];
        }
      }
    };
  }
}
