import * as tslib_1 from "tslib";
import { JsonApiModelConfig, JsonApiModel, Attribute, HasMany } from 'angular2-jsonapi';
var UserSession = /** @class */ (function (_super) {
    tslib_1.__extends(UserSession, _super);
    function UserSession() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(UserSession.prototype, "navName", {
        get: function () {
            return this.email;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        Attribute({ serializedName: 'authentication-token' }),
        tslib_1.__metadata("design:type", String)
    ], UserSession.prototype, "authenticationToken", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'password-reset-required' }),
        tslib_1.__metadata("design:type", String)
    ], UserSession.prototype, "passwordResetRequired", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'first-name' }),
        tslib_1.__metadata("design:type", String)
    ], UserSession.prototype, "firstName", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'last-name' }),
        tslib_1.__metadata("design:type", String)
    ], UserSession.prototype, "lastName", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'user-type' }),
        tslib_1.__metadata("design:type", String)
    ], UserSession.prototype, "userType", void 0);
    tslib_1.__decorate([
        Attribute(),
        tslib_1.__metadata("design:type", String)
    ], UserSession.prototype, "email", void 0);
    tslib_1.__decorate([
        HasMany(),
        tslib_1.__metadata("design:type", Array)
    ], UserSession.prototype, "roles", void 0);
    tslib_1.__decorate([
        Attribute({ serializedName: 'account-name' }),
        tslib_1.__metadata("design:type", String)
    ], UserSession.prototype, "accountName", void 0);
    UserSession = tslib_1.__decorate([
        JsonApiModelConfig({
            type: 'user-sessions'
        })
    ], UserSession);
    return UserSession;
}(JsonApiModel));
export { UserSession };
